import * as React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gs from '../gStyles'

import Text from './Text'

export type ButtonProps = c.r<{
  alignSelfStart?: boolean
  children: string
  on: c.Surface
  onPress(): void
  shrink?: boolean
}>

export default React.memo<ButtonProps>(function Button({
  alignSelfStart,
  children,
  on,
  onPress,
  shrink,
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const rootStyle = React.useCallback(
    (s: rn.PressableStateCallbackType): rn.StyleProp<rn.ViewStyle> => {
      if (alignSelfStart && s.hovered) {
        return shrink
          ? styles.rootHoveredAlignStartShrink
          : styles.rootHoveredAlignStart
      }
      if (s.hovered) {
        return shrink ? styles.rootHoveredShrink : styles.rootHovered
      }
      if (alignSelfStart) {
        return shrink ? styles.rootAlignStartShrink : styles.rootAlignStart
      }
      return shrink ? styles.rootShrink : styles.root
    },
    [
      alignSelfStart,
      shrink,
      styles.root,
      styles.rootAlignStart,
      styles.rootAlignStartShrink,
      styles.rootHovered,
      styles.rootHoveredAlignStart,
      styles.rootHoveredAlignStartShrink,
      styles.rootHoveredShrink,
      styles.rootShrink,
    ],
  )

  return (
    <rn.Pressable onPress={onPress} style={rootStyle}>
      <Text style={styles.label}>{children}</Text>
    </rn.Pressable>
  )
})

const themedStyles = gs.ThemedStyleSheet.create((t, on, _) => {
  const rootShrink = {
    borderColor: t.button[on].backgroundColor,
    ...t.button[on], // TODO: Parametrize
    alignItems: 'center',
    justifyContent: 'center',
    ...gs.inputShrink,
    // TODO: do not rely on surface, find way around it
    marginHorizontal:
      r.isMobile && on === 'canvas' ? t[on].paddingHorizontal : undefined,
  } as const
  const root = { ...rootShrink, width: '100%' }

  const rootAlignStart = { ...root, alignSelf: 'flex-start' } as const
  const rootHovered = {
    ...root,
    backgroundColor: t.button[on].hoverBG,
    borderColor: t.button[on].hoverBC || t.button[on].hoverBG,
  }
  const rootHoveredShrink = {
    ...rootShrink,
    backgroundColor: t.button[on].hoverBG,
    borderColor: t.button[on].hoverBC || t.button[on].hoverBG,
  }

  return {
    label: {
      ...gs.userSelectNone,
      color: t.button[on].color,
      fontFamily: t.fontFamily,
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 21, // TODO: ?
    },
    root,
    rootShrink,
    rootAlignStart,
    rootAlignStartShrink: { ...rootShrink, ...rootAlignStart },
    rootHovered,
    rootHoveredShrink,
    rootHoveredAlignStart: { ...rootHovered, ...rootAlignStart },
    rootHoveredAlignStartShrink: { ...rootHoveredShrink, ...rootAlignStart },
  }
})
