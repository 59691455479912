import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'

export interface UPSViewProps extends rn.ViewProps {
  readonly avoidsKB?: boolean
  readonly avoidsKBBehavior?: rn.KeyboardAvoidingViewProps['behavior']
  /**
   * This didn't need to be enabled in Android but now the changes in
   * FullScreenModal made it necessary, look into why.
   */
  readonly avoidsKBForceAndroid?: boolean

  readonly bgDark?: string
  readonly bgLight?: string

  readonly children?: React.ReactNode

  readonly colorScheme?: c.ColorScheme

  readonly alignItems?: rn.ViewStyle['alignItems']
  readonly alignSelf?: rn.ViewProps['alignSelf']
  readonly backgroundColor?: rn.ViewStyle['backgroundColor']
  readonly borderColor?: rn.ViewStyle['borderColor']
  readonly borderLeftColor?: rn.ViewStyle['borderLeftColor']
  readonly borderLeftWidth?: rn.ViewStyle['borderLeftWidth']
  readonly borderRadius?: rn.ViewStyle['borderRadius']
  readonly borderRightColor?: rn.ViewStyle['borderRightColor']
  readonly borderRightWidth?: rn.ViewStyle['borderRightWidth']
  readonly borderWidth?: rn.ViewStyle['borderWidth']
  readonly height?: rn.ViewStyle['height']
  readonly flexDirection?: rn.ViewStyle['flexDirection']
  readonly flexGrow?: rn.ViewStyle['flexGrow']
  readonly flexWrap?: rn.ViewStyle['flexWrap']
  readonly justifyContent?: rn.ViewStyle['justifyContent']
  readonly marginBottom?: rn.ViewStyle['marginBottom']
  readonly marginLeft?: rn.ViewStyle['marginLeft']
  readonly marginRight?: rn.ViewStyle['marginRight']
  readonly minHeight?: rn.ViewStyle['minHeight']
  readonly paddingBottom?: rn.ViewStyle['paddingBottom']
  readonly paddingHorizontal?: rn.ViewStyle['paddingHorizontal']
  readonly paddingLeft?: rn.ViewStyle['paddingLeft']
  readonly paddingRight?: rn.ViewStyle['paddingRight']
  readonly paddingTop?: rn.ViewStyle['paddingTop']
  readonly paddingVertical?: rn.ViewStyle['paddingVertical']
  readonly width?: rn.ViewStyle['width']
}

export default React.memo<UPSViewProps>(function UPSView({
  avoidsKB,
  avoidsKBBehavior,
  avoidsKBForceAndroid,

  bgDark,
  bgLight,

  colorScheme,

  alignItems,
  alignSelf,
  backgroundColor,
  borderColor,
  borderLeftColor,
  borderLeftWidth,
  borderRadius,
  borderRightColor,
  borderRightWidth,
  borderWidth,
  flexDirection,
  flexGrow,
  flexWrap,
  height,
  justifyContent,
  marginLeft,
  marginRight,
  minHeight,
  paddingBottom,
  paddingHorizontal,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingVertical,
  style: styleProp,
  width,
  ...viewPropsRest
}) {
  const isDark = rn.useColorScheme() === 'dark'

  const mixin = React.useMemo(
    (): rn.ViewStyle => ({
      alignItems,
      alignSelf,
      backgroundColor: backgroundColor || (isDark ? bgDark : bgLight),
      borderColor,
      borderLeftColor,
      borderLeftWidth,
      borderRadius,
      borderRightColor,
      borderRightWidth,
      flexDirection,
      flexGrow,
      flexWrap,
      height,
      justifyContent,
      marginLeft,
      marginRight,
      minHeight,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      width,
    }),
    [
      alignItems,
      alignSelf,
      backgroundColor,
      bgDark,
      bgLight,
      borderColor,
      borderLeftColor,
      borderLeftWidth,
      borderRadius,
      borderRightColor,
      borderRightWidth,
      flexDirection,
      flexGrow,
      flexWrap,
      height,
      isDark,
      justifyContent,
      marginLeft,
      marginRight,
      minHeight,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      width,
    ],
  )

  const style = React.useMemo((): rn.StyleProp<rn.ViewStyle> => {
    if (styleProp) return styleProp
    if (c.keys(mixin).length) return mixin
    return undefined
  }, [mixin, styleProp])

  if (avoidsKB && (rn.Platform.OS === 'ios' || avoidsKBForceAndroid)) {
    return (
      <rn.KeyboardAvoidingView
        behavior={avoidsKBBehavior || 'padding'}
        {...viewPropsRest}
        style={style}
      />
    )
  }

  return <rn.View {...viewPropsRest} style={style} />
})
