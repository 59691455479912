import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Dimensions,
  FlatList,
  ListRenderItem,
  Modal,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
  useColorScheme,
} from 'react-native'

import * as c from '../../../common'
import {
  CityPrices,
  Customer,
  Price,
  selectEfficiencyPrice,
  useSelector,
} from '../../../common'

import Feather from 'react-native-vector-icons/Feather'

import * as gs from '../../gStyles'
import Text from '../../components/Text'

// TODO: Parametrize
enum EnumButtonFilter {
  CALIFORNIA = 'california',
  NEVADA = 'nevada',
  UTAH = 'utah',
  TEXAS = 'texas',
}

export interface ModalPriceProps {
  customer?: Customer
  field: keyof Customer
  onRequestClose: () => void
  title?: string
  visible: boolean
}

const keyExtractor = (_: unknown, index: number): string => String(index)

export default React.memo<ModalPriceProps>(function ModalPrice({
  customer,
  field,
  onRequestClose,
  title,
  visible,
}): ReactElement {
  const colorScheme = useColorScheme() || 'light'
  const isDark = colorScheme === 'dark'
  const styles = gs.useThemedStyleSheet(themedStyles)

  // TODO: Parametrize
  const filterSelected = useMemo((): EnumButtonFilter | null => {
    if (!customer) {
      return null
    }
    if (customer.solarCompany === 'NPS') {
      return EnumButtonFilter.NEVADA
    } else if (customer.solarCompany === 'CPS') {
      return EnumButtonFilter.CALIFORNIA
    } else if (customer.solarCompany === 'UTPS') {
      return EnumButtonFilter.UTAH
    } else if (customer.solarCompany === 'TPS') {
      return EnumButtonFilter.TEXAS
    }
    return null
  }, [customer])

  const [priceList, setPriceList] = useState<Price[]>([])
  const [pricesByCityList, setPricesByCityList] = useState<CityPrices[]>([])

  const [pricesByCitySelected, setPricesByCitySelected] = useState<
    string | null
  >(null)

  const dataPriceSelected = useSelector(selectEfficiencyPrice(field))

  const onLoadDataByFilter = useCallback((): void => {
    if (!dataPriceSelected) {
      return
    }
    if (
      filterSelected === EnumButtonFilter.TEXAS ||
      filterSelected === EnumButtonFilter.UTAH
    ) {
      setPricesByCitySelected(null)
      setPriceList([])
      setPricesByCityList([])
      return
    }

    if (filterSelected === EnumButtonFilter.CALIFORNIA) {
      const californiaPrice = dataPriceSelected.california.pricesByCity
      // @ts-expect-error TODO
      setPricesByCitySelected(californiaPrice[0].label || '')
      // @ts-expect-error TODO
      setPriceList(californiaPrice[0].list)
      setPricesByCityList(californiaPrice)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    const nevadaPrice: Price[] = dataPriceSelected?.nevada?.list || []
    setPricesByCityList([])
    setPriceList(nevadaPrice)
  }, [dataPriceSelected, filterSelected])

  useEffect((): void => {
    onLoadDataByFilter()
  }, [onLoadDataByFilter])

  const renderItem: ListRenderItem<Price> = useCallback(
    ({ item }): ReactElement => (
      <View style={styles.renderItemPrice}>
        {item.label ? (
          <View style={styles.contentLabel}>
            <Text
              numberOfLines={1}
              bold
              size={16}
              style={styles.label}
            >{`${item.label}:  `}</Text>
          </View>
        ) : null}
        <Text>{item.value ? `${item.value}$` : ''}</Text>
      </View>
    ),
    [styles.contentLabel, styles.label, styles.renderItemPrice],
  )

  const renderEmptyPriceList = useCallback(
    (): ReactElement => (
      <View style={styles.renderEmptyPriceList}>
        <Text on="canvas" size={18} bold>
          No Price List
        </Text>
      </View>
    ),
    [styles.renderEmptyPriceList],
  )

  const renderItemHorizontal: ListRenderItem<CityPrices> = useCallback(
    ({ item }): ReactElement => {
      const onPress = (): void => {
        setPriceList(item.list)
        setPricesByCitySelected(item.label || '')
      }

      const styleSelected: StyleProp<ViewStyle> = {
        borderBottomColor:
          pricesByCitySelected === item.label
            ? c.themeTuple[colorScheme].paper.highlight
            : 'transparent',
      }

      return (
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={onPress}
          style={[styles.itemHorizontal, styleSelected]}
        >
          <Text
            style={
              pricesByCitySelected === item.label
                ? styles.labelSelected
                : styles.label
            }
            numberOfLines={1}
          >
            {item.label || ''}
          </Text>
        </TouchableOpacity>
      )
    },
    [
      pricesByCitySelected,
      colorScheme,
      styles.itemHorizontal,
      styles.labelSelected,
      styles.label,
    ],
  )

  return (
    <Modal
      animationType="fade"
      onRequestClose={onRequestClose}
      transparent
      visible={visible}
    >
      <View style={styles.container}>
        <View style={isDark ? styles.modalViewDark : styles.modalView}>
          <View style={styles.contentBtn}>
            <TouchableOpacity
              onPress={onRequestClose}
              style={styles.btnCloseModal}
            >
              <Feather color={isDark ? 'white' : 'black'} name="x" size={24} />
            </TouchableOpacity>
            {typeof title === 'string' && (
              <View style={styles.titleContainer}>
                <Text bold on="canvas">
                  {title}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.contentLabel}>
            <Text bold size={20} style={styles.label}>
              {filterSelected || ''}
            </Text>
          </View>
          {pricesByCityList.length > 0 && (
            <View style={styles.contentSubCategories}>
              <FlatList
                data={pricesByCityList}
                horizontal
                keyExtractor={keyExtractor}
                renderItem={renderItemHorizontal}
              />
            </View>
          )}
          <FlatList
            ListEmptyComponent={renderEmptyPriceList}
            contentContainerStyle={styles.priceList}
            data={priceList}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
          />
        </View>
      </View>
    </Modal>
  )
})

const buttonFilter = {
  alignItems: 'center',
  borderColor: c.themeTuple.light.paper.opaque,
  flex: 1,
  height: 30,
  justifyContent: 'center',
} as const

const calcItemWidthSubCategory = (Dimensions.get('screen').width * 0.8) / 3

const themedStyles = gs.ThemedStyleSheet.create((t) => ({
  btnCloseModal: {
    alignItems: 'center',
    borderRadius: 10,
    justifyContent: 'center',
  },
  buttonFilterActive: {
    ...buttonFilter,
    backgroundColor: c.themeTuple.light.paper.highlight,
  },
  buttonFilterInactive: { ...buttonFilter },
  buttonFilterLeft: {
    borderBottomLeftRadius: 10,
    borderRightWidth: 0,
    borderTopLeftRadius: 10,
    borderWidth: 1,
  },
  buttonFilterRight: {
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    borderTopRightRadius: 10,
    borderWidth: 1,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    justifyContent: 'center',
  },
  contentBtn: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    marginVertical: 5,
  },
  contentBtnFilter: { flexDirection: 'row', justifyContent: 'center' },
  contentLabel: { marginTop: 10 },
  contentSubCategories: { marginVertical: 10 },
  greenText: {
    color: c.upsLeaf,
    fontFamily: t.fontFamily,
    fontWeight: '700',
  },
  itemHorizontal: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderRadius: 10,
    justifyContent: 'center',
    padding: 10,
    width: calcItemWidthSubCategory,
  },
  label: {
    color: 'rgba(0,0,0,0.7)',
    fontFamily: t.fontFamily,
    textTransform: 'capitalize',
  },
  labelSelected: {
    color: t.canvas.highlight,
    fontFamily: t.fontFamily,
    textTransform: 'capitalize',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 10,
    elevation: 5,
    height: '50%',
    padding: 10,
    shadowColor: '#000',
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '80%',
  },
  modalViewDark: {
    backgroundColor: 'black',
    borderRadius: 10,
    elevation: 5,
    height: '50%',
    padding: 10,
    shadowColor: '#fff',
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '80%',
  },
  priceList: { marginHorizontal: 10 },
  renderEmptyPriceList: { alignItems: 'center', justifyContent: 'center' },
  renderItemPrice: { justifyContent: 'center', marginTop: 5 },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
  },
}))

// TODO: use getFieldLabel()
