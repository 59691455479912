import * as u from '../utils'

import main_service_panel_0 from './electrical_survey/main_service_panel_0.jpg'
import main_service_panel_1 from './electrical_survey/main_service_panel_1.jpg'
import eight_foot_msp_0 from './electrical_survey/eight_foot_msp_0.jpg'
import eight_foot_msp_1 from './electrical_survey/eight_foot_msp_1.jpg'
import utility_meter_closeup_0 from './electrical_survey/utility_meter_closeup_0.jpg'
import main_panel_label_0 from './electrical_survey/main_panel_label_0.jpg'
import breakers_with_labels_0 from './electrical_survey/breakers_with_labels_0.jpg'
import breakers_without_labels_0 from './electrical_survey/breakers_without_labels_0.jpg'
import main_breaker_disconnect_0 from './electrical_survey/main_breaker_disconnect_0.jpg'
import ground_sys_conn_0 from './electrical_survey/ground_sys_conn_0.jpg'
import ground_sys_conn_1 from './electrical_survey/ground_sys_conn_1.jpg'

export type ExampleImageNew = u.r<{
  caption: string
  src: number | string
  thumb: number | string
}>
export type ExampleImagesNew = readonly ExampleImageNew[]

export const mainServicePanelExamples: ExampleImagesNew = [
  {
    caption: 'Main service panel (1/2)',
    src: main_service_panel_0,
    thumb: main_service_panel_0,
  },
  {
    caption: 'Utility feed (roof or ground) (2/2)',
    src: main_service_panel_1,
    thumb: main_service_panel_1,
  },
]

export const eightFootMspExamples: ExampleImagesNew = [
  {
    caption: '(1/2)',
    src: eight_foot_msp_0,
    thumb: eight_foot_msp_0,
  },
  {
    caption: '(2/2)',
    src: eight_foot_msp_1,
    thumb: eight_foot_msp_1,
  },
]

export const utilityMeterCloseupExamples: ExampleImagesNew = [
  {
    caption: '(1/1)',
    src: utility_meter_closeup_0,
    thumb: utility_meter_closeup_0,
  },
]

export const mainPanelLabelExamples: ExampleImagesNew = [
  {
    caption: '(1/1)',
    src: main_panel_label_0,
    thumb: main_panel_label_0,
  },
]

export const breakersWithLabelsExamples: ExampleImagesNew = [
  {
    caption: '(1/1)',
    src: breakers_with_labels_0,
    thumb: breakers_with_labels_0,
  },
]

export const breakersWithoutLabelsExamples: ExampleImagesNew = [
  {
    caption: '(1/1)',
    src: breakers_without_labels_0,
    thumb: breakers_without_labels_0,
  },
]

export const mainBreakerDisconnectExamples: ExampleImagesNew = [
  {
    caption: '(1/1)',
    src: main_breaker_disconnect_0,
    thumb: main_breaker_disconnect_0,
  },
]

export const groundSysConnExamples: ExampleImagesNew = [
  {
    caption: '(1/2)',
    src: ground_sys_conn_0,
    thumb: ground_sys_conn_0,
  },
  {
    caption: '(2/2)',
    src: ground_sys_conn_1,
    thumb: ground_sys_conn_1,
  },
]
