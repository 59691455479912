import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import Octicons from 'react-native-vector-icons/Octicons'
import Feather from 'react-native-vector-icons/Feather'
// import useMediaQuery from '@mui/material/useMediaQuery'

import axios from 'axios'
import Carousel, { ResponsiveType } from 'react-multi-carousel'

// import { FileValidated } from '@dropzone-ui/react'
import { ExtFile, FileInputButton } from '@files-ui/react'
// @ts-expect-error
import { Fancybox as FancyBox } from '@fancyapps/ui'
import { saveAs } from 'file-saver'
import { useDispatch } from 'react-redux'
import { v1 } from 'uuid'
import '@fancyapps/ui/dist/fancybox.css'
import 'react-multi-carousel/lib/styles.css'

//#region siteSurveyExamples
import { ALL_EXTERIOR_WALLS_SITE_SURVEY } from '../../../app/assets/img/AllExteriorWalls'
import { RAFTERS_SITE_SURVEY } from '../../../app/assets/img/Rafters'
import { ROOF_ALL_PANELS_SITE_SURVEY as ROOF_ALL_PLANES_SITE_SURVEY } from '../../../app/assets/img/RoofFromAllPlanes'
import { ELECTRICAL_PANEL_SITE_SURVEY } from '../../../app/assets/img/ElectricalPanel'
//#endregion

import {
  ROOF_CLAIMS_3D_VIEW,
  ROOF_CLAIMS_ADDRESS_VERIFICATION,
  ROOF_CLAIMS_DRIP,
  ROOF_CLAIMS_FLASHINGS,
  ROOF_CLAIMS_FRONT_HOUSE,
  ROOF_CLAIMS_GUTTER,
  ROOF_CLAIMS_HAIL,
  ROOF_CLAIMS_LAYERS,
  ROOF_CLAIMS_LENGTH_SHINGLE,
  ROOF_CLAIMS_OTHER_PROPERTY,
  ROOF_CLAIMS_PENETRATIONS,
  ROOF_CLAIMS_ROOF_INCLINATION,
  ROOF_CLAIMS_ROOF_OVERVIEW,
  ROOF_CLAIMS_SHINGLES_COLOR,
  ROOF_CLAIMS_SIDING,
  ROOF_CLAIMS_WIRES_HOME,
  ROOF_CLAIMS_GARAGE,
  ROOF_CLAIMS_ICE_SHIELD,
} from '../../img/RoofClaims'

import * as gs from '../../../app/gStyles'
import gStyles from '../../global.module.css'
import MediaImage from '../MediaImage'
import Pad from '../Pad'
import ToastAlert from '../ToastAlert'

import styles from './MediaInput.module.css'
import './Dropzone.css'

let zip = require('jszip')()

export interface MediaInputProps {
  customerID: string
  ofWhat: c.MediaKind
}

export default React.memo<MediaInputProps>(function MediaInput({
  customerID,
  ofWhat,
}) {
  const t = r.useTheme()
  // const isDark = useMediaQuery('(prefers-color-scheme: dark)')

  const dispatch = useDispatch()
  const isMounted = r.useIsMounted()

  const selectCustomerMedia = React.useMemo(() => c.makeSelectMedia(), [])

  const [successfulUpload, setSuccessfulUpload] = React.useState(false)

  const [, mediaToLabelSolarSurvey] = r.useSolarSurveyMedia(customerID)

  const label =
    c.mediaToLabel[ofWhat] ||
    mediaToLabelSolarSurvey[ofWhat] ||
    c.mediaToLabelRoofClaims[ofWhat] ||
    ofWhat

  const selectorArgs = React.useMemo(
    () => ({
      customerID,
      mediaKind: ofWhat,
    }),
    [customerID, ofWhat],
  )

  const customerMedia = c.useSelector((_) =>
    selectCustomerMedia(_, selectorArgs),
  )

  const filteredMediaSorted = React.useMemo((): c.MediaItems => {
    const filteredMedia = c.pickBy(
      customerMedia,
      (mediaItem) =>
        mediaItem.image_of.toLowerCase().includes(ofWhat) ||
        mediaItem.video_of.toLowerCase().includes(ofWhat),
    )

    return c.values(filteredMedia).sort((a, b) => a.updated_at - b.updated_at)
  }, [customerMedia, ofWhat])

  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomerField> =>
      c.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): c.SelectCustomerFieldParams => ({
      customerID,
      field: 'customerName',
    }),
    [customerID],
  )
  // Remember to subscribe to the customer elsewhere!
  const customerName = c.useSelector(
    (_): c.ValueOf<c.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )

  const handleSuccessFullUpload = React.useCallback(() => {
    setSuccessfulUpload((current) => !current)
  }, [setSuccessfulUpload])

  React.useEffect((): c.EmptyFn => {
    dispatch(c.subToCustomerMedia({ customerID }))

    return (): void => {
      dispatch(
        c.unSubFromCustomerMedia({
          customerID,
        }),
      )
    }
  }, [customerID, dispatch])

  // React.useEffect(() => {
  //   let mediaIDs: string[] = []
  //   for (const [key, mediaItem] of mediaItems) {
  //     if (mediaItem.err) {
  //       mediaIDs.push(key)
  //       alert(`
  //          An error occurred while uploading the image: ${mediaItem.err}`)
  //     }
  //   }

  //   for (const id of mediaIDs) {
  //     c.dispatch(c.deleteMediaItem(customerID, id))
  //   }
  // }, [customerID, mediaItems])

  const handleFilesSelect = React.useCallback(
    async (files: ExtFile[]): Promise<void> => {
      if (!files.length) return
      if (!isMounted()) return

      const _asMediaItems: readonly Promise<c.MediaItemToBeUploaded>[] =
        files.map(async (f): Promise<c.MediaItemToBeUploaded> => {
          const uid = v1()
          try {
            if (!f.file) {
              throw new Error('No f.file')
            }
            if (f.errors) {
              throw new Error(f.errors.join(','))
            }
            if (!f.type) {
              throw new Error('No f.type')
            }
            if (!f.type.startsWith('image') && !f.type.startsWith('video')) {
              throw new Error(`Unknown type: ${f.type}`)
            }
            if (!f.name) {
              throw new Error('No f.name')
            }

            const type: 'image' | 'video' = f.type!.startsWith('image')
              ? 'image'
              : 'video'

            const path = await (async (): Promise<string> => {
              if (type === 'image') {
                if (ofWhat === 'electricity') {
                  return new Promise((res, rej) => {
                    const reader = new FileReader()

                    reader.onerror = (e) => {
                      if (!isMounted()) {
                        return
                      }
                      rej(new Error(JSON.stringify(e)))
                    }
                    reader.onload = () => {
                      if (!isMounted()) {
                        return
                      }
                      if (typeof reader.result === 'string') {
                        res(reader.result)
                      } else {
                        console.log(
                          'reader result not a string',
                          typeof reader.result,
                        )
                        rej(
                          new TypeError(
                            `reader result not a string, but: ${typeof reader.result}`,
                          ),
                        )
                      }
                    }
                    reader.readAsDataURL(f.file!)
                  })
                }

                return compressImg(f.file!)
              }

              return new Promise((res, rej) => {
                const videoFileReader = new FileReader()

                videoFileReader.onload = () => {
                  const videoBase64 = videoFileReader.result as string
                  res(videoBase64)
                }
                // onerror defective?
                videoFileReader.onerror = (e) => void rej(e)
                videoFileReader.readAsDataURL(f.file!)
              })
            })()

            return {
              customerID,
              name: uid,
              ofWhat,
              path,
              thumbnail: type === 'image' ? await makeThumbnail(f.file!) : '',
              type,
            }
          } catch (e) {
            console.log('error', e)
            const type: 'image' | 'video' = f.type?.startsWith('image')
              ? 'image'
              : 'video'

            return {
              customerID,
              name: uid,
              ofWhat,
              path: 'file:///error',
              thumbnail: '',
              type,
            }
          }
        })

      const asMediaItems = await Promise.all(_asMediaItems)

      c.dispatch(
        c.requestedMediaUpload({
          items: asMediaItems,
        }),
      )
    },
    [customerID, isMounted, ofWhat],
  )

  const handlePreviewImages = React.useCallback(() => {
    if (roofingClaimsImage[ofWhat]) {
      const fancyBoxMedia = roofingClaimsImage[ofWhat]?.map((media) => ({
        caption: label,
        src: media.source,
        thumb: media.source,
      }))

      FancyBox.show(fancyBoxMedia)
    }
    if (ofWhat === 'all_exterior_walls_site_survey') {
      const fancyBoxMedia = ALL_EXTERIOR_WALLS_SITE_SURVEY.map(
        ({ label, source }) => ({
          caption: label,
          src: source,
          thumb: source,
        }),
      )

      FancyBox.show(fancyBoxMedia)
    }
    if (ofWhat === 'rafters_site_survey') {
      const fancyBoxMedia = RAFTERS_SITE_SURVEY.map(({ label, source }) => ({
        caption: label,
        src: source,
        thumb: source,
      }))

      FancyBox.show(fancyBoxMedia)
    }
    if (ofWhat === 'all_planes_roof_site_survey') {
      const fancyBoxMedia = ROOF_ALL_PLANES_SITE_SURVEY.map(
        ({ label, source }) => ({
          caption: label,
          src: source,
          thumb: source,
        }),
      )

      FancyBox.show(fancyBoxMedia)
    }
    if (ofWhat === 'electrical') {
      const fancyBoxMedia = ELECTRICAL_PANEL_SITE_SURVEY.map(
        ({ label, source }) => ({
          caption: label,
          src: source,
          thumb: source,
        }),
      )

      FancyBox.show(fancyBoxMedia)
    }
    if (ofWhat === 'main_service_panel_site_survey') {
      FancyBox.show(c.mainServicePanelExamples)
    }
    if (ofWhat === 'eight_foot_msp_site_survey') {
      FancyBox.show(c.eightFootMspExamples)
    }
    if (ofWhat === 'utility_meter_closeup_site_survey') {
      FancyBox.show(c.utilityMeterCloseupExamples)
    }
    if (ofWhat === 'main_panel_label_site_survey') {
      FancyBox.show(c.mainPanelLabelExamples)
    }
    if (ofWhat === 'main_panel_breakers_dead_front_included_site_survey') {
      FancyBox.show(c.breakersWithLabelsExamples)
    }
    if (ofWhat === 'main_panel_breakers_dead_front_removed_site_survey') {
      FancyBox.show(c.breakersWithoutLabelsExamples)
    }
    if (ofWhat === 'main_breaker_disconnect_closeup_site_survey') {
      FancyBox.show(c.mainBreakerDisconnectExamples)
    }
    if (ofWhat === 'ground_system_connected_site_survey') {
      FancyBox.show(c.groundSysConnExamples)
    }
  }, [label, ofWhat])
  const downloadImages = React.useCallback(() => {
    const urls = filteredMediaSorted.map((value) => c.getMediaItemSrc(value))

    if (!navigator.onLine) {
      alert('No internet connection')
      return
    }

    if (!urls || !urls.length) return

    let temp: string[] = []

    urls.forEach(async (value, i) => {
      try {
        const res = await axios.get(`${value}`, {
          responseType: 'arraybuffer',
        })

        let blob = new Blob([res.data])

        let reader = new FileReader()

        reader.readAsDataURL(blob)

        reader.onload = function () {
          const formattedString = reader.result as string
          const _formatted = formattedString.replace(
            'data:application/octet-stream;base64,',
            // `data:${res.headers['content-type']}`
            '',
          )

          temp.push(_formatted)
          if (temp.length !== urls.length) {
            return
          }
          temp.map((file, i) =>
            zip.file(`${ofWhat}-${i + 1}.jpg`, file, { base64: true }),
          )

          zip
            .generateAsync({ type: 'blob' })
            .then(function (content: string | Blob) {
              if (!navigator.onLine) {
                return
              }
              saveAs(content, `${customerName} ${ofWhat} Images.zip`)
            })
        }
      } catch (error) {
        alert(
          `Error downloading image number ${
            i + 1
          } of ${label}, please try again\n${error}`,
        )
      }
    })

    zip = require('jszip')()
  }, [customerName, filteredMediaSorted, label, ofWhat])

  const maxFilesUpload = c.validateNumberOfPhotosAllowed(ofWhat)

  const hasExample = React.useMemo((): boolean => {
    if (ofWhat === 'sub_panels_site_survey') {
      return false
    }
    if (ofWhat === 'existing_solar_system_site_survey') {
      return false
    }
    if (ofWhat === 'all_appliances_and_labels_site_survey') {
      return false
    }
    const showReference =
      ofWhat === 'electrical' ||
      ofWhat.startsWith('rc_') ||
      (ofWhat.endsWith('_site_survey') && ofWhat !== 'other_site_survey')
    return showReference
  }, [ofWhat])

  const isOutdatedField =
    ofWhat === 'adders' || ofWhat === 'electrical_panel_site_survey'

  if (isOutdatedField && filteredMediaSorted.length === 0) {
    return null
  }

  return (
    <>
      <div className={gStyles['content-holder']}>
        <div className={styles['group-title']}>
          <label htmlFor={ofWhat} className={styles['label']}>
            {label}
          </label>
          <Pad amt={16} row />
          {hasExample && (
            <Octicons
              color={t.backdrop.color as string}
              name="link-external"
              onPress={handlePreviewImages}
              size={24}
              style={gs.userSelectNone}
            />
          )}
          <div className={styles['top-right-icon-row']}>
            {filteredMediaSorted.length > 0 && (
              <Feather
                color={t.canvas.opaque}
                name="download"
                onPress={downloadImages}
                size={32}
                style={gs.userSelectNone}
              />
            )}

            {gs.colGap24}

            <FileInputButton
              accept="image/*,video/*"
              disableRipple
              disabled={
                maxFilesUpload === 0 ||
                filteredMediaSorted.length >= maxFilesUpload
              }
              href={`#${ofWhat}`}
              onChange={handleFilesSelect}
              style={sx['fileInputButton']}
            >
              <Feather
                color={t.canvas.opaque}
                name="upload"
                size={32}
                style={gs.userSelectNone}
              />
            </FileInputButton>
          </div>
        </div>

        <Carousel
          containerClass={
            filteredMediaSorted.length
              ? styles['carousel-populated']
              : styles['carousel']
          }
          itemClass={styles['carousel-item']}
          responsive={responsive}
          swipeable={true}
        >
          {filteredMediaSorted.map(({ name: id }) => (
            <MediaImage
              customerID={customerID}
              key={id}
              mediaItemID={id}
              mediaKind={ofWhat}
            />
          ))}
        </Carousel>
      </div>

      <ToastAlert
        duration={6000}
        handleClose={handleSuccessFullUpload}
        message="Files Successfully Uploaded!"
        open={successfulUpload}
        severity="success"
        type="alert"
      />
    </>
  )
})

const carouselBoxBaseStyle: React.CSSProperties = {
  margin: '0 auto',
  paddingBottom: 24,
  paddingTop: 24,
  width: '100%',
  marginRight: 240,
  // @ts-ignore
  '@media (min-width: 1200px)': { marginRight: 240 },
}

const sx = {
  carouselBox: {
    ...carouselBoxBaseStyle,
    backgroundColor: c.themeTuple.light.paper.backgroundColor!,
  },
  carouselBoxDark: {
    ...carouselBoxBaseStyle,
    backgroundColor: c.themeTuple.dark.paper.backgroundColor!,
  },
  dropzone: {
    backgroundColor: c.light.backdrop.backgroundColor,
    // 'clip-path': 'rect(0 0 0 0)'
  },
  dropzoneDark: {
    backgroundColor: c.dark.backdrop.backgroundColor,
  },
  dropzoneBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'var(--ups-canvas-gap)',
  },
  dropzoneClipper: {
    width: '100%',
    zoom: 0.56,
  },
  fileInputButton: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    display: 'contents',
    margin: 0,
    padding: 0,
  },
  snackbar: { width: '298px' },
}

const roofingClaimsImage: c.DeepReadonly<
  Partial<Record<c.MediaKind, c.ExampleImage[]>>
> = {
  rc_3d_view: ROOF_CLAIMS_3D_VIEW,
  rc_address_verification: ROOF_CLAIMS_ADDRESS_VERIFICATION,
  rc_drip_edge: ROOF_CLAIMS_DRIP,
  rc_flashings: ROOF_CLAIMS_FLASHINGS,
  rc_front_house: ROOF_CLAIMS_FRONT_HOUSE,
  rc_gutters: ROOF_CLAIMS_GUTTER,
  rc_hail: ROOF_CLAIMS_HAIL,
  rc_layers: ROOF_CLAIMS_LAYERS,
  rc_length_shingle: ROOF_CLAIMS_LENGTH_SHINGLE,
  rc_other_property_wind_damage: ROOF_CLAIMS_OTHER_PROPERTY,
  rc_wind_damages: ROOF_CLAIMS_OTHER_PROPERTY,
  rc_penetrations: ROOF_CLAIMS_PENETRATIONS,
  rc_roof_inclination: ROOF_CLAIMS_ROOF_INCLINATION,
  rc_roof_overview: ROOF_CLAIMS_ROOF_OVERVIEW,
  rc_siding: ROOF_CLAIMS_SIDING,
  rc_wires_home: ROOF_CLAIMS_WIRES_HOME,
  rc_shingles_color: ROOF_CLAIMS_SHINGLES_COLOR,
  rc_garage: ROOF_CLAIMS_GARAGE,
  rc_ice_shield: ROOF_CLAIMS_ICE_SHIELD,
}

const responsive: ResponsiveType = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

// const blobToFile = (theBlob: Blob, fileName: string): File => {
//   const b: any = theBlob
//   //A Blob() is almost a File() - it's just missing the two properties below which we will add
//   b.lastModifiedDate = new Date()
//   b.name = fileName

//   //Cast to a File() type
//   return theBlob as File
// }

const compressImg = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const $canvas = document.createElement('canvas')
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      const maxEdge = 2048
      const isHorizontal = image.width > image.height
      const longEdge = isHorizontal ? image.width : image.height
      const ratio = maxEdge / longEdge
      $canvas.width = isHorizontal ? maxEdge : image.width * ratio
      $canvas.height = !isHorizontal ? maxEdge : image.height * ratio
      $canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, $canvas.width, $canvas.height)
      // To data url?
      resolve($canvas.toDataURL('image/jpeg', 0.8))
    }
    image.onerror = (e) => {
      reject(e)
    }
  })
}

const makeThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const $canvas = document.createElement('canvas')
    const image = new Image()
    image.src = URL.createObjectURL(file)

    image.onload = () => {
      const width = image.width > image.height ? 320 : 240
      const ratio = width / image.width
      $canvas.width = width
      $canvas.height = image.height * ratio
      $canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, $canvas.width, $canvas.height)

      resolve($canvas.toDataURL('image/jpeg', 0.6))
    }

    image.onerror = (error) => {
      reject(error)
    }
  })
}
