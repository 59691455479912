import React, { ReactElement, useMemo } from 'react'
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  Modal,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'

import Feather from 'react-native-vector-icons/Feather'

import MpuImage from '../screens/Customer/img/mpu_matrix.png'

export interface ModalMpuImageProps {
  onRequestClose: () => void
  visible: boolean
}

export default React.memo<ModalMpuImageProps>(function ModalMpuImage({
  onRequestClose,
  visible,
}): ReactElement {
  const sourceImage = useMemo(() => MpuImage as ImageSourcePropType, [])

  return (
    <Modal
      animationType="fade"
      onRequestClose={onRequestClose}
      transparent={false}
      visible={visible}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          <View style={styles.contentBtn}>
            <TouchableOpacity
              onPress={onRequestClose}
              style={styles.btnCloseModal}
            >
              <Feather color="white" name="x" size={24} />
            </TouchableOpacity>
          </View>
          <ScrollView
            style={styles.scrollView}
            bouncesZoom
            maximumZoomScale={5}
            minimumZoomScale={0.5}
            centerContent
          >
            <Image
              source={sourceImage}
              style={styles.mpuImage}
              resizeMode="stretch"
            />
          </ScrollView>
        </View>
      </SafeAreaView>
    </Modal>
  )
})

const { width, height } = Dimensions.get('screen')

const styles = StyleSheet.create({
  btnCloseModal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 10,
    justifyContent: 'center',
    padding: 5,
  },
  container: {
    backgroundColor: 'black',
    flex: 1,
  },
  contentBtn: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 15,
    marginVertical: 10,
  },
  mpuImage: { height: height * 0.3, width },
  scrollView: { height, width },
  webView: {
    flex: 1,
  },
})
