import * as rn from 'react-native'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as gs from '../../gStyles'

export const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const input = {
    ...t.input[on],
    ...gs.inputGrow,
    fontWeight: '400',
    paddingLeft: 16,
  } as const
  // @ts-ignore
  delete input.placeholderColor

  const inputNoMinWidth = { ...input, minWidth: 24 }
  const inputFocused = { ...input, borderColor: t[on].highlight }
  const inputFocusedNoMinWidth = {
    ...inputNoMinWidth,
    borderColor: t[on].highlight,
  }
  const inputWarning = { ...input, borderColor: t.warning }
  const inputWarningNoMinWidth = {
    ...inputNoMinWidth,
    borderColor: t.warning,
  }
  const inputShrink = { ...input, width: c.fieldWidthWeb }
  const inputShrinkFocused = { ...inputShrink, borderColor: t[on].highlight }
  const inputShrinkWarning = {
    ...inputShrink,
    borderColor: t.warning,
    width: c.fieldWidthWeb,
  }

  const inputMultiline = {
    ...input,
    paddingTop: t[on].gap,
    height: 160,
    textAlign: 'left',
    textAlignVertical: 'top',
  }
  const inputMultilineFocused = {
    ...inputMultiline,
    borderColor: t[on].highlight,
  }
  const inputMultilineShrink: rn.ViewStyle = {
    ...inputMultiline,
    width: c.fieldWidthWeb,
  }

  return {
    elementBottomRight: {
      ...gs.deadCenter,
      bottom: 0,
      padding: 24,
      position: 'absolute',
      right: 0,
    },
    elementLeft: {
      ...gs.deadCenter,
      ...gs.userSelectNone,
      borderRadius: t.input[on].borderRadius,
      borderWidth: 1,
      borderColor: 'transparent',
      height: '100%',
      left: 0,
      paddingHorizontal: 24,
      position: 'absolute',
      top: 0,
      // backgroundColor: '#9c1',
    },
    iconRight: {
      ...gs.deadCenter,
      height: '100%',
      position: 'absolute',
      paddingHorizontal: 24,
      right: 0,
      top: 0,
    },
    innerWrapper: {
      ...gs.grow,
      marginHorizontal: r.isMobile ? t[on].paddingHorizontal : undefined,
    },
    innerWrapperShrink: {
      ...gs.rowCentered,
      borderRadius: t.input[on].borderRadius,
      width: c.fieldWidthWeb,
      backgroundColor: '#919',
    },
    input,
    inputNoMinWidth,
    inputFocused,
    inputFocusedNoMinWidth,
    inputDisabled: { ...input, ...gs.disabled },
    inputDisabledNoMinWidth: { ...inputNoMinWidth, ...gs.disabled },
    inputDisabledElLeft: { ...input, ...gs.disabled, paddingLeft: 64 },
    inputDisabledElLeftNoMinWidth: {
      ...inputNoMinWidth,
      ...gs.disabled,
      paddingLeft: 64,
    },
    inputWarn: inputWarning,
    inputWarnNoMinWidth: inputWarningNoMinWidth,
    inputElementLeft: { ...input, paddingLeft: 64 },
    inputElementLeftNoMinWidth: { ...inputNoMinWidth, paddingLeft: 64 },
    inputElementLeftFocused: { ...inputFocused, paddingLeft: 64 },
    inputElementLeftFocusedNoMinWidth: {
      ...inputFocusedNoMinWidth,
      paddingLeft: 64,
    },
    inputElementLeftWarn: { ...inputWarning, paddingLeft: 64 },
    inputElementLeftWarnNoMinWidth: {
      ...inputWarningNoMinWidth,
      paddingLeft: 64,
    },
    inputShrink,
    inputShrinkDisabled: { ...inputShrink, ...gs.disabled },
    inputShrinkFocused,
    inputShrinkWarn: inputShrinkWarning,
    inputShrinkElementLeft: { ...inputShrink, paddingLeft: 64 },
    inputShrinkElementLeftFocused: { ...inputShrinkFocused, paddingLeft: 64 },
    inputShrinkElementLeftWarn: { ...inputShrinkWarning, paddingLeft: 64 },
    inputMultiline,
    inputMultilineDisabled: { ...inputMultiline, ...gs.disabled },
    inputMultilineFocused,
    inputMultilineWarn: { ...inputMultiline, borderColor: t.warning },
    inputMultilineShrink,
    inputMultilineShrinkDisabled: { ...inputMultilineShrink, ...gs.disabled },
    inputMultilineShrinkFocused: {
      ...inputMultilineShrink,
      borderColor: t[on].highlight,
    },
    inputMultilineShrinkWarn: {
      ...inputMultilineShrink,
      borderColor: t.warning,
    },
    outerWrapper: {
      borderRadius: t.input[on].borderRadius,
      width: c.fieldWidthWeb,
    },
  }
})
