import React from 'react'

import * as c from '../../common'
import * as r from '../../react-utils'

import YesOrNo from './YesOrNo'

interface ConnectedYesOrNoProps {
  centerText?: boolean
  customerID: string
  field: c.CustomerField
}

export default React.memo<ConnectedYesOrNoProps>(function ConnectedYesOrNo({
  centerText,
  customerID,
  field,
}) {
  const [customer] = r.useCustomer(customerID)
  const [yesOrNo, setYesOrNo] = r.useCustomerField(customerID, field)

  const handleUpdateCheckbox = React.useCallback(
    (newYesOrNo: 'no' | 'yes') => void setYesOrNo(newYesOrNo),
    [setYesOrNo],
  )

  return (
    <YesOrNo
      canBeEmpty={c.CustomerSchema[field] === c.YesNoEmpty}
      centerText={centerText}
      grow={!!c.efficiencyToInputs[field] || c.fieldToGrow[field]}
      hide={c.shouldHideInput(field, customer)}
      label={c.getFieldLabel(field)}
      onPress={handleUpdateCheckbox}
      value={yesOrNo as typeof c.YesNoEmpty}
    />
  )
})
