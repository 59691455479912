import * as React from 'react'

import * as r from '../../../react-utils'

import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as gs from '../../../app/gStyles'

import styles from './Breadcrumbs.module.css'

export default React.memo(function Breadcrumbs() {
  const { customerID } = useParams<'customerID'>()
  const location = useLocation()

  const [customerName] = r.useCustomerField(customerID!, 'customerName')

  const DashboardWrapper = !!customerID ? Link : 'div'

  const isAuditor = location.pathname.endsWith('/auditor')
  const CustomerWrapper = isAuditor ? Link : 'div'

  return (
    <div className={styles['breadcrumbs']}>
      {gs.colGap24}

      <DashboardWrapper className={styles['breadcrumbs-content']} to="/">
        <MaterialIcons color="#FFF" name="home" size={iconSize} />
        {gs.colGap8}
        <span className={styles['text']}>Dashboard</span>
      </DashboardWrapper>

      {!!customerID && (
        <>
          <span className={styles['text']}>{'/'}</span>

          <CustomerWrapper
            className={styles['breadcrumbs-content']}
            to={`/customers/${customerID}`}
          >
            <MaterialIcons color="#FFF" name="person" size={iconSize} />
            {gs.colGap8}
            <span className={styles['text']}>{customerName}</span>
          </CustomerWrapper>
        </>
      )}

      {isAuditor && (
        <>
          <span className={styles['text']}>{'/'}</span>

          <div className={styles['breadcrumbs-content']}>
            <MaterialIcons color="#FFF" name="assessment" size={iconSize} />
            {gs.colGap8}
            <span className={styles['text']}>Auditor</span>
          </div>
        </>
      )}
    </div>
  )
})

const iconSize = 16
