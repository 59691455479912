import React from 'react'
import * as rn from 'react-native'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import Feather from 'react-native-vector-icons/Feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SxProps } from '@mui/material/styles'

import Pad from '../Pad'

import './MediaImage.css'

export interface MediaImageProps {
  mediaKind: c.MediaKind
  mediaItemID: string
  customerID: string
}

export default React.memo<MediaImageProps>(function MediaImage({
  customerID,
  mediaItemID,
  mediaKind,
}) {
  const [open, setOpen] = React.useState(false)
  const t = r.useTheme()
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')

  const imageRef = React.useRef<HTMLImageElement | HTMLVideoElement>(
    document.createElement('img'),
  )

  const handleOpen = React.useCallback(() => {
    setOpen((current) => !current)
  }, [setOpen])

  const selectMediaItem = React.useMemo(
    (): ReturnType<typeof c.makeSelectMediaItem> => c.makeSelectMediaItem(),
    [],
  )

  const selectorArgs = React.useMemo<c.SelectMediaItemParams>(
    (): c.SelectMediaItemParams => ({
      customerID,
      mediaItemID,
      mediaKind,
    }),
    [customerID, mediaItemID, mediaKind],
  )

  const mediaItem = c.useSelector(
    (_): c.MediaItem => selectMediaItem(_, selectorArgs),
  )

  const [currentDescription, setCurrentDescription] = React.useState(
    mediaItem.description,
  )
  React.useEffect((): void => {
    setCurrentDescription(mediaItem.description)
  }, [mediaItem.description])

  const handleClickIcon = React.useCallback(() => {
    imageRef.current.click()
  }, [])

  const thumbnail = c.useSelector(c.selectThumbnail(customerID, mediaItemID))

  const handleDeleteImage = React.useCallback(() => {
    handleDelete(customerID, mediaItemID, mediaKind)
  }, [customerID, mediaItemID, mediaKind])

  React.useEffect(() => {
    // console.log(Fancybox)
    if (!imageRef) {
      return
    }
    imageRef.current?.setAttribute('data-fancybox', '')

    if (mediaItem.video_of) {
      imageRef.current?.setAttribute('data-type', 'html5video')
    }

    imageRef.current?.setAttribute(
      'data-src',
      `${
        mediaItem.video_of
          ? c.getMediaItemSrc(mediaItem)
          : c.getMediaItemSrc(mediaItem)
          ? c.getMediaItemSrc(mediaItem)
          : thumbnail
      }`,
    )
  }, [mediaItem, thumbnail])

  const handleDescriptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentDescription(e.target.value)
      c.updateMediaItem(customerID, mediaItemID, {
        description: e.target.value,
      })
    },
    [customerID, mediaItemID],
  )

  return (
    <Mui.Card
      sx={(isDark ? sx['containerCard'] : sx['containerDark']) as SxProps}
      elevation={6}
    >
      <Mui.CardActionArea>
        <MuiIcons.PlayCircleOutline
          onClick={handleClickIcon}
          sx={mediaItem.video_of ? sx['playIcon'] : sx['hide']}
        />
        <Mui.CardMedia
          //@ts-ignore
          ref={imageRef}
          component={mediaItem.image_of ? 'img' : 'video'}
          sx={sx['cardMedia'] as SxProps}
          image={
            mediaItem.video_of
              ? c.getMediaItemSrc(mediaItem)
              : thumbnail
              ? thumbnail
              : c.getMediaItemSrc(mediaItem)
          }
          alt={mediaItem.description}
        />
        <Mui.CardContent
          sx={(isDark ? sx['cardContentDark'] : sx['cardContent']) as SxProps}
        >
          <Mui.TextField
            multiline
            onChange={handleDescriptionChange}
            rows={3}
            sx={isDark ? sx['imageNotesDark'] : sx['imageNotes']}
            value={currentDescription}
          />
        </Mui.CardContent>
      </Mui.CardActionArea>
      <Mui.Modal open={open} onClose={handleOpen}>
        <Mui.Box component={Mui.Paper} sx={sx['boxModal']}>
          <Mui.Alert severity="warning">Are you sure ?</Mui.Alert>
          <Pad amt={20} />
          <Mui.Button
            color="error"
            endIcon={<MuiIcons.Delete />}
            onClick={handleDeleteImage}
            variant="contained"
          >
            Delete
          </Mui.Button>
        </Mui.Box>
      </Mui.Modal>

      <rn.Pressable onPress={handleOpen} style={sx['deleteIconWrapper']}>
        <div style={isDark ? sx['deleteIconDark'] : sx['deleteIcon']}>
          <Feather color={t.canvas.color as string} name="x" size={24} />
        </div>
      </rn.Pressable>
    </Mui.Card>
  )
})

const handleDelete = (
  customerID: string,
  mediaItemID: string,
  mediaKind: c.MediaKind,
) => {
  c.dispatch(
    c.requestedMediaDeletion({
      customerID,
      mediaItemID,
      mediaKind: mediaKind,
    }),
  )
}

const sx = {
  boxModal: {
    alignItems: 'center',
    backgroundColor: '#777',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
  },
  cardMedia: {
    backgroundColor: c.dark.paper.backgroundColor!,
    height: {
      sm: '240px',
      xs: '240px',
    },
    padding: '4px',
  },
  cardContent: {
    backgroundColor: c.light.paper.backgroundColor!,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentDark: {
    backgroundColor: c.dark.paper.backgroundColor!,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  containerCard: {
    backgroundColor: c.light.paper.backgroundColor!,
    position: 'relative',
    width: '260px',
  },
  containerDark: {
    backgroundColor: c.dark.paper.backgroundColor!,
    position: 'relative',
    width: '260px',
  },
  deleteIcon: {
    backgroundColor: c.light.paper.backgroundColor as string,
    borderColor: c.light.paper.borderColor as string,
    borderRadius: c.light.paper.borderRadius,
    borderStyle: 'solid',
    borderWidth: c.light.paper.borderWidth,
    cursor: 'pointer',
    padding: 8,
  },
  deleteIconDark: {
    backgroundColor: c.dark.paper.backgroundColor as string,
    borderColor: c.dark.paper.borderColor as string,
    borderRadius: c.dark.paper.borderRadius,
    borderStyle: 'solid',
    borderWidth: c.dark.paper.borderWidth,
    cursor: 'pointer',
    padding: 8,
  },
  deleteIconWrapper: {
    left: 0,
    paddingBottom: 32,
    paddingLeft: 8,
    paddingRight: 32,
    paddingTop: 8,
    position: 'absolute' as const,
    top: 0,
  },
  hide: {
    display: 'none',
  },
  imageNotes: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  imageNotesDark: {
    textarea: { color: '#FFFFFF' },
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  playIcon: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    boxShadow: '4px 0px 7px rgba(0,0,0,1)',
    color: '#000000',
    cursor: 'pointer',
    height: '100px',
    left: 'calc(50% - 50px)',
    position: 'absolute',
    top: '20%',
    width: '100px',
  },
}
