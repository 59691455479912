import React from 'react'
import ReactDOM from 'react-dom'

import * as c from '../common'
import * as r from '../react-utils'

import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { kebabCase } from 'change-case'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import Customer from './routes/customer'
import CustomerList from './comps/CustomerList'
import Login from './routes/login/Login'
import NotFound from './comps/NotFound'
import PrivacyPolicy from './routes/privacy-policy'
import reportWebVitals from './reportWebVitals'
import { RequireAuth, auth, fireStorage } from './fire'
import './global.css'

const Auditor = React.lazy(() => import('./routes/auditor'))

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    laptop: true
  }
}

c.setLogger(console.log)
c.setLoggerDebug(console.debug)

const ReduxWrapper = r.initProvider({
  fireStorage,
  getUserID() {
    return auth.currentUser?.uid || null
  },
})

const root = document.documentElement
const injectCSSVars = (prefix: string, theme: c.Theme | c.Layer): void => {
  for (const [k, val] of c.entries(theme)) {
    const name = prefix + kebabCase(k)
    if (typeof val === 'object') injectCSSVars(name + '-', val)
    const value = (() => {
      let finalVal = val
      // Too little on web otherwise
      if (k.startsWith('padding')) finalVal = (val as number) * 2
      if (k.startsWith('gap')) finalVal = (val as number) * 2
      return typeof finalVal === 'number'
        ? finalVal.toString() + 'px'
        : finalVal!
    })()
    root.style.setProperty(name, value)
  }
}

const generalMUITheme = createTheme({
  palette: {
    primary: {
      main: c.light.backdrop.highlight!,
    },
  },
  breakpoints: {
    values: r.MUIBreakpoints,
  },
})

const auditorFallback = (
  <div
    style={{
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      width: '100vw',
    }}
  >
    <span>Loading...</span>
  </div>
)

const AppRoutes = React.memo(() => {
  const location = useLocation()
  const background = location.state && location.state.background

  return (
    <>
      <Routes location={background || location}>
        <Route
          index
          element={
            <RequireAuth>
              <Navigate to="/customers" />
            </RequireAuth>
          }
        />

        <Route element={<Login />} path="/login" />

        <Route
          element={
            <RequireAuth>
              <CustomerList />
            </RequireAuth>
          }
          path="/customers"
        />

        <Route
          element={
            <RequireAuth>
              <Customer />
            </RequireAuth>
          }
          path="/customers/:customerID"
        />

        {/* <Route
            path="/customers/:customerID/glow"
            element={
              <RequireAuth>
                <GlowModal onClose={handleGlowModalClose} />
              </RequireAuth>
            }
          /> */}
        <Route
          element={
            <React.Suspense fallback={auditorFallback}>
              <RequireAuth>{<Auditor />}</RequireAuth>
            </React.Suspense>
          }
          path="/customers/:customerID/auditor"
        />

        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
})

const App = React.memo(function App() {
  // This is shared across Native and Web
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  // This var is used by MaterialUI
  const [mode = 'light', setMode] = React.useState<'dark' | 'light'>(
    prefersDarkMode ? 'light' : 'dark',
  )

  React.useEffect(() => {
    if (mode === 'dark') {
      injectCSSVars('--ups-', c.dark)
    }
    if (mode === 'light') {
      injectCSSVars('--ups-', c.light)
    }
  }, [mode])

  React.useEffect(() => {
    c.dispatch(c.closersAction())
    c.dispatch(c.settersAction())
  }, [])

  React.useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light')
  }, [prefersDarkMode, setMode])
  React.useEffect(() => {}, [mode])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ReduxWrapper>
        <BrowserRouter>
          <ThemeProvider theme={generalMUITheme}>
            <AppRoutes />
          </ThemeProvider>
        </BrowserRouter>
      </ReduxWrapper>
    </LocalizationProvider>
  )
})

ReactDOM.render(
  (<App />) as React.ReactElement, // The cast is just to avoid React not used error
  document.body,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
