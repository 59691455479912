import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'

import ImageResizer from 'react-native-image-resizer'

export type CurrScreenRefType =
  | { scrollTo?(args: { animated: boolean; x: number; y: number }): void }
  | { scrollToOffset?(args: { animated: boolean; offset: number }): void }
  | null
export const currScreenRef = React.createRef<CurrScreenRefType>()
export let currScreenOffset: number = 0
export const setCurrScreenOffset = (offset: number) =>
  (currScreenOffset = offset)

interface ResizeImgOpts {
  readonly maxEdge?: number
  readonly quality?: number
  readonly rotation?: number
}

export const resizeImage = async (
  res: c.MobileFile,
  { maxEdge = 2048, quality = 100, rotation = 0 }: ResizeImgOpts = {},
): Promise<c.MobileFile> => {
  try {
    const isHorizontal = res.width > res.height

    const longEdge = isHorizontal ? res.width : res.height

    if (maxEdge >= longEdge) {
      return res
    }

    const ratio = maxEdge / longEdge
    const newWidth = Math.round(res.width * ratio)
    const newHeight = Math.round(res.height * ratio)

    const resCompress = await ImageResizer.createResizedImage(
      res.path,
      newWidth,
      newHeight,
      'JPEG',
      quality,
      rotation,
      undefined,
      false,
    )

    return {
      height: newHeight,
      mime: 'image/jpeg',
      path: resCompress.path,
      width: newWidth,
    }
  } catch (e) {
    throw new TypeError(`Unknown resize image ${e}`)
  }
}

// Weird cast?
export const emptyTextInput = (<rn.TextInput />) as unknown as rn.TextInput

export const useLayout = () => {
  const [layout, setLayout] = React.useState<rn.LayoutRectangle>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const handleLayoutEvent = React.useCallback(
    ({ nativeEvent: { layout } }: rn.LayoutChangeEvent): void => {
      setLayout(layout)
    },
    [],
  )

  return [layout, handleLayoutEvent] as const
}
