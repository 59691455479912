import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as gs from '../../gStyles'
import Labeled from '../Labeled'

import NativeTextInput, { TextInputProps } from './TextInput.native'
import { themedStyles } from './styles'

const TextInputWebForwarded = React.forwardRef<
  r.Focusable | undefined,
  TextInputProps
>(function TextInputWeb({ multiline, ...restProps }, ref) {
  const {
    elementLeftBlurred,
    elementLeftFocused,
    guideline,
    guidelineType,
    hide,
    label = '',
    labelIconRight,
    labelShowAsterisk,
    mask,
    on,
    // onPressElementLeft,
    shrink,
    warn,
    // Native passthrough props
    clearButtonMode,
    editable = true,
    onChangeText,
    value,
  } = restProps

  //#region ref
  const inputRef = React.useRef<HTMLTextAreaElement>()
  React.useImperativeHandle(ref, () => inputRef.current as r.Focusable)
  //#endregion ref

  // const t = r.useTheme()
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const [focused, , toggleFocused] = r.useBool(false)

  const handleChangeText = React.useCallback(
    (e: c.r<{ target: { value: string } }>): void => {
      onChangeText?.(e.target.value)
    },
    [onChangeText],
  )

  // const handleClear = React.useCallback(
  //   () => void onChangeText?.(''),
  //   [onChangeText]
  // )

  if (!multiline) {
    return <NativeTextInput {...restProps} />
  }

  if (mask) throw new TypeError(`Mask prop not supported for web multiline`)

  const mightRenderClearBtn = editable && clearButtonMode !== 'never'
  const willRenderClearBtn =
    mightRenderClearBtn &&
    ((clearButtonMode === 'while-editing' && focused) ||
      (clearButtonMode === 'unless-editing' && !focused) ||
      clearButtonMode === 'always')

  const isWrapped = Boolean(
    elementLeftBlurred || elementLeftFocused || label || willRenderClearBtn,
  )
  const inputStyle = (() => {
    if (isWrapped || !shrink) {
      if (!editable) {
        return styles.inputMultilineDisabled
      }
      return focused
        ? styles.inputMultilineFocused
        : warn
        ? styles.inputMultilineWarn
        : styles.inputMultiline
    }

    if (!editable) {
      return styles.inputMultilineShrinkDisabled
    }
    return focused
      ? styles.inputMultilineShrinkFocused
      : warn
      ? styles.inputMultilineShrinkWarn
      : styles.inputMultilineShrink
  })()

  let txtInputNode = (
    <textarea
      disabled={!editable}
      onBlur={toggleFocused}
      onFocus={toggleFocused}
      onChange={handleChangeText}
      // @ts-ignore
      style={inputStyle}
      value={value || undefined}
    />
  )

  if (label) {
    txtInputNode = (
      <Labeled
        grow={!shrink}
        guideline={guideline}
        guidelineType={guidelineType}
        hide={hide}
        iconRight={labelIconRight}
        label={label}
        on={on}
        showAsterisk={labelShowAsterisk}
      >
        {txtInputNode}
      </Labeled>
    )
  }

  return txtInputNode
})

export default React.memo(TextInputWebForwarded)

export * from './TextInput.native'
