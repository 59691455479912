import React from 'react'

import * as c from '../../common'

import moment from 'moment'

import * as gs from '../gStyles'

import Labeled from './Labeled'
import Text from './Text'

export type MomentProps = c.r<{
  alignRight?: boolean
  /**
   * Unix timestamp. Optional if `field` is provided.
   */
  children?: number
  /**
   * Customer from which `field` will be fetched from. Optional if `children`
   * provided. Mandatory if `field` is provided.
   */
  customerID?: string
  /**
   * Customer field to connect to and display. Optional if `children` is
   * provided. Mandatory if `customerID` is provided.
   */
  field?: c.CustomerField
  format: string
  label?: string
  labelIconRight?: React.ReactElement
  on: c.Surface
  shrink?: boolean
}>

export default React.memo<MomentProps>(function Moment({
  alignRight,
  children,
  customerID = '',
  field,
  format,
  label,
  labelIconRight,
  on,
  shrink,
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)
  const fieldSelector = React.useMemo(() => c.makeSelectCustomerField(), [])
  const fieldSelectorArgs = React.useMemo(
    (): c.SelectCustomerFieldParams => ({
      customerID,
      // Will make selector return 0 which is falsy
      field: field || 'date',
    }),
    [customerID, field],
  )
  const currFieldValue = c.useSelector((_) =>
    fieldSelector(_, fieldSelectorArgs),
  )

  const formatted = React.useMemo((): string => {
    if (children) {
      if (customerID || field)
        throw new TypeError(
          'Must not provide both children and customerID/field at the same time.',
        )

      return moment(c.normalizeTimestampToMs(children)).format(format)
    }

    if (customerID || field) {
      if (children)
        throw new TypeError(
          'Must not provide both customerID+field and children at the same time.',
        )

      if (!customerID)
        throw new TypeError('Must provide customerID together with field.')
      if (!field)
        throw new TypeError('Must provide field together with customerID.')

      const positiveTimestamp =
        field === 'sort_key'
          ? (currFieldValue as number) * -1
          : (currFieldValue as number)

      return moment(c.normalizeTimestampToMs(positiveTimestamp)).format(format)
    }
    throw new TypeError('Unreachable')
  }, [children, currFieldValue, customerID, field, format])

  const txtNode = (
    <Text style={alignRight ? styles.txtAlignRight : styles.txt}>
      {formatted}
    </Text>
  )

  if (field || label) {
    return (
      <Labeled
        alignRight={alignRight}
        grow={!shrink}
        iconRight={labelIconRight}
        label={(field ? c.getFieldLabel(field) : label) || ''}
        on={on}
      >
        {txtNode}
      </Labeled>
    )
  }

  return txtNode
})

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const txt = {
    color: t[on].color,
    fontFamily: t.input[on].fontFamily,
    fontSize: t.input[on].fontSize,
  }
  return {
    txt,
    txtAlignRight: { ...txt, textAlign: 'right' },
  }
})
