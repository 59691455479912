import * as React from 'react'

import * as env from '../env'
import * as schema from '../schema'
import * as utils from '../utils'
import { Customer, MediaItem, MediaKind } from '../schema'
import { Writable, isBetween, range, stringIsNumber } from '../utils'

export type FieldToTrait<T> = Partial<Readonly<Record<schema.CustomerField, T>>>

export const fieldToLabel: FieldToTrait<string> = {
  air_conditioner: 'Air Conditioner',
  air_conditioner_current_tons: 'How many tons is your current AC?',
  air_conditioner_furnance: 'Will you include the furnance?',
  air_conditioner_new_tons: 'How many tons will the new AC be? ',
  air_conditioner_replace_ducts: 'Are you replacing ducts?',
  air_conditioner_size: 'Size',
  air_conditioner_unit_type: 'What type AC unit are you going to  install?',
  air_conditioner_working: 'Do you currently have an AC?',
  // air_conditioner_explain_situation: 'Explain the situation of the home',
  // air_conditioner_type_installation: 'Type of Installation',
  air_conditioner_any_swamp_cooler: 'Do you have any swamp cooler(s)?',
  air_conditioner_swamp_cooler_use: 'Are you using a swamp cooler?',
  air_conditioner_tons_how_many: 'How many tons will the new AC be?',
  air_conditioner_swamp_cooler_use_remove:
    'Would you be removing any swamp coolers?',
  air_conditioner_replace_curve: 'Are you changing the AC size?',
  attic_fan_quantity: 'How many?',
  attic_fan: 'Attic Fan',
  attic_insulation_type: 'What type of insulation?',
  attic_insulation: 'Attic Insulation',
  attic_insulation_how_much: 'Square footage to be insulated',
  attic_square_footage: 'Attic square footage',
  battery_type: 'Select battery type',
  battery_size: 'Select battery size',
  battery: 'Battery',
  cash_amount: 'Cashback amount',
  cash_pool_pumps: 'Pool Pumps',
  cash_heat_pumps: 'Heat Pumps',
  cash_whole_house_fans_or_ventilation: 'Whole House Fans / Ventilation',
  cash_efficient_boilers: 'Efficient Boilers',
  cash_geothermal_heat_pumps: 'Geothermal Heat Pumps',
  cash_radiant_floors: 'Radiant Floors',
  cash_water_saving_fixtures: 'Water Saving Fixtures',
  cash_solar_water_heaters: 'Solar Water Heaters',
  cash_on_demand_or_tankless_hot: 'On Demand / Tank-less Hot Water Heater',
  cash_efficient_water_heaters: 'Efficient Water Heaters',
  cash_artificial_turf: 'Artificial Turf',
  cash_irrigation_systems: 'Irrigation Systems',
  cash_windows: 'Windows',
  cash_air_conditioner: 'Air Conditioner',
  cash_floor_insulation: 'Floor Insulation',

  cash: 'Cash',
  createdAt: 'Created at',
  crm_id: 'Crm Id',
  custom_eight_fields_description: 'Description',
  custom_eight_fields_name: 'Name',
  custom_eight_fields: 'Custom 8',
  custom_fields_description: 'Description',
  custom_fields_name: 'Name',
  custom_fields: 'Custom 1',
  custom_five_fields_description: 'Description',
  custom_five_fields_name: 'Name',
  custom_five_fields: 'Custom 5',
  custom_four_fields_cost: 'Cost',
  custom_four_fields_description: 'Description',
  custom_four_fields_name: 'Name',
  custom_four_fields: 'Custom 4',
  custom_nine_fields_description: 'Description',
  custom_nine_fields_name: 'Name',
  custom_nine_fields: 'Custom 9',
  custom_seven_fields_description: 'Description',
  custom_seven_fields_name: 'Name',
  custom_seven_fields: 'Custom 7',
  custom_six_fields_cost: 'Cost',
  custom_six_fields_description: 'Description',
  custom_six_fields_name: 'Name',
  custom_six_fields: 'Custom 6',
  custom_ten_fields_cost: 'Cost',
  custom_ten_fields_description: 'Description',
  custom_ten_fields_name: 'Name',
  custom_ten_fields: 'Custom 10',
  custom_three_fields_description: 'Description',
  custom_three_fields_name: 'Name',
  custom_three_fields: 'Custom 3',
  custom_two_fields_description: 'Description',
  custom_two_fields_name: 'Name',
  custom_two_fields: 'Custom 2',
  customerAddress: 'Customer address',
  customerEmail: 'Customer email',
  customerName: 'Customer name',
  customerPhone: 'Customer phone',
  customerPhoneAlt: 'Alt phone number (opt)',
  date: 'Created at',
  derate: 'Derate',
  derate_cost: 'Cost',
  duct_insulation_cost: 'Cost',
  duct_insulation: 'Duct Insulation',
  duct_repair_quantity: 'How many?',
  duct_repair: 'Duct Repair',
  //#region duct_replacement
  duct_replacement: 'Duct Replacement',
  duct_replacement_qty: 'How many ducts will be replaced?',
  //#endregion duct_replacement
  duct_seal_cost: 'Cost',
  duct_seal: 'Duct Seal',
  flat_roof_panels: 'Flat Roof Panels',
  flat_roof_panels_cost: 'Cost',
  flat_roof_panels_how_many: 'How many?',
  globalNotes: 'Global customer notes',
  glow: 'Protocol Fee (Glow Gold)',
  glow_price_per_watt: 'Price Per Kw/h from the utility bill',
  glow_power_output_mw: 'System Size in Kw',
  incentive_adders_description: 'Incentive/Adders Description',
  main_panel_upgrade: 'Main Panel Upgrade (MPU)',
  main_panel_upgrade_needed_or_requested: 'Is needed or requested by customer?',
  main_panel_upgrade_installation_company: 'Installer',
  mini_split: 'Mini Split',
  mini_split_ton1: 'Mini Split System 1Ton',
  mini_split_ton2: 'Mini Split System 2Ton',
  mini_split_cost: 'Cost',
  new_ducts_description: 'Description',
  new_ducts_quantity: 'How many?',
  new_ducts_replace: 'Replacing ducts?',
  new_ducts: 'New Ducts',
  // #region new_windows
  new_windows_areas: 'What areas?',
  new_windows_color: 'Window colors',
  new_windows_cost: 'Cost',
  new_windows_grid: 'Grid windows?',
  new_windows_quantity: 'Total Number of Windows',
  new_windows_sqft_each_window: 'Sqft of each window?',
  new_windows_sliding_glass_sqft: 'Feet of each sliding glass door?',
  new_windows_sliding_glass: 'Sliding glass doors included?',
  new_windows_sliding_glass_how_many:
    'Total Number of Glass Sliding Doors included:',
  new_windows_sqft: 'How Much Square Footage?',
  new_windows_replace: 'Are you replacing the windows on the whole house?',
  new_windows_replace_custom:
    'What areas of home windows will be installed in detail?',
  new_windows: 'Windows',
  new_windows_california_city: 'Choose a city',
  new_windows_sliding_glass_size: 'Sliding glass door Size',
  newWindows: 'Windows & Sliding Glass Doors',
  // #endregion new_windows
  pool_pump_cost: 'Cost',
  pool_pump: 'Pool Pump',
  // #region roof
  roof_cost: 'Cost',
  roof_california_city: 'Choose a city',
  roof_square_footage: 'Roof squares',
  roof_fascia_included: 'Are we including fascia?',
  roof_fascia_included_square_footage:
    'How many linear feet are we going to included?',
  roof_layover_or_tear: 'Choose type of roof work',
  roof_tear_material: 'Material duration',
  roof_patio_included: 'Patio/ flat roof included?',
  roof_patio_how_many: 'How many squares?',
  roof_pitch: 'What is the pitch of the roof?',
  roof_plywood_under_tile: 'Is there plywood under the roof tile?',
  roof_plywood_replaced: 'Will you be replacing all the plywood?',
  roof_plywood_replaced_square: 'How many squares?',
  roof_will_add_license: 'Pulling permits?',
  roof_commission_add_up: 'Commission add up',
  roof: 'Roof',
  // #endregion roof
  // Closer
  homeRep: 'EcoHome rep (closer)',
  smart_thermostat_how_many: 'How many?',
  smart_thermostat: 'Smart Thermostat',
  solar_tax: 'Solar Tax Consultant',
  solarCompany: 'State',
  products: 'Products',
  solarEmail: 'Solar rep email',
  // Setter
  solarRep: 'Solar rep (setter)',
  sort_key: 'Last update',
  sub_panel_upgrade: 'Sub Panel Upgrade',
  ssn: 'Ssn',
  system_size: 'System size',
  small_system: 'Small System',
  yearHomeBuilt: 'Year home was built',
  panel_removal: 'Panel Removal',
  panel_removal_how_many: 'How many panels do you need to remove?',

  // Checklist

  attach_thermal_camera: 'Attach thermal camera to the phone',

  take_picture_of_home: 'Take picture of home',
  get_debts_report: 'Get debts report',

  perform_energy_assessment: 'Perform energy assessment',
  /**/ use_thermal_camera: 'Use thermal camera',
  /**/ take_quiz: 'Take survey',
  /**/ use_room_that_gets_hot: 'Room that gets hot',
  /**/ use_smoke_pen: 'Use smoke pen',

  take_pictures: 'Take pictures',

  fill_out_prices: 'Fill out prices',

  verify_pricing_or_ppw: 'Verify pricing/PPW (info)',

  show_ecohome_report: `Show ${env.HOLDING_NAME} report`,

  show_solo_report: 'Show Solo report',

  /**/ /**/

  run_credit: 'Run credit',

  change_to_correct_email: 'Change to correct email',

  sign_financing_agreement: 'Sign financing agreement (info)',
  /**/ sign_cosigner: 'Sign co-signer',

  submit_install_docs_in_solo: 'Submit install docs in Solo (info)',
  /**/ counter_sign_rep_install_doc: 'Counter-sign rep install doc (info)',
  /**/ sign_customer_install_docs: 'Sign customer install docs (info)',

  ecohome_report: `${env.HOLDING_NAME} report`,
  /**/ make_sure_ecohome_and_solo_match:
    'Make Sure Ecohome/Solo #s match (info)',
  /**/ take_picture_of_utility_bill: 'Take picture of utility bill',
  /**/ take_picture_of_id: 'Take picture of ID',
  /**/ take_pictures_of_meter: 'Take pictures of meter (example)',
  /**/ submit_detailed_notes_for_efficiencies:
    'Submit detailed notes for efficiencies (info)',
  /**/ review_notes_with_customers: 'Review notes w/ customers',
  /**/ sign_ecohome_report: `Sign ${env.HOLDING_NAME} report`,
  /**/ get_google_review_and_or_pic_with_customer:
    'Get a 5-star Google Review from the client',
  /**/ take_pic_with_customer: 'Take picture with customer',
  /**/ offer_incentives_for_referrals: 'Offer incentives for referrals',

  update_notes_on_HL: 'Update notes on HL',

  report_sale_to_slack: 'Report sale to Slack!',

  // End Checklist
  // Roofing Claiming

  roof_claiming_date: 'Date',
  roof_claiming_homeowner: 'Homeowner',
  roof_claiming_phone: 'Phone',
  roof_claiming_phone_alt: 'Alt Phone',
  roof_claiming_email: 'Email',
  roof_claiming_address: 'Address',
  roof_claiming_city: 'City',
  roof_claiming_zip: 'Zip',
  roof_claiming_deductible: 'Deductible',
  roof_claiming_shingle_manufacturer: 'Shingle Manufacturer',
  roof_claiming_style: 'Style',
  roof_claiming_color: 'Color',
  roof_claiming_drip_color: 'Drip Color',

  roof_claiming_tear_off_layers_shingles: 'Tear off all layers of shingles',
  roof_claiming_install_felt: 'Install Felt',
  roof_claiming_close_valleys: 'Close Valleys ',
  roof_claiming_ridges_color_coordinated: 'Ridges Color Coordinated',
  roof_claiming_install_new_flashings: 'Install New Flashings',
  roof_claiming_replace_ventilation: 'Replace ventilation as needed',
  roof_claiming_install_nails: 'Install nails per code',
  roof_claiming_clean_job_waste_gutters: 'Clean job waste from gutters',
  roof_claiming_two_year_workmanship_warranty: '2 year workmanship warranty ',
  roof_claiming_magnetic_sweep_property:
    'Magnetic sweep property after install ',

  roof_claiming_age: 'Age',
  roof_claiming_layers: 'Layers',
  roof_claiming_predominate_pitch: 'Predominate Pitch',

  roof_claiming_pipe_jack: 'Piple Jack (QTY)',
  roof_claiming_chimney_flashing: 'Chimney Flashing (QTY)',
  roof_claiming_chimney_cap: 'Chimney Cap (QTY)',
  roof_claiming_digital_satellite: 'Digital Satellite (QTY)',
  roof_claiming_gas_cap: 'Gas Vent/Rain Cap (QTY)',
  roof_claiming_other_one: 'Other',

  roof_claiming_turtle_vent: 'Turtle Vent (QTY)',
  roof_claiming_ridge_vent: 'Ridge Vent (QTY)',
  roof_claiming_turbine_vent: 'Turbine Vent (QTY)',
  roof_claiming_power_attic_vent: 'Power Attic Vent (QTY)',
  roof_claiming_other_two: 'Other',
  roof_claiming_other_three: 'Other',

  roof_claiming_notes: 'Roof claim notes',
  roof_claiming_documents_notes: 'Notes',

  roof_claiming_date_loss: 'Date of Loss',
  roof_claiming_insurance_company: 'Insurance Company',
  roof_claiming_claim: 'Claim #',

  roof_claiming_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign: 'Authorized Signature',

  roof_claiming_printed_name: 'Printed Name',
  roof_claiming_completion_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign_date: 'Date',
  roof_claiming_completion_client_sign_date: 'Date',

  // End Roofing Claiming

  qualify_glow_birth_date: 'Birth Date',
  qualify_glow_city: 'City',
  qualify_glow_email: 'Email',
  qualify_glow_first_name: 'First Name',
  qualify_glow_last_name: 'Last Name',
  qualify_glow_phone_number: 'Phone Number',
  qualify_glow_ssn: 'Last 4 Digits or Full SSN',
  qualify_glow_state: 'State',
  qualify_glow_street: 'Street',
  qualify_glow_zip: 'Zip',

  incentive_glow_carbon_credits: 'Carbon Credits Produce',
  incentive_glow_cash: 'Glow Cash',
  incentive_glow_tax_deduction: 'Tax Deduction',
  incentive_glow_federal_tax_credit: 'Federal Tax Credit',
}

/**
 * Will only contain one dependent for now.
 */
export const yesNoToDependent: FieldToTrait<[schema.CustomerField]> = {
  air_conditioner_swamp_cooler_use: ['air_conditioner_swamp_cooler_use_remove'],
  air_conditioner_working: ['air_conditioner_current_tons'],
  new_windows_replace: ['new_windows_replace_custom'],
  new_windows_sliding_glass: ['new_windows_sliding_glass_sqft'],
  roof_fascia_included: ['roof_fascia_included_square_footage'],
  roof_patio_included: ['roof_patio_how_many'],
  roof_plywood_replaced: ['roof_plywood_replaced_square'],
}

export const fieldToDependency: FieldToTrait<schema.CustomerField> = (() => {
  const res = {}
  // @ts-ignore
  for (const [dependency, [dependent]] of utils.entries(yesNoToDependent)) {
    // @ts-ignore
    res[dependent] = dependency
  }
  return res
})()

/**
 * Sub-inputs are guaranteed to be 1 or 2 in length. Inputs should be rendered
 * in the order of this object's keys.
 */
export const efficiencyToInputs: FieldToTrait<schema.CustomerFields> = {
  attic_insulation: ['attic_insulation_type', 'attic_insulation_how_much'],
  duct_seal: [],
  roof: [
    'roof_square_footage',
    'roof_pitch',
    'roof_layover_or_tear',
    'roof_layers_how_many',
    'roof_tear_material',
    'roof_fascia_included',
    'roof_patio_included',
    'roof_plywood_under_tile',
    'roof_plywood_replaced',
    'roof_will_add_license',
    'roof_commission_add_up',
  ],
  new_windows: [
    'new_windows_sqft_each_window',
    'new_windows_color',
    'new_windows_grid',
    'new_windows_sliding_glass',
    'new_windows_replace',
  ],
  air_conditioner: [
    'air_conditioner_working',
    'air_conditioner_replace_ducts',
    'air_conditioner_swamp_cooler_use',
    'air_conditioner_new_tons',
    'air_conditioner_unit_type',
    'air_conditioner_furnance',
  ],
  battery: [
    'main_panel_upgrade_installation_company',
    'battery_type',
    'battery_size',
  ],
  main_panel_upgrade: [
    'main_panel_upgrade_installation_company',
    'main_panel_upgrade_needed_or_requested',
  ],
  derate: ['main_panel_upgrade_installation_company'],
  mini_split: ['mini_split_tons'],
  smart_thermostat: ['smart_thermostat_how_many'],
  attic_fan: ['attic_fan_quantity'],
  pool_pump: [],
  duct_replacement: ['duct_replacement_qty'],
  duct_repair: ['duct_repair_quantity'],
  cash: ['cash_amount'],
  solar_tax: [],
  small_system: ['main_panel_upgrade_installation_company'],
  flat_roof_panels: ['flat_roof_panels_how_many'],
  panel_removal: ['panel_removal_how_many'],
  // sub_panel_upgrade: [],
  // glow: ['glow_price_per_watt', 'glow_power_output_mw'],
  custom_fields: ['custom_fields_name', 'custom_fields_description'],
  custom_two_fields: [
    'custom_two_fields_name',
    'custom_two_fields_description',
  ],
  custom_three_fields: [
    'custom_three_fields_name',
    'custom_three_fields_description',
  ],
  custom_four_fields: [
    'custom_four_fields_name',
    'custom_four_fields_description',
  ],
  custom_five_fields: [
    'custom_five_fields_name',
    'custom_five_fields_description',
  ],
  custom_six_fields: [
    'custom_six_fields_name',
    'custom_six_fields_description',
  ],
  custom_seven_fields: [
    'custom_seven_fields_name',
    'custom_seven_fields_description',
  ],
  custom_eight_fields: [
    'custom_eight_fields_name',
    'custom_eight_fields_description',
  ],
  custom_nine_fields: [
    'custom_nine_fields_name',
    'custom_nine_fields_description',
  ],
  custom_ten_fields: [
    'custom_ten_fields_name',
    'custom_ten_fields_description',
  ],
}

export const inputToEfficiency: FieldToTrait<schema.CustomerField> = (() => {
  const res: Writable<FieldToTrait<schema.CustomerField>> = {}

  for (const [efficiency, inputs] of utils.entries(efficiencyToInputs)) {
    for (const input of inputs!) res[input] = efficiency
    res[(efficiency + '_notes') as schema.CustomerField] = efficiency
    // res[efficiency] = efficiency
  }

  // @ts-ignore Typescript whack here
  for (const [input, [subInput]] of utils.entries(yesNoToDependent)) {
    // @ts-ignore Typescript whack here
    res[subInput] = res[input]
  }

  return res
})()

// TODO: Do this via database
if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allFields = utils.keys(efficiencyToInputs)
    const nonGlowFields = allFields.filter(
      (field) => !schema.glowFields.includes(field),
    )
    for (const field of nonGlowFields) {
      delete efficiencyToInputs[field]
    }
  } else {
    for (const field of schema.mainOnlyFields) {
      delete efficiencyToInputs[field]
    }
  }
}

export const efficiencyKeys = utils.keys(efficiencyToInputs)
export const nonCustomEfficiencyKeys = efficiencyKeys.filter(
  (k) => !k.startsWith('custom_'),
)

export const getFieldLabel = (
  field: keyof Customer,
  customer?: schema.Customer,
): string => {
  if (field === 'homeRep' && env.CODENAME === 'glow') {
    return 'Sales Representative'
  }
  if (field === 'roof_layers_how_many') {
    const roofingType = customer?.roof_layover_or_tear

    if (roofingType === schema.RoofWorkType.Layover) {
      return 'How many layers?'
    }
    if (roofingType === schema.RoofWorkType.ReRoofShingles) {
      return 'Current roof layers'
    }
    if (roofingType === schema.RoofWorkType.ReRoofFlatRoof) {
      return 'Current roof layers'
    }
    if (roofingType === schema.RoofWorkType.ReRoofWoodShake) {
      return 'Current roof layers'
    }
    return ''
  }

  const maybeLabel = fieldToLabel[field]

  if (maybeLabel) return maybeLabel
  if (field.endsWith('customer_cost')) return 'Customer cost'
  if (field.endsWith('cost')) return 'Cost'
  if (field.endsWith('extra_notes')) return 'Extra notes'
  if (field.toLowerCase().includes('notes')) return 'Notes'
  return field
}

export const getMediaItemSrc = (mediaItem: MediaItem): string => {
  return mediaItem.status === 'uploaded'
    ? 'https://firebasestorage.googleapis.com/v0/b/' +
        env.commonEnv.FB_PROJECT_ID +
        '.appspot.com/o/' +
        mediaItem.order_id +
        '%2F' +
        // TODO: thumbnail
        mediaItem.name +
        `?alt=media&token=dummy`
    : 'file://' + mediaItem.path
}

export const qualifyGlowPrimaryApplicantInputs: readonly schema.CustomerFields[] =
  [
    ['qualify_glow_first_name', 'qualify_glow_last_name'],
    ['qualify_glow_phone_number', 'qualify_glow_email'],
    ['qualify_glow_ssn', 'qualify_glow_birth_date'],
  ]

export const qualifyAddressInfoInputs: readonly schema.CustomerFields[] = [
  ['qualify_glow_street'],
  ['qualify_glow_city', 'qualify_glow_state', 'qualify_glow_zip'],
]

export const normalizeThumbnail = (
  customerID: string,
  thumbnail: string,
): string => {
  if (thumbnail === 'default_house.png') {
    return ''
  }
  if (thumbnail.length === 0) {
    return ''
  }
  if (thumbnail.startsWith('http')) {
    return thumbnail
  }
  if (thumbnail.startsWith('data:image/')) {
    return thumbnail
  }
  // Base64 jpg
  if (thumbnail.startsWith('/9j/')) {
    return 'data:image/jpeg;base64,' + thumbnail
  }
  // Base64 png
  if (thumbnail.startsWith('iVBO')) {
    return 'data:image/png;base64,' + thumbnail
  }

  return (
    'https://firebasestorage.googleapis.com/v0/b/' +
    env.commonEnv.FB_PROJECT_ID +
    '.appspot.com/o/' +
    customerID +
    '%2F' +
    thumbnail +
    '?alt=media&token=69e431fa-59ad-497a-b700-a3aebcc68fc6'
  )
}

export const mediaToLabel: Partial<Record<MediaKind, string>> = {
  adders: 'Adjustment screenshots',
  electricity: 'Electricity bill',
  owner_house: 'House front view (showing address)',
  insulation: 'Attic',
  roof: 'Roof',
  windows: 'Windows',
  problem: 'Other areas',
  hvac_system: 'Hvac',
  id: 'ID picture',
  other_documents: 'Other documents',
  solar_proposal: 'Solar proposal',
}

export const categoryToMediaKinds: Readonly<
  Record<string, readonly MediaKind[]>
> = {
  Assessment: ['insulation', 'roof', 'windows', 'hvac_system', 'problem'],
  Documentation: [
    'id',
    'electricity',
    'solar_proposal',
    'adders',
    'other_documents',
  ],
}

if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allKinds = utils.keys(mediaToLabel)
    const nonGlowKinds = allKinds.filter(
      (field) => !schema.glowMedia.includes(field),
    )
    for (const field of nonGlowKinds) {
      delete mediaToLabel[field]
    }
  } else {
    for (const mediaKind of schema.mainOnlyMedia) {
      delete mediaToLabel[mediaKind]
    }
  }
}

export const mediaToLabelSolarSurveyOld: Partial<Record<MediaKind, string>> = {
  thermal: 'Thermal camera',
  all_exterior_walls_site_survey: 'All exterior walls',
  rafters_site_survey: 'Rafters',
  all_planes_roof_site_survey: 'All planes roof',
  electrical: 'Electrical panel (do not use, old customers only)',
  other_site_survey: 'Other',
  electrical_panel_site_survey:
    'Electrical panel (do not use, old customers only)',
}
export const solarSurveyMediaKindsOld = utils.keys(mediaToLabelSolarSurveyOld)
export const mediaToLabelSolarSurveyNew: Partial<Record<MediaKind, string>> = {
  thermal: 'Thermal camera',
  all_exterior_walls_site_survey: 'All exterior walls',
  rafters_site_survey: 'Rafters',
  all_planes_roof_site_survey: 'All planes roof',
  main_service_panel_site_survey: 'Main service panel',
  eight_foot_msp_site_survey: '8 foot msp',
  utility_meter_closeup_site_survey: 'Utility meter close up',
  main_panel_label_site_survey: 'Main panel label',
  main_panel_breakers_dead_front_included_site_survey:
    'All breakers labeled on lid',
  main_panel_breakers_dead_front_removed_site_survey:
    'All breakers with lid removed',
  main_breaker_disconnect_closeup_site_survey: 'Main breaker',
  ground_system_connected_site_survey: 'Grounding (if visible)',
  sub_panels_site_survey: 'Sub-panel(s) (if any)',
  existing_solar_system_site_survey: 'Existing solar system (if any)',
  all_appliances_and_labels_site_survey: 'All appliances and labels',
  other_site_survey: 'Other',
}
export const solarSurveyMediaKindsNew = utils.keys(mediaToLabelSolarSurveyNew)

export const mediaToLabelRoofClaims: Partial<Record<MediaKind, string>> = {
  rc_front_house: 'Front of the house',
  rc_address_verification: 'Address verification',
  rc_3d_view: '3D view',
  rc_garage: 'Garage',
  rc_wires_home: 'Wires in to home',
  rc_siding: 'Siding',
  rc_layers: 'Shingle layers',
  rc_roof_inclination: 'Roof inclination angle',
  rc_roof_overview: 'Roof overview',
  rc_drip_edge: 'Drip edge',
  rc_gutters: 'Gutters',
  rc_ice_shield: 'Ice shield',
  rc_flashings: 'Flashings',
  rc_penetrations: 'Penetrations',
  rc_hail: 'Hail damages',
  rc_other_property_wind_damage: 'Property damages',
  rc_wind_damages: 'Wind damages',
  rc_length_shingle: 'Shingle length',
  rc_shingles_color: 'Shingles color',
}

export const roofClaimsMediaKinds = utils.keys(mediaToLabelRoofClaims)

export const checkAllRoofFilled = (customer: schema.Customer): boolean => {
  const isNewerCustomer = customer.createdAt > jan302025
  const roofPitchEmpty = isNewerCustomer && customer.roof_pitch === ''
  if (customer.roof_layover_or_tear === '') {
    return false
  }
  if (customer.roof_layover_or_tear === schema.RoofWorkType.Layover) {
    if (
      customer.roof_square_footage === '' ||
      roofPitchEmpty ||
      customer.roof_layers_how_many === ''
    ) {
      return false
    }
  } else if (
    customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofFlatRoof
  ) {
    if (
      customer.roof_square_footage === '' ||
      roofPitchEmpty ||
      customer.roof_layers_how_many === ''
    ) {
      return false
    }
  } else if (
    customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles
  ) {
    if (
      customer.roof_square_footage === '' ||
      roofPitchEmpty ||
      customer.roof_layers_how_many === '' ||
      customer.roof_tear_material === ''
    ) {
      return false
    }
  } else if (
    customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofWoodShake
  ) {
    if (
      customer.roof_square_footage === '' ||
      roofPitchEmpty ||
      customer.roof_layers_how_many === '' ||
      customer.roof_tear_material === ''
    ) {
      return false
    }
  } else if (customer.roof_layover_or_tear === schema.RoofWorkType.TileRepl) {
    if (customer.roof_square_footage === '' || roofPitchEmpty) {
      return false
    }
  } else if (
    customer.roof_layover_or_tear === schema.RoofWorkType.UnderlaymentRepl
  ) {
    if (customer.roof_square_footage === '' || roofPitchEmpty) {
      return false
    }
  } else {
    console.error('Roof work type un-accounted for in checkAllRoofFilled()')
  }
  return true
}

export type Guideline = utils.r<{
  type: 'error' | 'info' | 'warning'
  text: string
}>

const roofFillAllFieldsGuideline: Guideline = {
  text: 'Fill all fields',
  type: 'warning',
}

export const getFieldGuideline = (
  customer: schema.Customer,
  field: schema.CustomerField,
): Guideline | null => {
  const installer = customer?.main_panel_upgrade_installation_company
  const noInstaller = installer === 'To Be Decided' || !installer

  if (field === 'air_conditioner_new_tons') {
    if (
      customer.air_conditioner_working === 'yes' &&
      customer.air_conditioner_unit_type.startsWith('split_') &&
      customer.air_conditioner_furnance === 'no'
    ) {
      if (!customer.air_conditioner_current_tons) {
        return {
          text: 'Select current AC tons',
          type: 'warning',
        }
      }
      return {
        text: 'Match preferred if no furnance',
        type: 'info',
      }
    }

    if (customer.attic_square_footage) {
      return {
        type: 'info',
        text: `Recommended tons = ${Math.round(
          utils.parseFloating(customer.attic_square_footage) / 400,
        )}`,
      }
    } else {
      return {
        type: 'warning',
        text: 'Enter attic square footage to get recommended tons',
      }
    }
  }
  if (
    field === 'roof_layers_how_many' &&
    customer.roof_layover_or_tear === 'Layover' &&
    utils.parseInt(customer.roof_layers_how_many) > 2
  ) {
    return {
      type: 'warning',
      text: 'For more than 2 layers you need to do a shingles re roof',
    }
  }
  if (field === 'roof_pitch') {
    if (customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofFlatRoof) {
      if (utils.parseInt(customer.roof_pitch) > 1) {
        return {
          text: 'Not a flat roof?',
          type: 'error',
        }
      }
      return null
    }
    return {
      text: 'Will incur a fee if higher than 7',
      type: 'info',
    }
  }
  if (field === 'battery_size') {
    if (noInstaller) {
      return {
        text: 'Installer not selected',
        type: 'warning',
      }
    }
    if (customer?.main_panel_upgrade_installation_company === 'EcoManagement') {
      return {
        type: 'info',
        text: 'Battery size should be bigger than the system. Promo only applies to utility companies: SCE/PG&E/SDG&E',
      }
    }
    return {
      type: 'info',
      text: 'Battery size should be bigger than the system',
    }
  }
  if (field === 'battery_cost') {
    const batteryTypeSelected = !!customer.battery_type

    if (batteryTypeSelected && noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }
  if (field === 'battery_type') {
    if (noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }
  if (field === 'derate_cost') {
    if (noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }
  if (field === 'main_panel_upgrade_cost') {
    if (noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }
  if (field === 'main_panel_upgrade_installation_company') {
    if (!customer.products.includes('Solar')) {
      return { text: 'Turn on Solar in Customer Info', type: 'warning' }
    }
  }
  if (field === 'main_panel_upgrade_needed_or_requested') {
    if (noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }
  if (field === 'roof_cost') {
    if (!checkAllRoofFilled(customer)) {
      return roofFillAllFieldsGuideline
    }
  }
  if (field === 'roof_commission_add_up') {
    if (utils.parseFloating(customer.roof_commission_add_up) > 3000) {
      return { text: 'Must be 3000 or lower', type: 'error' }
    }
  }
  if (field === 'small_system_cost') {
    if (noInstaller) {
      return { text: 'Installer not selected', type: 'warning' }
    }
  }

  return null
}

export const disableInputs: FieldToTrait<boolean> = {
  cash_cost: true,
  createdAt: true,
  date: true,
  sort_key: true,
}

export const shouldDisableInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => {
  if (
    field === 'main_panel_upgrade_needed_or_requested' &&
    !customer.main_panel_upgrade_installation_company
  ) {
    return true
  }
  if (field === 'air_conditioner_new_tons') {
    return (
      customer.air_conditioner_working === 'yes' &&
      customer.air_conditioner_unit_type.startsWith('split_') &&
      customer.air_conditioner_furnance === 'no'
    )
  }
  if (field === 'cash_cost' || field === 'cash_customer_cost') {
    return true
  }
  if (field === 'main_panel_upgrade_installation_company') {
    return !customer.products.includes('Solar')
  }

  if (field.endsWith('notes') && !field.endsWith('extra_notes')) {
    const efficiency = fieldToEfficiency(field)
    return !!efficiencyToCalculator[efficiency!]
  }
  return Boolean(disableInputs[field])
}

export const shouldEnableInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => !shouldDisableInput(field, customer)

export const parseCashbackOptions = (options: string): utils.Opts => {
  try {
    const _options = JSON.parse(options) as unknown as utils.Opts

    return _options
  } catch (e) {
    return []
  }
}

export const getTotalCashback = (options: utils.Opts, customer: Customer) => {
  let total = 0

  if (options.length) {
    options.forEach((key) => {
      if (customer[key.value as keyof Customer]) {
        total += utils.parseFloating(
          customer[key.value as keyof Customer] as string,
        )
      }
    })
  }
  return total
}

export const fieldToEfficiency = (
  field: schema.CustomerField,
): schema.CustomerField | undefined => {
  if (field.endsWith('_cost')) {
    return field.replace('_cost', '') as schema.CustomerField
  }
  if (field.endsWith('_customer_cost')) {
    return field.replace('_customer_cost', '') as schema.CustomerField
  }
  if (field.endsWith('_notes')) {
    return field.replace('_notes', '') as schema.CustomerField
  }
  if (field.endsWith('_extra_notes')) {
    return field.replace('_extra_notes', '') as schema.CustomerField
  }
  const [efficiencyField] =
    utils
      .entries(efficiencyToInputs)
      .find(([_, subFields]) =>
        [subFields].flat(1).some((v) => v?.includes(field)),
      ) || []

  return efficiencyField
}

export const isSubField = (field: keyof Customer) => {
  let isSubfield = false
  efficiencyKeys.forEach((key) => {
    if (field?.startsWith(key) && field !== key && !field.endsWith('notes')) {
      isSubfield = true
    }
  })

  return isSubfield
}

export const fieldCanBeEmpty = (field: schema.CustomerField) => {
  if (isSubField(field)) {
    return true
  }

  // TODO: Later to be handled by the new schema validators
  return ['customerPhoneAlt', 'ssn'].includes(field)
}

export const fieldShouldBeFilled = (field: schema.CustomerField): boolean =>
  !fieldCanBeEmpty(field)

export const isLargeEfficiency = (field: keyof Customer) => {
  if (
    field === 'air_conditioner' ||
    field === 'attic_insulation' ||
    field === 'new_windows' ||
    field === 'roof'
  ) {
    return true
  }

  return false
}

//#region MPUCalculator

const MAX_PANELS = 42

interface Inverter {
  readonly capacity: number
  readonly name: string
  readonly numOfPanels: readonly [lowerBound: number, upperBound: number]
}

const availableInverters: readonly Inverter[] = [
  {
    capacity: 1.21,
    name: 'Enphase IQ8+ Microinverters.',
    numOfPanels: [1, 1],
  },
  {
    capacity: 2.42,
    name: '2 x Enphase IQ8+ Microinverters.',
    numOfPanels: [2, 2],
  },
  {
    capacity: 3.63,
    name: '3 x Enphase IQ8+ Microinverters.',
    numOfPanels: [3, 3],
  },
  {
    capacity: 4.84,
    name: '4 x Enphase IQ8+ Microinverters.',
    numOfPanels: [4, 4],
  },
  {
    capacity: 6.05,
    name: '5 x Enphase IQ8+ Microinverters.',
    numOfPanels: [5, 5],
  },
  {
    capacity: 7.26,
    name: '6 x Enphase IQ8+ Microinverters.',
    numOfPanels: [6, 6],
  },
  {
    capacity: 8.47,
    name: '7 x Enphase IQ8+ Microinverters.',
    numOfPanels: [7, 7],
  },
  {
    capacity: 16,
    name: 'Solaredge Inv-3800',
    numOfPanels: [8, 12],
  },
  {
    capacity: 21,
    name: 'Solaredge Inv-5000',
    numOfPanels: [13, 16],
  },
  {
    capacity: 25,
    name: 'Solaredge Inv-6000',
    numOfPanels: [17, 20],
  },
  {
    capacity: 32,
    name: 'Solaredge Inv-7600',
    numOfPanels: [21, 25],
  },
  {
    capacity: 40,
    name: 'Solaredge Inv-10000',
    numOfPanels: [26, 33],
  },
  {
    capacity: 47.5,
    name: 'Solaredge Inv-11400',
    numOfPanels: [34, 38],
  },
  {
    capacity: 50,
    name: 'Solaredge Inv-6000 + Solaredge Inv-6000',
    numOfPanels: [39, 40],
  },
  {
    capacity: 53,
    name: 'Solaredge Inv-7600 + Solaredge Inv 5000',
    numOfPanels: [41, 42],
  },
]

const reverseInverters = availableInverters.slice().reverse()

interface MPU {
  readonly capacity: number
  readonly name: string
}

const availableMPUs: readonly MPU[] = [
  {
    capacity: 225,
    name: '225amp MPU',
  },
]

const [maxCapInverter] = (availableMPUs as [Inverter])
  .slice()
  .sort((a, b) => a.capacity - b.capacity)

export const { capacity: MAX_MPU_RATING } = maxCapInverter!

export interface MPUCalculatorResult {
  readonly maxPanelsAsIs: number | null
  readonly maxPanelsWithDerate: number | null
  readonly inverterAsIs: Inverter | null
  readonly inverterWithDerate: Inverter | null
}

const VALID_DERATES = [100, 175, 200]

export const mpuCalculator = (
  busRating: number,
  mainBreakerRating: number,
): MPUCalculatorResult => {
  const res: Writable<MPUCalculatorResult> = {
    maxPanelsAsIs: null,
    maxPanelsWithDerate: null,
    inverterAsIs: null,
    inverterWithDerate: null,
  }

  // Information!G11 = numOfPanels

  // Information!F15 = chosenInverter according to numOfPanels
  // Information!K15 = chosenInverter.capacity * 1.25

  for (const inverter of reverseInverters) {
    const {
      capacity,
      numOfPanels: [, upperBound],
    } = inverter
    /**
     * IF (B2*1.2)>B3+(Information!C48*1.25):
     *   CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A48),
     * else:
     *   IF (B2*1.2)>B3+(Information!C46*1.25):
     *      CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A46)
     *   else:
     *     IF (B2*1.2)>B3+(Information!C44*1.25):
     *       CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A44)
     *     else:
     *       IF (B2*1.2)>B3+(Information!C39*1.25):
     *         CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A39)
     *       else:
     *         IF (B2*1.2)>B3+(Information!C31*1.25):
     *           CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A31)
     *         else:
     *           IF (B2*1.2)>B3+(Information!C26*1.25):
     *             CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A26)
     *           else:
     *             IF (B2*1.2)>B3+(Information!C21*1.25):
     *               CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A21)
     *             else:
     *               IF (B2*1.2)>B3+(Information!C18*1.25):
     *                 CONCATENATE("Maximum number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A18),"No possible to install Solar Panels without the need of Derate or MPU"))))))))
     */
    if (busRating * 1.2 > mainBreakerRating + capacity * 1.25) {
      res.maxPanelsAsIs = upperBound
      res.inverterAsIs = inverter
      break
    }
  }

  if (!res.maxPanelsAsIs || !res.inverterAsIs) {
    return res
  }

  for (const numOfPanels of range(
    MAX_PANELS - res.maxPanelsAsIs,
    res.maxPanelsAsIs + 1,
  )) {
    /**
     * IF (B2*1.2)>B3+Information!K15:
     *   "Derate is not need"
     * else IF (B2*1.2)>B3-25+Information!K15:
     *   IF B3-25=200:
     *     CONCATENATE("Derate size should be: ",B3-25)
     *   else IF B3-25=175:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else IF B3-25=100:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else:
     *    "Derate is not possible"
     * else:
     *   "Derate is not possible"
     */
    const inverterViaPanels = availableInverters.find(
      ({ numOfPanels: [lowerBound, upperBound] }) =>
        isBetween({
          lowerBound,
          upperBound,
          value: numOfPanels,
        }),
    )
    if (!inverterViaPanels) {
      return res
    }
    if (
      busRating * 1.2 >
      mainBreakerRating + inverterViaPanels.capacity * 1.25
    ) {
      // Derate not needed
      if (numOfPanels !== res.maxPanelsAsIs) {
        console.error('Assertion failed: numOfPanels === res.maxPanelsAsIs')
      }
      continue
    }

    if (
      busRating * 1.2 >
      mainBreakerRating - 25 + inverterViaPanels.capacity * 1.25
    ) {
      /**
       * IF B3-25=200:
       *     CONCATENATE("Derate size should be: ",B3-25)
       *   else IF B3-25=175:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else IF B3-25=100:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else:
       *    "Derate is not possible"
       */
      if (VALID_DERATES.includes(mainBreakerRating - 25)) {
        res.maxPanelsWithDerate = numOfPanels
        const inverterViaPanels = availableInverters.find(
          ({ numOfPanels: [lowerBound, upperBound] }) =>
            isBetween({
              lowerBound,
              upperBound,
              value: numOfPanels,
            }),
        )
        if (!inverterViaPanels) {
          console.error(
            `Found panels with derate but not Inverter for those panels?`,
          )
          continue
        }
        res.inverterWithDerate = inverterViaPanels
      }
    }
  }

  return res
}

// TODO: Move to react utils
export function useMpuCalculator() {
  const [mpuBusRating, setMpuBusRating] = React.useState('')
  const [mpuMainBreakerRating, setMPUMainBreakerRating] = React.useState('')

  const mpuCalculatorResult = React.useMemo((): MPUCalculatorResult | null => {
    if ([mpuBusRating, mpuBusRating].every(stringIsNumber)) {
      // const parsedNumOfSolarPanels = parseInt(numOfSolarPanels, 10)
      const parsedMPUBusRating = utils.parseFloating(mpuBusRating)
      const parsedMPUMainBreakerRating =
        utils.parseFloating(mpuMainBreakerRating)

      return mpuCalculator(parsedMPUBusRating, parsedMPUMainBreakerRating)
    }

    return null
  }, [mpuBusRating, mpuMainBreakerRating])

  return {
    mpuBusRating,
    mpuCalculatorResult,
    mpuMainBreakerRating,
    setMpuBusRating,
    setMPUMainBreakerRating,
  }
}

//#endregion MPUCalculator

export const validateNumberOfPhotosAllowed = (ofWhat: MediaKind): number => {
  if (ofWhat === 'owner_house') {
    return 1
  }
  return 40
}

export const invoiceEfficiencyOpts: utils.DeepReadonly<
  Record<string, string[] | Record<string, string[]>>
> = {
  artificial_turf: [
    'Evergreen Collection Waterproof Solid Grass 1x1 Indoor/Outdoor Artificial Grass Tile, 1 ft. x 1 ft',
  ],
  attic_insulation: ['Attic Insulation'],
  duct_seal: ['Duct Seal'],
  efficient_boiler: [
    'Rinnai M120SN Condensing Gas Boiler, Natural Gas: Materials+Labor',
    'Aspen Firetube 126,000 BTU Combi High Efficiency Wall Hung Condensing Gas Fired Boiler: Materials+Labor',
  ],
  heat_pump: [
    'Goodman 5 Ton 15.5 SEER Heat Pump GSZC180601, Coil CHPF4860D6, 120,000 BTU 97% AFUE Horizontal Gas furnance GMVM971205DN',
    '56,000 BTU 4.6 Ton 17.5 SEER Ducted Central Split Air Conditioner Heat Pump System',
  ],
  hvac: [
    '2 T HVAC System Installation: Miscellaneous + Labor',
    '2.5 T HVAC System Installation: Miscellaneous + Labor',
    '3 T HVAC System Installation: Miscellaneous + Labor',
    '3.5 T HVAC System Installation: Miscellaneous + Labor',
    '4 T HVAC System Installation: Miscellaneous + Labor',
    '5 T HVAC System Installation: Miscellaneous + Labor',
  ],
  mini_split: [
    '1T HVAC mini split: Miscellaneous+Labor',
    '2T HVAC mini split: Miscellaneous+Labor',
  ],
  radiant_floor: ['WarmlyYours Tempzone Electric Radiant Floor  '],
  roof: {
    roof_layover: [
      'Install 30-year Shingles according to manufactures specifications\nSeal penetrations',
    ],
    shingles_tear_off: [
      'Tear off the existing roof.\nInstall one-layer of synthetic underlayment.\nInstall new 2x2 drep edge metal.\nInstall 30-year Shingles according to manufactures specifications.\nSeal penetrations.\n',
    ],
  },
  thermostat: [
    'Ecobee3 Lite Programmable Smart Thermostat - Energy Star Certified',
    'Nest Learning Thermostat - Smart Wi-Fi Thermostat Stainless Steel',
  ],
  whole_house_fans: [
    'Whole House Fan/Ventilation',
    'QA-Deluxe 6500 Includes Plug & Play Wireless On/Off Remote Control | Energy Efficient Whole House Fan | R-5 Insulated Damper',
  ],
  windows: ['Sliding Windows', 'Sliding Windows: Low-E Energy Efficient.'],
  windows_film: [
    'KESPEN Window Privacy Film One Way Daytime Privacy Static Cling Sun Blocking Anti UV Reflective Window Tint for Home and Office, Black-Silver',
  ],
}

/**
 * At this point this is just the state for the company.
 */
export const companyToLabel: utils.ReadonlyRecord<string> = (() => {
  const res: Record<string, string> = {}

  for (const [subCompanyLabel, subCompanyValue] of utils.zip(
    env.COMPANY_LABELS,
    env.COMPANY_VALUES,
  ) as readonly [string, string][]) {
    res[subCompanyValue] = subCompanyLabel
  }

  return res
})()

export const stateToSolarCompanyName: utils.ReadonlyRecord<string> = {
  california: 'California Power Solutions',
  nevada: 'Nevada Power Solutions',
}

//#region Glow
const nper = 10 // Commitment (years)
const pmt = 0 // Payment amount per period

const calcAvg = (rate: number, value: number): number => {
  const pv =
    (value * (1 - Math.pow(1 + rate, -nper))) / rate -
    (pmt * (1 - Math.pow(1 + rate, -nper))) / rate
  return Number(pv.toFixed(2))
}

const cashFlowDiscount = 0.11 // Cashflow discount
const _powerOutputMW = 0.0156

export const calculateProtocolFee = (
  pricePerWatt: number,
  hoursOfSunPerDay: number,
  escalator: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  const firstYearElectricityOldPrice = Number(
    (pricePerWatt * hoursOfSunPerDay * powerOutputMW * 365.25).toFixed(2),
  )

  const lifetimeOldElectricityValue = calcAvg(
    -escalator,
    firstYearElectricityOldPrice,
  )

  const protocolCashRequirements = calcAvg(
    cashFlowDiscount,
    lifetimeOldElectricityValue / nper,
  )

  return protocolCashRequirements
}

export const calculateInstallationCost = (
  installationCostPerWatt: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  return installationCostPerWatt * powerOutputMW * 1000000
}
//#endregion Glow

/**
 * Windows global color selectors cut-off.
 */
export const Jun4_2024 = 1717519937085
/**
 * Roof pitch cut off
 */
const jan302025 = 1738238982375
export const shouldHideInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => {
  const state = env.getCompanyState(customer.solarCompany)

  const dependency = fieldToDependency[field]
  if (dependency) {
    /* Special case */
    if (field === 'new_windows_replace_custom') {
      return customer.new_windows_replace !== 'no'
    }
    return customer[dependency] !== 'yes'
  }

  if (field === 'air_conditioner_current_tons') {
    return customer.air_conditioner_working !== 'yes'
  }
  if (field === 'air_conditioner_furnance') {
    return (
      customer.air_conditioner_working === 'no' ||
      !customer.air_conditioner_unit_type.startsWith('split_')
    )
  }
  if (field === 'air_conditioner_swamp_cooler_use_remove') {
    return customer.air_conditioner_swamp_cooler_use !== 'yes'
  }
  if (field === 'air_conditioner_replace_ducts') {
    return customer.air_conditioner_working === 'no'
  }
  if (field === 'battery') {
    if (!customer.solarCompany) return true
    return state !== 'california'
  }
  if (field === 'battery_size') {
    return customer.battery_type.toLowerCase() === ''
  }
  if (field === 'glow') {
    const shouldDisplay = env.IS_MAIN || env.CODENAME === 'glow'
    const shouldHide = !shouldDisplay
    return shouldHide
  }
  // Hide for older customers
  if (field === 'new_windows_color') {
    return utils.normalizeTimestampToMs(customer.createdAt) < Jun4_2024
  }
  if (field === 'new_windows_sliding_glass_sqft') {
    return customer.new_windows_sliding_glass !== 'yes'
  }
  if (field === 'roof_fascia_included_square_footage') {
    return customer.roof_fascia_included !== 'yes'
  }
  if (field === 'roof_layers_how_many') {
    const shouldShow =
      customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles ||
      customer.roof_layover_or_tear === schema.RoofWorkType.Layover ||
      customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofFlatRoof ||
      customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofWoodShake

    return !shouldShow
  }
  if (field === 'roof_patio_included') {
    return customer.roof_layover_or_tear === schema.RoofWorkType.ReRoofFlatRoof
  }
  if (field === 'roof_patio_how_many') {
    return customer.roof_patio_included !== 'yes'
  }
  if (field === 'roof_pitch') {
    return customer.createdAt < jan302025
  }
  if (field === 'roof_plywood_replaced') {
    if (customer.roof_layover_or_tear === schema.RoofWorkType.TileRepl) {
      return customer.roof_plywood_under_tile === 'no'
    }
    return customer.roof_layover_or_tear === schema.RoofWorkType.Layover
  }
  if (field === 'roof_plywood_under_tile') {
    return customer.roof_layover_or_tear !== schema.RoofWorkType.TileRepl
  }
  if (field === 'roof_plywood_replaced_square') {
    return customer.roof_plywood_replaced !== 'yes'
  }
  if (field === 'roof_tear_material') {
    return (
      customer.roof_layover_or_tear !== schema.RoofWorkType.ReRoofShingles &&
      customer.roof_layover_or_tear !== schema.RoofWorkType.ReRoofWoodShake
    )
  }
  if (field === 'roof_will_add_license') {
    return state !== 'california'
  }
  if (field === 'roof_commission_add_up') {
    return state !== 'california'
  }

  return false
}

export const shouldDisplayInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => !shouldHideInput(field, customer)

export const fieldToGrow: FieldToTrait<boolean> = {
  air_conditioner_furnance: true,

  customerAddress: true,

  cash_air_conditioner: true,
  cash_artificial_turf: true,
  cash_amount: true,
  cash_irrigation_systems: true,
  cash_heat_pumps: true,
  cash_whole_house_fans_or_ventilation: true,
  cash_efficient_boilers: true,
  cash_geothermal_heat_pumps: true,
  cash_radiant_floors: true,
  cash_water_saving_fixtures: true,
  cash_solar_water_heaters: true,
  cash_on_demand_or_tankless_hot: true,
  cash_windows: true,
  cash_floor_insulation: true,
  cash_efficient_water_heaters: true,
  cash_pool_pumps: true,

  new_windows_color: true,
  new_windows_replace: true,
  new_windows_replace_custom: true,
  new_windows_grid: true,
  new_windows_sliding_glass: true,
  new_windows_sqft_each_window: true,

  custom_fields_name: true,
  custom_two_fields_name: true,
  custom_three_fields_name: true,
  custom_four_fields_name: true,
  custom_five_fields_name: true,
  custom_six_fields_name: true,
  custom_seven_fields_name: true,
  custom_eight_fields_name: true,
  custom_nine_fields_name: true,
  custom_ten_fields_name: true,

  roof_will_add_license: true,
}

export const readableDateFormat = 'MMM Do YYYY'
export const readableDateFormatWTime = 'MMM Do YYYY HH:MM'

export const hasAutoNotes: FieldToTrait<boolean> = {
  air_conditioner: true,
  attic_fan: true,
  attic_insulation: true,
  battery: true,
  derate: true,
  duct_seal: true,
  flat_roof_panels: false,
  main_panel_upgrade: true,
  mini_split: false,
  new_windows: true,
  panel_removal: true,
  roof: true,
  small_system: false,
  smart_thermostat: true,
}

type Calculator = (
  customer: utils.DeepReadonly<schema.Customer>,
  prices: utils.DeepReadonly<schema.SaleTag>,
  state: 'california' | 'nevada',
) => [number, string]

export const efficiencyToCalculator: FieldToTrait<Calculator> = {
  air_conditioner(
    {
      air_conditioner_furnance,
      air_conditioner_new_tons,
      air_conditioner_replace_ducts,
      air_conditioner_swamp_cooler_use,
      air_conditioner_swamp_cooler_use_remove,
      air_conditioner_unit_type,
      air_conditioner_working,
    },
    prices,
    state,
  ) {
    let finalCost = 0
    const notesLines: string[] = []

    const priceList =
      state === 'california'
        ? prices.air_conditioner.california.pricesByCity[0]!.list
        : prices.air_conditioner.nevada.list

    // There's now 4 types of job:
    // 1. Full new installation
    // 2. Full replacement
    // 3. Replacement without ducts
    // 4. AC only replacement
    // We won't account for a AC-only + duct replacement as it will be rare
    const installationTypeNotes = (() => {
      if (air_conditioner_working === 'no') {
        return '(new installation)'
      }
      if (air_conditioner_unit_type.startsWith('roof')) {
        // Doesn't get offered the furnance question
        if (air_conditioner_replace_ducts === 'yes') {
          return '$$__FULL__REPLACEMENT'
        }
        return '(replacement)'
      }
      if (air_conditioner_unit_type.startsWith('split')) {
        if (
          air_conditioner_furnance === 'yes' &&
          air_conditioner_replace_ducts === 'yes'
        ) {
          return '$$__FULL__REPLACEMENT'
        }
        if (air_conditioner_furnance === 'yes') {
          return '(replacement)'
        }
        if (air_conditioner_furnance === 'no') {
          return '(ac only replacement)'
        }
        if (
          air_conditioner_replace_ducts === 'yes' &&
          air_conditioner_furnance === 'no'
        ) {
          // Unhandled scenario, fall back to ac only
          return '(ac only replacement)'
        }
      }

      throw new Error('Should be unreachable?')
    })()

    const [splitOrRoof, gasOrElectric] = air_conditioner_unit_type
      .toLowerCase()
      .split('_')

    if (installationTypeNotes === '(new installation)') {
      notesLines.push('Scope of Work: HVAC System Installation')
      notesLines.push('\n')
      notesLines.push('Project Overview')
      notesLines.push(
        `This project involves the installation of a new ${air_conditioner_new_tons}-ton ${splitOrRoof} ${
          splitOrRoof === 'roof' ? '' : gasOrElectric + ' '
        }HVAC unit, including a furnace and ductwork. The work will ensure the HVAC system operates efficiently and complies with local building codes.`,
      )
      notesLines.push('\n')
      notesLines.push('1. HVAC Unit Installation')
      notesLines.push('- Install a new HVAC unit.')
      notesLines.push('- Connect and integrate the furnace with the HVAC unit.')
      notesLines.push(
        '- Ensure proper electrical connections, grounding, and circuit protection.',
      )
      notesLines.push(
        '- Test the unit for optimal functionality, including heating and cooling performance.',
      )
      notesLines.push('\n')
      notesLines.push('2. Ductwork Installation')
      notesLines.push(
        '- Design and install new duct systems based on load calculations to ensure proper airflow and energy efficiency.',
      )
      notesLines.push(
        '- Use insulated, code-compliant materials for all ducts to enhance thermal performance.',
      )
      notesLines.push('- Seal all connections to prevent air leaks.')
      notesLines.push(
        '- Install and position registers and grilles for even air distribution.',
      )
      notesLines.push('\n')
      notesLines.push('3. Accessories and Components')
      notesLines.push(
        '- Install a new thermostat and ensure proper functionality.',
      )
      notesLines.push(
        '- Integrate air filters and ensure compatibility with the HVAC system.',
      )
      notesLines.push(
        '- Check and secure connections for return air and supply air systems.',
      )
      notesLines.push('\n')
      notesLines.push('4. Testing and Balancing')
      notesLines.push(
        '- Perform system testing and balancing to ensure proper airflow and temperature distribution.',
      )
      notesLines.push(
        '- Verify energy efficiency and compliance with HVAC standards.',
      )
      notesLines.push('\n')
      notesLines.push('5. Cleanup and Final Inspection')
      notesLines.push('- Clean the work area after installation.')
    }
    if (installationTypeNotes === '$$__FULL__REPLACEMENT') {
      notesLines.push('Scope of Work: HVAC System Replacement')
      notesLines.push('\n')
      notesLines.push('Project Overview')
      notesLines.push(
        `This project involves the replacement of a ${air_conditioner_new_tons}-ton ${splitOrRoof} ${
          splitOrRoof === 'roof' ? '' : gasOrElectric + ' '
        }HVAC unit, including a furnace and all existing ductwork. The work will ensure the HVAC system operates efficiently and complies with local building codes.`,
      )
      notesLines.push('\n')
      notesLines.push('1. HVAC Unit Replacement')
      notesLines.push('- Uninstall the existing HVAC unit and furnace.')
      notesLines.push('- Install the new HVAC unit and furnace.')
      notesLines.push('- Connect and integrate the furnace with the HVAC unit.')
      notesLines.push(
        '- Ensure proper electrical connections, grounding, and circuit protection.',
      )
      notesLines.push(
        '- Test the unit for optimal functionality, including heating and cooling performance.',
      )
      notesLines.push('\n')
      notesLines.push('2. Ductwork Replacement')
      notesLines.push('- Remove all existing ductwork.')
      notesLines.push(
        '- Design and install new duct systems based on load calculations to ensure proper airflow and energy efficiency.',
      )
      notesLines.push(
        '- Use insulated, code-compliant materials for all ducts to enhance thermal performance.',
      )
      notesLines.push('Seal all connections to prevent air leaks.')
      notesLines.push(
        '- Install and position registers and grilles for even air distribution.',
      )
      notesLines.push('\n')
      notesLines.push('3. Accessories and Components')
      notesLines.push(
        '- Install a new thermostat and ensure proper functionality.',
      )
      notesLines.push(
        '- Integrate air filters and ensure compatibility with the HVAC system.',
      )
      notesLines.push(
        '- Check and secure connections for return air and supply air systems.',
      )
      notesLines.push('\n')
      notesLines.push('4. Testing and Balancing')
      notesLines.push(
        '- Perform system testing and balancing to ensure proper airflow and temperature distribution.',
      )
      notesLines.push(
        '- Verify energy efficiency and compliance with HVAC standards.',
      )
      notesLines.push('\n')
      notesLines.push('5. Cleanup and Final Inspection')
      notesLines.push(
        '- Remove and dispose of old equipment and ductwork in compliance with environmental regulations.',
      )
      notesLines.push('- Clean the work area after installation.')
    }
    if (installationTypeNotes === '(replacement)') {
      notesLines.push('Scope of Work: HVAC Replacement')
      notesLines.push('\n')
      notesLines.push('Project Overview')
      notesLines.push(
        `This project involves the replacement of a ${air_conditioner_new_tons}-ton ${splitOrRoof} ${
          splitOrRoof === 'roof' ? '' : gasOrElectric + ' '
        }HVAC unit. The work will ensure the HVAC system operates efficiently and complies with local building codes.`,
      )
      notesLines.push('\n')
      notesLines.push('1. HVAC Unit Replacement')
      notesLines.push('- Uninstall the existing HVAC unit')
      notesLines.push('- Install a new HVAC unit.')
      notesLines.push('- Connect and integrate the furnace with the HVAC unit.')
      notesLines.push(
        '- Ensure proper electrical connections, grounding, and circuit protection.',
      )
      notesLines.push(
        '- Test the unit for optimal functionality, including heating and cooling performance.',
      )
      notesLines.push('\n')
      notesLines.push('2. Accessories and Components')
      notesLines.push(
        '- Install a new thermostat and ensure proper functionality.',
      )
      notesLines.push(
        '- Integrate air filters and ensure compatibility with the HVAC system.',
      )
      notesLines.push(
        '- Check and secure connections for return air and supply air systems.',
      )
      notesLines.push('\n')
      notesLines.push('3. Testing and Balancing')
      notesLines.push(
        '- Perform system testing and balancing to ensure proper airflow and temperature distribution.',
      )
      notesLines.push(
        '- Verify energy efficiency and compliance with HVAC standards.',
      )
      notesLines.push('\n')
      notesLines.push('4. Cleanup and Final Inspection')
      notesLines.push(
        '- Remove and dispose of old equipment in compliance with environmental regulations.',
      )
      notesLines.push('- Clean the work area after installation.')
    }
    if (installationTypeNotes === '(ac only replacement)') {
      notesLines.push('Scope of Work: AC Replacement')
      notesLines.push('\n')
      notesLines.push('Project Overview')
      notesLines.push(
        `This project involves the replacement of a ${air_conditioner_new_tons}-ton split ${gasOrElectric} AC unit. The work will ensure the HVAC system operates efficiently and complies with local building codes.`,
      )
      notesLines.push('\n')
      notesLines.push('1. AC Unit Installation')
      notesLines.push('- Uninstall the existing AC unit')
      notesLines.push('- Install a new AC unit.')
      notesLines.push('- Connect and integrate the furnace with the AC unit.')
      notesLines.push(
        '- Ensure proper electrical connections, grounding, and circuit protection.',
      )
      notesLines.push(
        '- Test the unit for optimal functionality, including heating and cooling performance.',
      )
      notesLines.push('\n')
      notesLines.push('2. Accessories and Components')
      notesLines.push(
        '- Install a new thermostat and ensure proper functionality.',
      )
      notesLines.push(
        '- Integrate air filters and ensure compatibility with the AC system.',
      )
      notesLines.push(
        '- Check and secure connections for return air and supply air systems.',
      )
      notesLines.push('\n')
      notesLines.push('3. Testing and Balancing')
      notesLines.push(
        '- Perform system testing and balancing to ensure proper airflow and temperature distribution.',
      )
      notesLines.push(
        '- Verify energy efficiency and compliance with AC standards.',
      )
      notesLines.push('\n')
      notesLines.push('4. Cleanup and Final Inspection')
      notesLines.push(
        '- Remove and dispose of old equipment in compliance with environmental regulations.',
      )
      notesLines.push('- Clean the work area after installation.')
    }

    if (air_conditioner_swamp_cooler_use === 'yes') {
      notesLines.push('\n')

      if (air_conditioner_swamp_cooler_use_remove === 'yes') {
        notesLines.push('Swamp cooler will be removed')

        finalCost += priceList.find(
          (p) => p.label === 'Swamp Cooler Removal',
        )!.value
      } else {
        notesLines.push('Swamp cooler is present')
      }
    }

    const installationTypePrice = (() => {
      // Replacing all AC furnance and ducts is the same as new install
      if (installationTypeNotes === '$$__FULL__REPLACEMENT') {
        return '(new installation)'
      }
      return installationTypeNotes
    })()

    const price: number = priceList.find(({ label: _label }) => {
      const label = _label.toLowerCase()

      return (
        label.includes(air_conditioner_new_tons + 't') &&
        label.includes(splitOrRoof!) &&
        // Roof has no gas or electric
        label.includes(splitOrRoof === 'roof' ? '' : gasOrElectric!) &&
        label.includes(installationTypePrice)
      )
    })!.value

    finalCost += price

    if (
      installationTypePrice === '(ac only replacement)' &&
      air_conditioner_replace_ducts === 'yes'
    ) {
      const ductReplPrice: number = priceList.find(
        ({ label }) => label === 'Duct Replacement (AC only)',
      )!.value

      finalCost += ductReplPrice
    }

    return [finalCost, notesLines.join('\n')]
  },
  attic_fan({ attic_fan_quantity }, prices, state) {
    let finalCost = 0
    const notesLines: string[] = []

    const price = (() => {
      if (state === 'california') {
        return prices.attic_fan.california.pricesByCity[0]!.list[0]!.value
      }
      return prices.attic_fan.nevada.list[0]!.value
    })()

    if (attic_fan_quantity) {
      finalCost += price * utils.parseInteger(attic_fan_quantity)
      notesLines.push(`${attic_fan_quantity} attic fans will be installed`)
    }

    return [finalCost, notesLines.join('\n')]
  },
  attic_insulation(
    { attic_insulation_how_much, attic_insulation_type },
    prices,
    state,
  ) {
    if (!attic_insulation_how_much || !attic_insulation_type) {
      return [0, '']
    }
    let finalCost = 0
    const notesLines: string[] = []
    const priceList =
      state === 'nevada'
        ? prices.attic_insulation.nevada.list
        : prices.attic_insulation.california.pricesByCity[0]!.list
    const price = priceList[0]!.value
    finalCost = price * utils.parseFloating(attic_insulation_how_much)
    //#region notes
    if (attic_insulation_how_much) {
      notesLines.push(`Total sq of insulation: ${attic_insulation_how_much}`)
    }
    if (attic_insulation_type) {
      notesLines.push(`Type of insulation: ${attic_insulation_type}`)
    }
    //#endregion notes
    return [finalCost, notesLines.join('\n')]
  },
  battery(
    { main_panel_upgrade_installation_company, battery_size, battery_type },
    prices,
    state,
  ) {
    let finalCost = 0
    const notesLines: string[] = []

    if (
      main_panel_upgrade_installation_company &&
      battery_size &&
      battery_type
    ) {
      const priceList = (() => {
        if (state === 'california') {
          return prices.battery.california.pricesByCity[0]!.list
        }
        return prices.battery.nevada.list
      })()

      const batteryKey = `Battery ${battery_type}-${main_panel_upgrade_installation_company}-${battery_size}`

      const price = priceList.find((x) => x.label === batteryKey)!.value || 0

      finalCost += price

      notesLines.push(`Installer: ${main_panel_upgrade_installation_company}`)
      notesLines.push(`Battery type: ${battery_type}`)
      notesLines.push(`Battery spec: ${battery_size}`)
    }

    return [finalCost, notesLines.join('\n')]
  },
  derate({ main_panel_upgrade_installation_company }, prices, state) {
    if (!main_panel_upgrade_installation_company) {
      return [0, '']
    }

    const price = (() => {
      if (state === 'california') {
        return prices.derate.california.pricesByCity[0]!.list.find(
          (p) =>
            p.label === `Derate (${main_panel_upgrade_installation_company})`,
        )!.value
      }
      return prices.derate.nevada.list.find(
        (p) =>
          p.label === `Derate (${main_panel_upgrade_installation_company})`,
      )!.value
    })()

    return [
      price,
      `A derate will be performed\nInstaller: ${main_panel_upgrade_installation_company}`,
    ]
  },
  duct_seal(_, prices, state) {
    const price = (() => {
      if (state === 'california') {
        return prices.duct_seal.california.pricesByCity[0]!.list[0]!.value
      }
      return prices.duct_seal.nevada.list[0]!.value
    })()

    return [price, 'Duct seal will be applied.']
  },
  flat_roof_panels({ flat_roof_panels_how_many }, prices, state) {
    let finalCost = 0
    const notesLines: string[] = []
    const price = (() => {
      if (state === 'california') {
        return prices.flat_roof_panels.california.pricesByCity[0]!.list[0]!
          .value
      }
      return prices.flat_roof_panels.nevada.list[0]!.value
    })()

    if (flat_roof_panels_how_many) {
      finalCost += price * utils.parseInteger(flat_roof_panels_how_many)
    }

    return [finalCost, notesLines.join('\n')]
  },
  main_panel_upgrade(
    {
      main_panel_upgrade_installation_company,
      main_panel_upgrade_needed_or_requested,
    },
    prices,
    state,
  ) {
    let finalCost = 0
    const notesLines: string[] = []

    const priceList = (() => {
      if (state === 'california') {
        return prices.main_panel_upgrade.california.pricesByCity[0]!.list
      }
      return prices.main_panel_upgrade.nevada.list
    })()

    const mpuKey = `Main Panel Upgrade-${main_panel_upgrade_installation_company}`

    const price = priceList.find((x) => x.label === mpuKey)!.value

    if (main_panel_upgrade_installation_company) {
      if (main_panel_upgrade_needed_or_requested === 'needed') {
        notesLines.push('An upgrade is needed')
        finalCost += price
      }
      if (main_panel_upgrade_needed_or_requested === 'requested') {
        notesLines.push('An upgrade was requested by the customer')
        finalCost += price
      }
      if (main_panel_upgrade_needed_or_requested === 'relocation') {
        notesLines.push(
          "Relocation requested by customer, a site survey is needed before it's performed",
        )
      }
      notesLines.push(`Installer: ${main_panel_upgrade_installation_company}`)
    }

    return [finalCost, notesLines.join('\n')]
  },
  mini_split({ mini_split_tons }, prices, state) {
    let finalCost = 0
    const notesLines: string[] = []

    const [miniSplitTon1Price, miniSplitTon2Price] = (() => {
      const priceList =
        state === 'california'
          ? prices.mini_split.california.pricesByCity[0]!.list
          : prices.mini_split.nevada.list

      return [
        priceList.find((cp) => cp.label === 'Mini Split System 1Ton')!.value,
        priceList.find((cp) => cp.label === 'Mini Split System 2Ton')!.value,
      ]
    })()

    const miniSplits = JSON.parse(mini_split_tons || '[]') as schema.MiniSplit[]

    for (const split of miniSplits.filter((ms) => ms.qty && ms.tons)) {
      if (
        // @ts-ignore
        split.tons === '1' ||
        // @ts-ignore
        split.tons === 'mini_split_1t' ||
        split.tons === 1
      ) {
        finalCost += utils.parseInteger(split.qty) * miniSplitTon1Price
      }
      if (
        // @ts-ignore
        split.tons === '2' ||
        // @ts-ignore
        split.tons === 'mini_split_2t' ||
        split.tons === 2
      ) {
        finalCost += utils.parseInteger(split.qty) * miniSplitTon2Price
      }
    }

    return [finalCost, notesLines.join('\n')]
  },
  new_windows(
    {
      createdAt,
      new_windows_color,
      new_windows_grid,
      new_windows_sliding_glass,
      new_windows_sliding_glass_sqft,
      new_windows_sqft_each_window,
    },
    prices,
    state,
  ) {
    let finalCost = 0
    const notesLines: string[] = []
    const pricePerSqft = (() => {
      if (state === 'california') {
        return prices.new_windows.california.pricesByCity[0]!.list.find(
          (cp) => cp.label === 'Per Sqft',
        )!.value
      }
      return prices.new_windows.nevada.list.find(
        (cp) => cp.label === 'Per Sqft',
      )!.value
    })()
    // @ts-ignore Special Case
    const priceColors = prices.new_windows.priceColors

    const windowGroups: readonly schema.WindowGroup[] = JSON.parse(
      new_windows_sqft_each_window || '[]',
    )
    if (!utils.isArray(windowGroups)) {
      throw new TypeError('new_windows_sqft_each_window not an array')
    }
    for (const obj of windowGroups) {
      if (!schema.isWindowGroup(obj)) {
        throw new TypeError(`Object inside sqftWindow[] not a WindowGroup`)
      }
    }
    const filledWindowGroups = windowGroups.filter(
      (sw) => !!sw.size && !!sw.window,
    )
    let totalSqft = 0
    let totalWindows = 0
    let amtColor = 0
    for (const windowGroup of filledWindowGroups) {
      const qty = utils.parseInteger(windowGroup.window)
      const size = utils.parseFloating(windowGroup.size)

      totalSqft += qty * size
      totalWindows += qty

      if (createdAt > Jun4_2024) {
        // Newer customer, has a global color selector
        const colorPrice = priceColors[new_windows_color]
        if (colorPrice) amtColor += colorPrice * qty
      } else {
        // Older customer, has an individual color selector for each window
        // group
        const colorPrice = priceColors[windowGroup.color]
        if (colorPrice) amtColor += colorPrice * qty
      }
    }
    finalCost += utils.parseFloating(pricePerSqft) * totalSqft + amtColor
    notesLines.push('Scope of work:')
    notesLines.push('')
    notesLines.push(
      'Remove and replace existing windows with new energy-efficient models. The windows will be properly installed and sealed. Old materials will be disposed of, and the worksite will be cleaned post-installation.',
    )
    notesLines.push('')
    notesLines.push('Type of windows: Low E, doble panel windows')
    notesLines.push('Material: Vinyl')
    if (new_windows_color) {
      notesLines.push(`Color: ${new_windows_color}`)
    }
    if (totalWindows > 0) {
      notesLines.push(`Total number of windows: ${totalWindows}`)
    }
    if (totalSqft > 0) {
      notesLines.push(`Total measure of windows in sqft: ${totalSqft}`)
    }

    const slidingNotes: string[] = []
    if (new_windows_sliding_glass === 'yes') {
      const slidingDoorPrices = (() => {
        if (state === 'california') {
          return {
            'Sliding Doors (5 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (5 ft)',
              )!.value,
            'Sliding Doors (6 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (6 ft)',
              )!.value,
            'Sliding Doors (8 ft)':
              prices.new_windows.california.pricesByCity[0]!.list.find(
                (cp) => cp.label === 'Sliding Doors (8 ft)',
              )!.value,
          }
        }
        return {
          'Sliding Doors (5 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (5 ft)',
          )!.value,
          'Sliding Doors (6 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (6 ft)',
          )!.value,
          'Sliding Doors (8 ft)': prices.new_windows.nevada.list.find(
            (cp) => cp.label === 'Sliding Doors (8 ft)',
          )!.value,
        }
      })()
      const slidingDoors = JSON.parse(
        new_windows_sliding_glass_sqft || '[]',
      ) as schema.SlidingGlassDoorGroup[]
      const filledSlidingDoors = slidingDoors.filter(
        (sd) => !!sd.size && !!sd.total,
      )
      for (const slidingDoor of filledSlidingDoors) {
        const price =
          slidingDoorPrices[slidingDoor.size as keyof typeof slidingDoorPrices]
        finalCost += price * utils.parseInteger(slidingDoor.total)
      }

      const totalSliding = filledSlidingDoors.reduce(
        (count, g) => count + utils.parseInt(g.total),
        0,
      )
      if (totalSliding) {
        notesLines.push(`Total number of sliding glass doors: ${totalSliding}`)
      }

      slidingNotes.push(
        ...filledSlidingDoors.map(
          (g) =>
            `${g.total} Sliding glass ${
              g.total > 1 ? 'doors' : 'door'
            } - Size of the sliding ${g.total > 1 ? 'doors' : 'door'} ${
              schema.slidingToSize[g.size as keyof typeof schema.slidingToSize]
            } ft`,
        ),
      )
    }

    notesLines.push(`Grids included: ${new_windows_grid}`)

    notesLines.push('Windows Details')

    notesLines.push(
      ...filledWindowGroups.map(
        (g) =>
          `${g.window} ${g.window > 1 ? 'windows' : 'window'} - Size ${
            g.size
          } sqft${
            createdAt < Jun4_2024 && g.color
              ? ` - Color ${g.color.toLowerCase()}`
              : ''
          }`,
      ),
    )

    notesLines.push(...slidingNotes)

    return [finalCost, notesLines.join('\n')]
  },
  panel_removal({ panel_removal_how_many }, prices, state) {
    let finalCost = 0
    const notesLines: string[] = []

    const price = (() => {
      if (state === 'california') {
        return prices.panel_removal.california.pricesByCity[0]!.list[0]!.value
      }
      return prices.panel_removal.nevada.list[0]!.value
    })()

    if (panel_removal_how_many) {
      finalCost += price * utils.parseInteger(panel_removal_how_many)
      notesLines.push(`${panel_removal_how_many} panels will be removed`)
    }

    return [finalCost, notesLines.join('\n')]
  },
  roof(customer, prices, state) {
    if (!checkAllRoofFilled(customer)) {
      return [0, '']
    }
    const {
      roof_fascia_included_square_footage,
      roof_fascia_included,
      roof_layers_how_many,
      roof_layover_or_tear,
      roof_tear_material,
      roof_patio_how_many,
      roof_patio_included,
      roof_pitch,
      roof_plywood_replaced_square,
      roof_plywood_replaced,
      roof_plywood_under_tile,
      roof_square_footage,
      roof_will_add_license,
      roof_commission_add_up,
    } = customer

    const priceList = (() => {
      if (state === 'california') {
        return prices.roof.california.pricesByCity[0]!.list
      }
      return prices.roof.nevada.list
    })()
    let finalCost = 0
    const notesLines: string[] = []

    if (roof_square_footage) {
      notesLines.push(`Roof measurement: ${roof_square_footage}`)
    }
    if (roof_layover_or_tear) {
      notesLines.push(
        `Type of work: ${
          roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles
            ? 'Re Roof (Shingles)'
            : roof_layover_or_tear
        }`,
      )
    }
    if (roof_pitch) {
      notesLines.push(`Roof pitch: ${roof_pitch}`)
    }
    if (roof_tear_material) {
      notesLines.push(`Material duration: ${roof_tear_material} years`)
    }

    if (roof_square_footage && roof_layover_or_tear) {
      const layoverPrice = (() => {
        if (state === 'california') {
          return priceList.find(
            (cp) => cp.label === schema.RoofWorkType.Layover,
          )!.value
        }
        return priceList.find((cp) => cp.label === schema.RoofWorkType.Layover)!
          .value
      })()
      const reRoofShinglesPrice = (() => {
        const price20y = priceList.find(
          (cp) => cp.label === 'Re Roof (Shingles) (20 years)',
        )!.value
        const price30y = priceList.find(
          (cp) => cp.label === 'Re Roof (Shingles) (30 years)',
        )!.value
        const price50y = priceList.find(
          (cp) => cp.label === 'Re Roof (Shingles) (50 years)',
        )!.value

        return (
          {
            20: price20y,
            30: price30y,
            50: price50y,
          }[roof_tear_material] || 0
        )
      })()
      const tilePrice = priceList.find(
        (cp) => cp.label === 'Tile (per sq)',
      )!.value
      const underlaymentReplPrice = priceList.find(
        (cp) => cp.label === 'Underlayment Replacement (per sq)',
      )!.value
      const reRoofFlatPrice = priceList.find(
        (cp) => cp.label === 'Re Roof (Flat roof) per sq',
      )!.value
      const reRoofWoodShakePrice = (() => {
        // const price20y = priceList.find(
        //   (cp) => cp.label === 'Re Roof (Woodshake) (20 years)',
        // )!.value
        const price30y = priceList.find(
          (cp) => cp.label === 'Re Roof (Woodshake) per sq (30 years)',
        )!.value
        // const price50y = priceList.find(
        //   (cp) => cp.label === 'Re Roof (Woodshake) (50 years)',
        // )!.value

        return (
          {
            // 20: price20y,
            30: price30y,
            // 50: price50y,
          }[roof_tear_material] || 0
        )
      })()

      const pricePerSq =
        {
          [schema.RoofWorkType.Layover]: layoverPrice,
          [schema.RoofWorkType.ReRoofShingles]: reRoofShinglesPrice,
          [schema.RoofWorkType.TileRepl]: tilePrice,
          [schema.RoofWorkType.UnderlaymentRepl]: underlaymentReplPrice,
          [schema.RoofWorkType.ReRoofFlatRoof]: reRoofFlatPrice,
          [schema.RoofWorkType.ReRoofWoodShake]: reRoofWoodShakePrice,
        }[roof_layover_or_tear] || -1

      const roofSquares = utils.parseFloating(roof_square_footage)

      finalCost += pricePerSq * roofSquares

      if (utils.isNumber(roof_pitch) && Number(roof_pitch) >= 8) {
        const price = priceList.find(
          ({ label }) => label === 'High pitch roof fee (per sq)',
        )!.value

        finalCost += roofSquares * price
      }

      if (roof_layers_how_many) {
        if (roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles) {
          const additionalLayersPrice = priceList.find(
            (cp) => cp.label === 'Tear-off cost per additional layer',
          )!.value

          notesLines.push(
            `Number of current shingle layers: ${roof_layers_how_many}`,
          )
          const additionalLayers = utils.parseInteger(roof_layers_how_many) - 1
          if (additionalLayers > 0) {
            finalCost += additionalLayersPrice * additionalLayers * roofSquares
            notesLines.push(
              `Number of additional layers (Tear-off): ${additionalLayers}`,
            )
          }
        } else if (roof_layover_or_tear === schema.RoofWorkType.Layover) {
          notesLines.push(
            `Number of layers to be installed: ${roof_layers_how_many}`,
          )
        } else {
          notesLines.push(`Current roof layers: ${roof_layers_how_many}`)
        }
      }
    }

    if (
      roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles ||
      (roof_layover_or_tear === schema.RoofWorkType.TileRepl &&
        roof_plywood_under_tile === 'yes') ||
      roof_layover_or_tear === schema.RoofWorkType.UnderlaymentRepl ||
      roof_layover_or_tear === schema.RoofWorkType.ReRoofFlatRoof ||
      roof_layover_or_tear === schema.RoofWorkType.ReRoofWoodShake
    ) {
      if (roof_plywood_replaced === 'yes' && roof_plywood_replaced_square) {
        const roofPlywoodPrice = (() => {
          if (state === 'california') {
            return prices.roof.california.pricesByCity[0]!.list.find(
              (cp) => cp.label === 'Plywood replacement per sq',
            )!.value
          }
          return prices.roof.nevada.list.find(
            (cp) => cp.label === 'Plywood replacement per sq',
          )!.value
        })()

        finalCost +=
          roofPlywoodPrice * utils.parseFloating(roof_plywood_replaced_square)
        notesLines.push(
          `${roof_plywood_replaced_square} sq of plywood will be replaced`,
        )
      } else {
        notesLines.push('Plywood will be replaced as needed')
      }
    }

    if (roof_fascia_included === 'yes' && roof_fascia_included_square_footage) {
      const roofFasciaPrice = (() => {
        if (state === 'california') {
          return prices.roof.california.pricesByCity[0]!.list.find(
            (cp) => cp.label === 'Roof Fascia per linear ft',
          )!.value
        }
        return prices.roof.nevada.list.find(
          (cp) => cp.label === 'Roof Fascia per linear ft',
        )!.value
      })()

      finalCost +=
        roofFasciaPrice *
        utils.parseFloating(roof_fascia_included_square_footage)

      notesLines.push(
        `Fascia included with ${roof_fascia_included_square_footage} sq`,
      )
    }

    if (roof_patio_included === 'yes' && roof_patio_how_many) {
      const roofPatioPrice = (() => {
        if (state === 'california') {
          return prices.roof.california.pricesByCity[0]!.list.find(
            (cp) => cp.label === 'Flat roof per sq',
          )!.value
        }
        return prices.roof.nevada.list.find(
          (cp) => cp.label === 'Flat roof per sq',
        )!.value
      })()

      finalCost += roofPatioPrice * utils.parseFloating(roof_patio_how_many)
      notesLines.push(`Patio included with ${roof_patio_how_many} sq`)
    }

    if (roof_layover_or_tear === schema.RoofWorkType.Layover) {
      notesLines.push('Scope of work:')
      notesLines.push(
        '- Inspecting the existing roof to confirm suitability for a layover.',
      )
      notesLines.push(
        '- Cleaning and preparing the surface of the existing shingles.',
      )
      notesLines.push(
        '- Furnishing and installing a new layer of architectural shingles (Owens Corning Oakridge series or similar product, depending on availability) with all associated flashings and trims.',
      )
      notesLines.push(
        '- Installing new drip edge metal and all new underlayment per local codes.',
      )
      notesLines.push(
        '- All job-related debris will be cleaned up and hauled away.',
      )
      notesLines.push('- Permits are included.')
    }
    if (roof_layover_or_tear === schema.RoofWorkType.ReRoofShingles) {
      notesLines.push('Scope of work:')
      notesLines.push(
        '- Removing the layers of existing roof down to the roof sheathing. (1 layer)',
      )
      notesLines.push(
        '- Inspecting the roof sheathing for damage, if damaged wood is discovered we will replace it.',
      )
      notesLines.push('- All debris will be cleaned up and hauled away.')
      notesLines.push(
        '- We will furnish and install new drip edge metal and all new underlayment in accordance with local codes.',
      )
      notesLines.push(
        '- We propose to furnish and install a new layer of architectural shingles (Owens Corning Oakridge series or similar product, depending on availability) with all associated flashings and trims.',
      )
      notesLines.push('-Permits are included.')
    }
    if (roof_layover_or_tear === schema.RoofWorkType.TileRepl) {
      notesLines.push('Scope of work:')
      notesLines.push(
        '- Preparation: Secure the site, protect landscaping, and set up debris disposal.',
      )
      notesLines.push(
        '- Tile Removal: Remove old tiles, dispose of debris, and clean roof surfaces.',
      )
      notesLines.push(
        '- Underlayment Replacement: Replace old underlayment with new material.',
      )
      notesLines.push(
        '- Plywood Inspection/Repair: Inspect plywood; replace damaged areas.',
      )
      notesLines.push(
        '- New Tile Installation: Install and align new tiles; seal edges and flashing.',
      )
      notesLines.push('- Cleanup: Remove debris and ensure the site is clean.')
      notesLines.push(
        '- Final Inspection: Conduct a final inspection to ensure the roof is properly sealed and secure.) this will appear as soon as we set the license question.',
      )
    }
    if (roof_layover_or_tear === schema.RoofWorkType.UnderlaymentRepl) {
      notesLines.push('Scope of work:')
      notesLines.push(
        '- Site Preparation: Set up safety measures and clear the work area.',
      )
      notesLines.push(
        '- Tile Removal: Carefully remove existing tiles, ensuring no damage.',
      )
      notesLines.push(
        '- Roof Surface Assessment: Inspect and prepare the roof deck, addressing any damage.',
      )
      notesLines.push(
        '- Underlayment Replacement: Install new underlayment with proper overlap and secure attachment.',
      )
      notesLines.push(
        '- Tile Reinstallation: Reinstall the removed tiles, replacing any broken ones with new tiles.',
      )
      notesLines.push('- Cleanup: Remove debris and ensure the site is clean.')
      notesLines.push(
        '- Final Inspection: Conduct a final inspection to ensure the roof is properly sealed and secure.) this will appear as soon as we set the license question.',
      )
    }

    if (roof_will_add_license === 'no') {
      finalCost -= 1000
    }
    if (roof_commission_add_up) {
      finalCost += utils.parseFloating(roof_commission_add_up)
    }

    return [finalCost, notesLines.join('\n')]
  },
  small_system({ main_panel_upgrade_installation_company }, prices, state) {
    const priceList = (() => {
      if (state === 'california') {
        return prices.small_system.california.pricesByCity[0]!.list
      }
      return prices.small_system.nevada.list
    })()

    const price = priceList.find(({ label }) => {
      if (main_panel_upgrade_installation_company) {
        return label.includes(main_panel_upgrade_installation_company)
      } else {
        return label === 'Small System'
      }
    })

    return [price!.value, '']
  },
  smart_thermostat({ smart_thermostat_how_many }, prices, state) {
    let finalCost = 0
    const notesLines: string[] = []

    const price = (() => {
      if (state === 'california') {
        return prices.smart_thermostat.california.pricesByCity[0]!.list[0]!
          .value
      }
      return prices.smart_thermostat.nevada.list[0]!.value
    })()

    if (smart_thermostat_how_many) {
      finalCost += price * utils.parseInteger(smart_thermostat_how_many)
      notesLines.push(
        `${smart_thermostat_how_many} smart thermostats will be installed`,
      )
    }

    return [finalCost, notesLines.join('\n')]
  },
}

export const addUpWindows = (customer: Customer): number | string => {
  try {
    const windows = JSON.parse(
      customer.new_windows_sqft_each_window || '[]',
    ) as schema.WindowGroup[]

    return windows
      .filter((w) => !!w.size)
      .reduce((acc, curr) => acc + utils.parseInt(curr.window), 0)
  } catch (e) {
    const msg = `Error adding up windows: ${utils.processErr(e)}`
    utils.log(e)
    return msg
  }
}

export const addUpSlidingDoors = (customer: Customer): number | string => {
  try {
    if (customer.new_windows_sliding_glass === 'no') {
      return 0
    }
    const slidingDoors = JSON.parse(
      (customer.new_windows_sliding_glass_sqft as string) || '[]',
    ) as schema.SlidingGlassDoorGroup[]

    return slidingDoors
      .filter((s) => !!s.size)
      .reduce((acc, curr) => acc + utils.parseInt(curr.total), 0)
  } catch (e) {
    const msg = `Error adding up sliding doors: ${utils.processErr(e)}`
    utils.log(e)
    return msg
  }
}

export type TagToEfficiencies = utils.DictB<
  utils.RRecord<schema.CustomerField, true>
>
