export type ColorScheme = 'dark' | 'light'
export const fieldWidthWeb = 240

//#region colors
export const white = '#FFF'
export const black = '#000'
export const nearWhite = '#EEE'
export const midGrey = '#888'
export const middleGrey = '#BBB'
export const middleDarkGrey = '#777'

// Used for making the header and other elements look just like native iOS
// const appleBackdrop = '#FFF'
// const appleBackdropDark = '#000'
const appleCanvas = '#F5F5F5'
const appleCanvasBorder = '#E9E9E9' // TODO: double-check
const appleCanvasDark = '#131313'
const appleCanvasBorderDark = '#131313'
// const applePaper = '#1C1C1C'
// const applePaperBorder = '#1C1C1C'
// const applePaperOpaqueDark = '#404040'
// const applePaperOpaqueColorDark = '#FFF'
// const applePaperSeparator = '#252525'
// const appleSeparator = '#CBCBCB'
// const appleSeparatorDark = '#2D2D2D' // TODO: find exact value
const appleBlue = '#0071EB'
const appleRed = '#EB0C00'
const appleYellowFill = '#FFEC7F'
// const appleYellowGlyph = '#F7CE46'

// UPS Theme
const upsGrey = '#EEEEF1'
const upsBorder = '#E5E4EC'
// const upsBGDark = '#2B2B2B'
// const upsBGSecDark = '#2E2A27'
export const upsLeaf = '#ACF163'
// const upsBrandDark = '#2D5007'
const upsBorderRad = 16
const upsPurple = '#7C3BEC'
const upsDarkGrey = '#2E2A27'
const upsFontFamily = 'DM Sans'

// Glow colors from website

const glowSand = '#FAF7EB'
const glowLeaf = '#63D370'
const glowSun = '#EFA068'
const glowTreeBark = '#210102'
// Secondary button border and font
export const glowLightHover = '#EAE6DB'
// const glowPlatinum = '#EBE9E8'
export const glowStoneGrey = '#CBCAC9'
// const glowGravelBrown = '#534F4B'
const glowWetSand = '#CFC6BC'
export const glowSeparatorDark = 'turquoise'
const glowBorderRad = 32

// const glowText = glowDark
// const glowTextDark = glowLight

export const glowThird = '#EBE9E8'

// const glowSubContentLight = '#CBCAC9'
// const glowThird = '#EBE9E8'

//#endregion colors
//#region types
export const SurfaceTypes = ['backdrop', 'canvas', 'paper'] as const
export type Surface = typeof SurfaceTypes[number]

export type OpaqueColorValue = symbol & { __TYPE__: 'Color' }
export type ColorValue = string | OpaqueColorValue

export type Gap = 2 | 4 | 8 | 12 | 16 | 24 | 28 | 32

export interface Layer {
  readonly backgroundColor?: ColorValue
  readonly borderColor?: ColorValue
  readonly borderRadius?: number
  readonly borderTopColor?: ColorValue
  readonly borderTopWidth?: number
  readonly borderWidth?: number
  readonly color?: ColorValue
  readonly disabled?: string
  readonly faint?: string
  readonly faint__color?: string
  // readonly focus?: Layer
  readonly fontFamily?: string
  readonly fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  readonly fontSize?: number
  readonly gap?: Gap
  readonly highlight?: string
  readonly highlight__color?: string
  // readonly hover?: Layer
  readonly hoverBC?: string
  readonly hoverBG?: string
  readonly opaque?: string
  readonly opaque__color?: string
  readonly paddingHorizontal?: number | string
  readonly paddingVertical?: number | string
  readonly placeholderColor?: string
  readonly separator?: string
}

export type BGAwareLayer = Readonly<Record<Surface, Layer>>

export interface Theme {
  /** To be used sparingly. */
  readonly accent: string
  readonly backdrop: Layer
  readonly borderRadius?: number
  readonly button: BGAwareLayer
  readonly input: BGAwareLayer
  /** The color that most represents the company. */
  readonly brand: string
  /** Danger. */
  readonly danger: string
  /** Main content holder. */
  readonly canvas: Layer
  /** Font family for everything. */
  readonly fontFamily?: string
  /** Sub content container. */
  readonly paper: Layer
  /** Links that open an external target or new window. */
  readonly href: string
  /** Complementary color. */
  readonly third: string
  /** Warning. */
  readonly warning: string
}

interface ThemeTuple {
  readonly dark: Theme
  readonly light: Theme
}
//#endregion types
//#region default
const defaultLayerDark: Layer = {
  backgroundColor: upsDarkGrey,
  borderColor: upsDarkGrey,
  borderRadius: 0,
  borderWidth: 0,
  color: white,
  disabled: middleDarkGrey,
  fontWeight: 'normal',
  highlight: appleBlue,
  highlight__color: white,
  opaque: middleGrey,
  opaque__color: black,
  separator: middleGrey,
}

const defaultThemeDark: Theme = {
  accent: appleBlue,
  backdrop: {
    backgroundColor: appleCanvasDark,
  },
  brand: appleCanvas,
  button: {
    backdrop: { ...defaultLayerDark, borderColor: appleCanvasDark },
    canvas: { ...defaultLayerDark, borderColor: appleCanvasDark },
    paper: { ...defaultLayerDark, borderColor: appleCanvasDark },
  },
  canvas: defaultLayerDark,
  danger: appleRed,
  href: appleBlue,
  input: {
    backdrop: {},
    canvas: {
      ...defaultLayerDark,
      backgroundColor: appleCanvasDark,
      borderColor: appleCanvasDark,
    },
    paper: {
      ...defaultLayerDark,
      backgroundColor: appleCanvasDark,
      borderColor: appleCanvasDark,
    },
  },
  paper: defaultLayerDark,
  third: appleYellowFill,
  warning: appleYellowFill,
}

const defaultInputLight: Layer = {
  backgroundColor: upsGrey,
  borderColor: upsGrey,
  borderRadius: upsBorderRad,
  borderWidth: 1,
  color: black,
}

const defaultLayerLight: Layer = {
  backgroundColor: white,
  borderColor: white,
  borderRadius: 0,
  borderWidth: 1,
  color: black,
  disabled: middleDarkGrey,
  faint: nearWhite,
  fontWeight: 'normal',
  highlight: appleBlue,
  highlight__color: white,
  opaque: middleGrey,
  opaque__color: black,
  placeholderColor: nearWhite,
  separator: middleGrey,
}

const defaultPaperLight: Layer = {
  ...defaultLayerLight,
  backgroundColor: upsGrey,
  borderColor: upsBorder,
}

const defaultThemeLight: Theme = {
  accent: appleBlue,
  backdrop: defaultLayerLight,
  brand: appleCanvasBorder,
  button: {
    backdrop: {},
    canvas: {},
    paper: defaultPaperLight,
  },
  canvas: defaultLayerLight,
  danger: appleRed,
  href: appleBlue,
  input: {
    backdrop: {},
    canvas: defaultInputLight,
    paper: { ...defaultInputLight, backgroundColor: white },
  },
  paper: defaultPaperLight,
  third: appleYellowFill,
  warning: appleYellowFill,
}
export const defaultThemeTuple: ThemeTuple = {
  dark: defaultThemeDark,
  light: defaultThemeLight,
}
//#endregion default
//#region glow
const glowPaperLight: Layer = {
  backgroundColor: glowSand,
  borderColor: glowSand,
  borderRadius: glowBorderRad,
  borderWidth: 1,
  color: glowTreeBark,
  fontFamily: 'Manrope',
  highlight: glowTreeBark,
  highlight__color: white,
  hoverBG: glowWetSand,
  placeholderColor: nearWhite,
  separator: glowWetSand,
  opaque: upsDarkGrey,
  opaque__color: middleGrey,
}
const glowPaperDark: Layer = {
  color: glowSand,
}
const glowDefaultInputLight: Layer = {
  ...glowPaperLight,
  backgroundColor: white,
  borderColor: white,
  color: glowTreeBark,
  paddingHorizontal: 12,
  paddingVertical: 18,
}

const glowBtnBase: Layer = {
  borderRadius: Number.MAX_SAFE_INTEGER,
  borderWidth: 1,
  fontFamily: 'Manrope',
}
//#endregion glow
//#region ups
const upsBase: Layer = {
  borderWidth: 1,
  borderRadius: upsBorderRad,
  fontFamily: upsFontFamily,
  gap: 12,
  highlight: appleBlue,
  highlight__color: white,
}

const upsPaper: Layer = { ...upsBase, gap: 16 }

const upsPaperDark: Layer = {
  ...upsPaper,
  backgroundColor: upsDarkGrey,
  borderColor: appleCanvasDark,
  borderWidth: 2,
  color: white,
  faint: middleDarkGrey,
  separator: appleCanvasDark,
  opaque: appleCanvasDark,
  opaque__color: white,
}

const upsPaperLight: Layer = {
  ...upsPaper,
  backgroundColor: upsGrey,
  borderColor: upsBorder,
  borderWidth: 2,
  color: black,
  faint: nearWhite,
  separator: upsBorder,
  opaque: upsBorder,
  opaque__color: black,
}

const upsInput: Layer = {
  borderRadius: upsBorderRad,
  borderWidth: 1,
  faint: middleGrey,
  fontFamily: upsFontFamily,
  highlight: appleBlue,
  highlight__color: white,
  paddingHorizontal: 12,
  paddingVertical: 18,
  // focus: { borderColor: appleBlue },
}

const upsBlackBtn: Layer = {
  ...upsInput,
  backgroundColor: '#000',
  borderColor: '#000',
  color: white,
  hoverBG: '#333',
}

const upsInputDark: Layer = {
  ...upsInput,
  backgroundColor: appleCanvasDark,
  borderColor: appleCanvasDark,
  color: white,
  faint: middleDarkGrey,
  placeholderColor: middleDarkGrey,
}
//#endregion ups
export const themeTuples: Readonly<Record<string, ThemeTuple>> = {
  glow: {
    dark: {
      accent: glowLeaf,
      brand: glowSand,
      backdrop: { backgroundColor: white },
      button: {
        backdrop: {
          ...glowBtnBase,
        },
        canvas: {
          ...glowBtnBase,
        },
        paper: {
          ...glowBtnBase,
          ...glowPaperLight,
        },
      },
      canvas: glowPaperDark,
      danger: appleRed,
      fontFamily: 'Manrope',
      href: glowSand,
      input: {
        backdrop: {},
        canvas: {
          ...glowPaperLight,
          backgroundColor: white,
          color: black,
          paddingHorizontal: 18,
          paddingVertical: 12,
        },
        paper: {
          ...glowPaperLight,
          backgroundColor: white,
          color: black,
          paddingHorizontal: 18,
          paddingVertical: 12,
        },
      },
      paper: glowPaperDark,
      third: '#9CC',
      warning: appleYellowFill,
    },
    light: {
      accent: glowSun,
      backdrop: { backgroundColor: white, separator: glowWetSand },
      borderRadius: glowBorderRad,
      brand: glowSand,
      button: {
        backdrop: { ...glowBtnBase },
        canvas: { ...glowBtnBase, color: glowTreeBark },
        paper: {
          ...glowBtnBase,
          backgroundColor: glowSand,
          borderColor: glowTreeBark,
          borderRadius: Number.MAX_SAFE_INTEGER,
          borderWidth: 1,
          color: glowTreeBark,
          highlight: appleBlue,
          hoverBG: glowWetSand,
        },
      },
      canvas: {
        borderRadius: glowBorderRad,
        backgroundColor: white,
        separator: glowTreeBark,
      },
      danger: appleRed,
      fontFamily: 'Manrope',
      href: appleBlue,
      input: {
        backdrop: {},
        canvas: {
          ...glowDefaultInputLight,
          backgroundColor: glowSand,
        },
        paper: glowDefaultInputLight,
      },
      paper: glowPaperLight,
      third: '#C91',
      warning: appleYellowFill,
    },
  },
  upsmain: {
    dark: {
      ...upsBase,
      accent: glowSun,
      backdrop: {
        ...upsBase,
        backgroundColor: appleCanvasDark,
        borderColor: appleCanvasDark,
        color: white,
        highlight: appleBlue,
        highlight__color: white,
        separator: appleCanvasBorderDark,
      },
      borderRadius: upsBorderRad,
      brand: upsLeaf,
      button: {
        backdrop: upsBlackBtn,
        canvas: upsBlackBtn,
        paper: {
          ...upsInput,
          color: white,
        },
      },
      canvas: {
        ...upsBase,
        backgroundColor: upsDarkGrey,
        borderColor: appleCanvasDark,
        borderRadius: upsBorderRad,
        borderWidth: 1,
        color: white,
        faint: middleDarkGrey,
        highlight: appleBlue,
        opaque: middleDarkGrey,
        separator: appleCanvasBorderDark,
      },
      danger: appleRed,
      href: appleBlue,
      input: {
        backdrop: upsInputDark,
        canvas: upsInputDark,
        paper: upsInputDark,
      },
      paper: upsPaperDark,
      third: upsPurple,
      warning: '#440',
    },
    light: {
      ...upsBase,
      accent: glowSun,
      backdrop: {
        ...upsBase,
        backgroundColor: appleCanvas,
        borderColor: appleCanvas,
        color: black,
        highlight: appleBlue,
        highlight__color: white,
        separator: appleCanvasBorder,
      },
      brand: upsLeaf,
      button: {
        backdrop: upsBlackBtn,
        canvas: upsBlackBtn,
        paper: {
          ...upsInput,
          borderColor: upsBorder,
          borderRadius: upsBorderRad,
          borderWidth: 1,
          hoverBG: upsPaperLight.faint,
        },
      },
      canvas: {
        ...upsBase,
        backgroundColor: white,
        borderColor: white,
        color: black,
        borderRadius: upsBorderRad,
        faint: nearWhite,
        highlight: appleBlue,
        opaque: middleGrey,
        separator: nearWhite,
      },
      danger: appleRed,
      href: appleBlue,
      input: {
        backdrop: { ...upsInput, backgroundColor: black },
        canvas: {
          ...upsInput,
          backgroundColor: upsGrey,
          borderColor: upsGrey,
          color: black,
          placeholderColor: middleGrey,
        },
        paper: {
          ...upsInput,
          backgroundColor: white,
          borderColor: white,
          color: black,
          placeholderColor: middleGrey,
        },
      },
      paper: upsPaperLight,
      third: upsPurple,
      warning: '#CC0',
    },
  },
}

//#endregion appColors
