import React from 'react'
import * as rn from 'react-native'

import Feather from 'react-native-vector-icons/Feather'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as cp from '../components'
import * as gs from '../gStyles'

import Text from './Text'

export type NavMenuProps = c.r<{
  availNonCustomEfficiencies: c.CustomerFields
  canHide?: boolean
  customerID: string
  hide?: boolean
  onPressAssessment(): void
  onPressAuditor(): void
  onPressCommissionCalc(): void
  onPressDocumentation(): void
  onPressEfficiency(field: c.CustomerField): void
  onPressRoofClaims(): void
  onPressSolarSurvey(): void
}>

export default React.memo<NavMenuProps>(function NavMenu({
  availNonCustomEfficiencies,
  canHide,
  customerID,
  hide,
  onPressAssessment,
  onPressAuditor,
  onPressCommissionCalc,
  onPressDocumentation,
  onPressEfficiency,
  onPressRoofClaims,
  onPressSolarSurvey,
}) {
  const t = r.useTheme()
  const gStyles = gs.useGlobalStyles('backdrop')
  const styles = gs.useThemedStyleSheet(themedStyles)

  //#region global
  const { currProducts, toggleHIL, toggleRC, toggleSolar } =
    r.useProducts(customerID)
  //#endregion global
  //#region local
  const handleCustomPress = React.useCallback((): void => {
    onPressEfficiency('custom_fields')
  }, [onPressEfficiency])
  //#endregion local

  const checkActive = (
    <Feather color={t.backdrop.highlight} name="check-square" size={16} />
  )
  const checkInactive = (
    <Feather color={t.backdrop.color as string} name="square" size={16} />
  )

  return (
    <rn.View
      accessibilityLabel="nav-menu"
      style={(() => {
        if (canHide) {
          return hide ? styles.containerHidden : styles.container
        }
        return styles.container
      })()}
    >
      <cp.Text size={10}>Shortcuts</cp.Text>
      <rn.TouchableOpacity onPress={onPressAuditor} style={styles.item}>
        <Text style={styles.itemText}>Auditor Report</Text>
      </rn.TouchableOpacity>
      <rn.TouchableOpacity onPress={onPressCommissionCalc} style={styles.item}>
        <Text style={styles.itemText}>Commission Calculator</Text>
      </rn.TouchableOpacity>

      <rn.View style={gStyles.separatorH} />
      {gs.rowGap12}
      <cp.Text size={10}>Multimedia</cp.Text>
      <rn.TouchableOpacity onPress={onPressAssessment} style={styles.item}>
        <Text style={styles.itemText}>Assessment</Text>
      </rn.TouchableOpacity>
      {currProducts.includes('Solar') && (
        <rn.TouchableOpacity onPress={onPressSolarSurvey} style={styles.item}>
          <Text style={styles.itemText}>Site Survey</Text>
        </rn.TouchableOpacity>
      )}
      <rn.TouchableOpacity onPress={onPressDocumentation} style={styles.item}>
        <Text style={styles.itemText}>Documentation</Text>
      </rn.TouchableOpacity>
      {currProducts.includes('RC') && (
        <rn.TouchableOpacity onPress={onPressRoofClaims} style={styles.item}>
          <Text style={styles.itemText}>Roof Claims</Text>
        </rn.TouchableOpacity>
      )}

      <rn.View style={gStyles.separatorH} />
      {gs.rowGap12}
      <cp.Text size={10}>Products</cp.Text>
      <rn.TouchableOpacity onPress={toggleSolar} style={styles.item}>
        {currProducts.includes('Solar') ? checkActive : checkInactive}
        {gs.colGap8}
        <Text style={styles.itemText}>Solar</Text>
      </rn.TouchableOpacity>
      <rn.TouchableOpacity onPress={toggleHIL} style={styles.item}>
        {currProducts.includes('HIL') ? checkActive : checkInactive}
        {gs.colGap8}
        <Text style={styles.itemText}>HIL</Text>
      </rn.TouchableOpacity>
      <rn.TouchableOpacity onPress={toggleRC} style={styles.item}>
        {currProducts.includes('RC') ? checkActive : checkInactive}
        {gs.colGap8}
        <Text style={styles.itemText}>Roof Claims</Text>
      </rn.TouchableOpacity>

      <rn.View style={gStyles.separatorH} />
      {gs.rowGap12}
      <cp.Text size={10}>Efficiencies</cp.Text>
      <rn.TouchableOpacity onPress={handleCustomPress} style={styles.item}>
        <Text style={styles.itemText}>Custom Efficiencies</Text>
      </rn.TouchableOpacity>
      <rn.View style={gStyles.separatorH} />
      {availNonCustomEfficiencies.map((field, i, a) => (
        <NavMenuItem
          field={field}
          key={field}
          onPress={onPressEfficiency}
          renderSeparator={i !== a.length - 1}
        />
      ))}
    </rn.View>
  )
})

type NavMenuItemProps = c.r<{
  field: c.CustomerField
  onPress(field: c.CustomerField): void
  renderSeparator: boolean
}>

const NavMenuItem = React.memo<NavMenuItemProps>(function NavMenuItem({
  field,
  onPress,
  renderSeparator,
}) {
  const gStyles = gs.useGlobalStyles('backdrop')
  const styles = gs.useThemedStyleSheet(themedStyles)
  const handlePress = React.useCallback(() => {
    onPress(field)
  }, [field, onPress])

  return (
    <React.Fragment>
      <rn.TouchableOpacity onPress={handlePress} style={styles.item}>
        <Text style={styles.itemText}>{c.getFieldLabel(field)}</Text>
      </rn.TouchableOpacity>

      {renderSeparator && <rn.View style={gStyles.separatorH} />}
    </React.Fragment>
  )
})

const touchSurfaceWidth = 24
const margin = 12

const themedStyles = gs.ThemedStyleSheet.create((t) => {
  const container = {
    backgroundColor: t.backdrop.backgroundColor,
    borderRadius: t.backdrop.borderRadius,
    bottom: margin,
    // @ts-ignore 'fixed' not available on mobile
    position: (r.isWeb ? 'fixed' : 'absolute') as 'absolute',
    right: Number(t.canvas.gap) * 2,
    /* Breadcrumb height + margin */
    top: 28 + margin,
    // @ts-ignore
    transition: 'right ease-in-out 0.2s',
    maxHeight: '100%', //`${r.isSafari() ? 96 : 100 / 0.78}vh`,
    overflow: 'scroll',
    padding: t.backdrop.gap || 0,
    ...gs.shadowFullSoft,
  } as const

  return {
    container,
    containerHidden: { ...container, right: -232, overflow: 'hidden' },
    dot: {
      backgroundColor: t.backdrop.highlight,
      borderRadius: Number.MAX_SAFE_INTEGER,
      height: 12,
      width: 12,
    },
    item: {
      ...gs.rowCentered,
      padding: t.backdrop.gap,
    },
    itemText: {
      color: t.backdrop.color,
      fontFamily: t.backdrop.fontFamily,
      fontSize: 14,
    },
    separator: {
      backgroundColor: t.backdrop.separator,
      height: 1,
      width: '80%',
    },
    touchSurface: {
      ...gs.deadCenter,
      position: 'absolute',
      right: 0,
      height: '100%',
      width: touchSurfaceWidth,
    },
  }
})
