import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'

import * as gs from '../gStyles'

import Text from './Text'

export type LabelProps = c.r<{
  alignRight?: boolean
  children: number | string | (number | string)[]
  danger?: boolean
  disabled?: boolean
  iconRight?: React.ReactElement | false
  on: c.Surface
  onPress?: c.VoidFn | false
  showAsterisk?: boolean
  small?: boolean
}>

export default React.memo<LabelProps>(function Label({
  alignRight,
  children,
  danger,
  disabled,
  iconRight,
  on,
  onPress,
  showAsterisk,
  small,
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const Wrapper = onPress && !disabled ? rn.TouchableOpacity : rn.View

  return (
    // prettier-ignore
    // @ts-expect-error
    <Wrapper 
      onPress={!disabled && onPress}
      style={alignRight ? styles.containerAlignRight:gs.rowSpaceBetween100}
    >
      <Text
        disableAndroidExtraSpace
        ellipsizeMode="tail"
        style={(() => {
          if (small && disabled) {
            return alignRight
            ? styles.labelDisabledAlignRightSmall
            : styles.labelDisabledSmall
          }
          if (disabled) {
            return alignRight
              ? styles.labelDisabledAlignRight
              : styles.labelDisabled
          }
          if (small && danger) {
            return alignRight
              ? styles.labelDangerAlignRightSmall
              : styles.labelDangerSmall
          }
          if (danger) {
            return alignRight
              ? styles.labelDangerAlignRight
              : styles.labelDanger
          }
          if (small) {
            return alignRight ? styles.labelAlignRightSmall : styles.labelSmall
          }
          return alignRight ? styles.labelAlignRight : styles.label
        })()}
      >
        {rn.Platform.OS === 'android'? children+ ' ' : children} {showAsterisk && <Text disableAndroidExtraSpace style={styles.required}>{'* '}</Text>}
      </Text>
      {iconRight}
    </Wrapper>
  )
})

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const small = { fontSize: 15 }

  const label: rn.TextStyle = {
    color: t[on].color,
    flexShrink: 1,
    fontFamily: t[on].fontFamily,
    fontSize: 18,
    // fontWeight: '500',
    letterSpacing: 0.8,
    ...gs.userSelectNone,
  } as const
  const labelAlignRight = { ...label, ...gs.txtAlignRight }
  const danger = { color: t.danger }
  const labelDanger = { ...label, ...danger }
  const labelDangerAlignRight = { ...label, ...danger, ...gs.txtAlignRight }
  const labelDisabled = { ...label, ...gs.disabled }
  const labelDisabledAlignRight = {
    ...label,
    ...gs.disabled,
    ...gs.txtAlignRight,
  }

  return {
    containerAlignRight: {
      ...gs.rowSpaceBetween100,
      justifyContent: 'flex-end',
    },
    label,
    labelSmall: { ...label, ...small },
    labelAlignRight,
    labelAlignRightSmall: { ...labelAlignRight, ...small },
    labelDanger,
    labelDangerSmall: { ...labelDanger, ...small },
    labelDangerAlignRight,
    labelDangerAlignRightSmall: { ...labelDangerAlignRight, ...small },
    labelDisabled,
    labelDisabledSmall: { ...labelDisabled, ...small },
    labelDisabledAlignRight,
    labelDisabledAlignRightSmall: { ...labelDisabledAlignRight, ...small },
    required: { color: t.third, fontFamily: t[on].fontFamily },
  }
})
