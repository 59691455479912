import algoliaSearch from 'algoliasearch'

import * as env from '../env'
import * as schema from '../schema'
import * as utils from '../utils'
import {
  Bit,
  Customer,
  CustomerSchema,
  MediaItem,
  NoYes,
  YesNo,
  YesNoEmpty,
  boolean,
  number,
  sanitizeBit,
  sanitizeBoolean,
  sanitizeNoYes,
  sanitizeNumber,
  sanitizeString,
  sanitizeYesNo,
  sanitizeYesNoEmpty,
  string,
} from '../schema'
import {
  ValueOf,
  entries,
  log,
  mapValues,
  normalizeTimestampToMs,
  processErr,
} from '../utils'

export type GetUserID = () => string | null

let userIDGetter = null as GetUserID | null

export const setUserIDGetter = (newUserIDGetter: GetUserID) => {
  userIDGetter = newUserIDGetter
}

export const getUserID: GetUserID = () => {
  if (userIDGetter) {
    return userIDGetter()
  }
  throw new ReferenceError(
    `Called getUserID() without setting user id getter first.`,
  )
}

// #region storage

export interface FireTask {
  on(
    event: 'state_changed',
    cb: (snapshot: {
      error?: Error
      state: 'success' | 'cancelled' | 'error' | 'paused' | 'running'
    }) => void,
  ): void
}

export interface StorageRef {
  putFile(blobOrPath: Blob | string): FireTask
}

export interface Storage {
  ref(key: string): StorageRef
}

let storage = null as Storage | null

export const setStorage = (st: Storage) => {
  storage = st
}

export const getStorage = (): Storage => {
  if (!storage) {
    throw new ReferenceError(
      `Tried to access storage without it being provided first.`,
    )
  }
  return storage
}

// #endregion storage

export type FireEventType =
  | 'child_added'
  | 'child_changed'
  | 'child_moved'
  | 'child_removed'
  | 'value'

export interface FireEvent<T> {
  type: FireEventType
  item: T
  key: string
  prevKey: string | null | undefined
}

export interface FireSnapshot {
  hasChildren(): boolean
  key: string | null
  val(): unknown
}

type FirebaseCb = (data: FireSnapshot, previousChildKey?: string | null) => void

type FBErrCb = (e: Error) => void

export interface DatabaseQueryRef {
  limitToFirst(howMany: number): DatabaseQueryRef
  limitToLast(howMany: number): DatabaseQueryRef
  off(): void
  on(event: FireEventType, cb: FirebaseCb, onErr?: FBErrCb): void
  once(event: 'value'): Promise<FireSnapshot>
  orderByChild(key: string): DatabaseQueryRef
}

export interface DatabaseRef extends DatabaseQueryRef {
  child(key: string): DatabaseRef
  remove(): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(value: any, onComplete?: (error: Error | null) => void): Promise<void>
  update(data: Record<string, unknown>): void
}

export interface Database {
  ref(key: string): DatabaseRef
}

let db = null as unknown as Database

export const setDatabase = (database: Database) => {
  db = database
}

export const getDatabase = (): Database => {
  if (db === null) {
    throw new ReferenceError(
      `Called getDatabase() without calling setDatabase() first.`,
    )
  }
  return db
}

export const searchClient = algoliaSearch(
  env.REACT_APP_ALGOLIA_APP_ID,
  env.REACT_APP_ALGOLIA_API_KEY,
)

export const searchIndex = searchClient.initIndex('prod_ecohome')

export const _updateCustomer = (
  customerID: string,
  data: Partial<Customer>,
  opts?: {
    skipModifyDate?: boolean | undefined | null
  },
): void => {
  for (const [field, value] of entries(data)) {
    const validator = CustomerSchema[field] as unknown as (
      value: unknown,
    ) => boolean

    const isValid = validator(value)

    if (!isValid) {
      throw new TypeError(
        `updateCustomer() -> invalid value provided for ${field}: ${JSON.stringify(
          value,
          null,
          2,
        )}`,
      )
    }
  }

  const now = normalizeTimestampToMs(Date.now())

  data = mapValues(data as Record<string, unknown>, (val, key) =>
    typeof val === 'string' && !utils.isNoteField(key as keyof Customer)
      ? val.trim()
      : val,
  ) as Partial<Customer>

  if (data.has_touched_cost) {
    data = {
      ...data,
      // @ts-ignore
      has_touched_cost: JSON.stringify(data.has_touched_cost),
    }
  }
  if (data.has_touched_customer_cost) {
    data = {
      ...data,
      // @ts-ignore
      has_touched_customer_cost: JSON.stringify(data.has_touched_customer_cost),
    }
  }

  const partialUpdate: schema.PartialCustomer = {}

  if (data.customerAddress) {
    partialUpdate.customerAddress = data.customerAddress
  }
  if (data.customerName) {
    partialUpdate.customerName = data.customerName
  }
  if (data.sort_key && opts?.skipModifyDate !== true) {
    partialUpdate.sort_key = data.sort_key
  }
  if (data.deleted) {
    partialUpdate.deleted = data.deleted
  }
  if (data.solarCompany) {
    partialUpdate.solarCompany = data.solarCompany
  }
  if (data.solarRep) {
    partialUpdate.solarRep = data.solarRep
  }
  if (data.crm_id) {
    partialUpdate.crm_id = data.crm_id
  }

  searchIndex.partialUpdateObject({
    objectID: customerID,
    ...partialUpdate,
  })

  db.ref('Customers')
    .child(customerID)
    .update(
      opts?.skipModifyDate
        ? data
        : {
            ...data,
            sort_key: -now,
          },
    )
}

export const deleteCustomer = (customerID: string) => {
  searchIndex.deleteObject(customerID)
  _updateCustomer(customerID, {
    deleted: true,
  })
}

export const subToCustomer = (
  customerID: string,
  onValue: (field: keyof Customer, value: ValueOf<Customer>) => void,
) => {
  const ref = db.ref('Customers').child(customerID)

  ref.on('child_added', (data) => {
    try {
      const { key } = data

      if (!key) {
        throw new ReferenceError(`No key found in data snapshot.`)
      }

      const field = key as keyof Customer

      // CASTS: I dunno why
      if (CustomerSchema[field] === Bit) {
        onValue(field, sanitizeBit(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === boolean) {
        onValue(field, sanitizeBoolean(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === number) {
        onValue(field, sanitizeNumber(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === string) {
        onValue(field, sanitizeString(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === YesNo) {
        onValue(field, sanitizeYesNo(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === NoYes) {
        onValue(field, sanitizeNoYes(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === YesNoEmpty) {
        onValue(field, sanitizeYesNoEmpty(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === schema.StrToPrimitive) {
        onValue(
          field,
          schema.sanitizeStrToPrimitive(data.val()) as ValueOf<Customer>,
        )
      }
    } catch (e) {
      const errMessage = processErr(e)
      log(`subToCustomer(${customerID}) -> child_added -> ${errMessage}`)
      log(e)
    }
  })

  ref.on('child_changed', (data) => {
    try {
      const { key } = data

      if (!key) {
        throw new ReferenceError(`No key found in data snapshot.`)
      }

      const field = key as keyof Customer

      // CASTS: I dunno why
      if (CustomerSchema[field] === Bit) {
        onValue(field, sanitizeBit(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === boolean) {
        onValue(field, sanitizeBoolean(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === number) {
        onValue(field, sanitizeNumber(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === string) {
        onValue(field, sanitizeString(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === YesNo) {
        onValue(field, sanitizeYesNo(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === NoYes) {
        onValue(field, sanitizeNoYes(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === YesNoEmpty) {
        onValue(field, sanitizeYesNoEmpty(data.val()) as ValueOf<Customer>)
      } else if (CustomerSchema[field] === schema.StrToPrimitive) {
        onValue(
          field,
          schema.sanitizeStrToPrimitive(data.val()) as ValueOf<Customer>,
        )
      }
    } catch (e) {
      const errMessage = processErr(e)
      log(`subToCustomer(${customerID}) -> child_changed -> ${errMessage}`)
      log(e)
    }
  })

  ref.on('child_removed', (data) => {
    try {
      const { key } = data

      if (!key) {
        throw new ReferenceError(`No key found in data snapshot.`)
      }

      const field = key as keyof Customer

      if (CustomerSchema[field] === Bit) {
        onValue(field, 0)
      } else if (CustomerSchema[field] === boolean) {
        onValue(field, false)
      } else if (CustomerSchema[field] === number) {
        onValue(field, 0)
      } else if (CustomerSchema[field] === string) {
        onValue(field, '')
      } else if (CustomerSchema[field] === YesNo) {
        onValue(field, 'no')
      } else if (CustomerSchema[field] === NoYes) {
        onValue(field, 'yes')
      } else if (CustomerSchema[field] === YesNoEmpty) {
        onValue(field, '')
      } else if (CustomerSchema[field] === schema.StrToPrimitive) {
        onValue(field, utils.EMPTY_OBJ)
      }
    } catch (e) {
      const errMessage = processErr(e)
      log(`subToCustomer(${customerID}) -> child_removed -> ${errMessage}`)
      log(e)
    }
  })

  // TODO: handle customer deletion

  return () => {
    ref.off()
  }
}

export const subToCustomerField = <K extends keyof Customer>(
  key: string,
  field: K,
  onValue: (value: Customer[K]) => void,
): (() => void) => {
  const ref = db.ref('Customers').child(key).child(field)

  ref.on('value', (data) => {
    // CASTS: I dunno why
    if (CustomerSchema[field] === Bit) {
      onValue(sanitizeBit(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === boolean) {
      onValue(sanitizeBoolean(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === number) {
      onValue(sanitizeNumber(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === string) {
      onValue(sanitizeString(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === YesNo) {
      onValue(sanitizeYesNo(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === NoYes) {
      onValue(sanitizeNoYes(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === YesNoEmpty) {
      onValue(sanitizeYesNoEmpty(data.val()) as Customer[K])
    } else if (CustomerSchema[field] === schema.StrToPrimitive) {
      onValue(schema.sanitizeStrToPrimitive(data.val()) as Customer[K])
    }
  })

  return () => {
    ref.off()
  }
}

export const updateMediaItem = (
  customerID: string,
  mediaItemID: string,
  data: Partial<MediaItem>,
) => {
  const picRef = db.ref(`NewPics/${customerID}/${mediaItemID}`)
  const vidRef = db.ref(`Videos/${customerID}/${mediaItemID}`)

  log(
    `Updating media item ${mediaItemID} with data: ${JSON.stringify(
      data,
      null,
      4,
    )}`,
  )

  picRef.once('value').then(function (snapshot) {
    if (snapshot.hasChildren()) {
      picRef.update(data)
    }
  })
  vidRef.once('value').then(function (snapshot) {
    if (snapshot.hasChildren()) {
      vidRef.update(data)
    }
  })
}

/**
 * Removes the database reference to the media, but leaves the media in storage
 * just in case.
 */
export const deleteMediaItem = (
  customerID: string,
  mediaItemID: string,
): void => {
  const picRef = db.ref(`NewPics/${customerID}/${mediaItemID}`)
  const vidRef = db.ref(`Videos/${customerID}/${mediaItemID}`)

  log(`Deleting media item ${mediaItemID}`)

  picRef.once('value').then(function (snapshot) {
    if (snapshot.hasChildren()) {
      picRef.remove()
    }
  })
  vidRef.once('value').then(function (snapshot) {
    if (snapshot.hasChildren()) {
      vidRef.remove()
    }
  })
}
