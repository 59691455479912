import React from 'react'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { Link } from 'react-router-dom'

import Pad from '../Pad'

interface ActionsProps {
  customerID: string
  onCustomerDeletion: (customerID: string) => void
}

export default React.memo<ActionsProps>(function Actions({
  customerID,
  onCustomerDeletion,
}) {
  const deleteCustomer = React.useCallback(() => {
    onCustomerDeletion(customerID)
  }, [customerID, onCustomerDeletion])

  return (
    <Mui.TableCell align="center">
      <Link style={link} to={customerID}>
        <Mui.Button
          endIcon={<MuiIcons.Edit />}
          sx={sx['editButton']}
          variant="contained"
        >
          Edit
        </Mui.Button>
        <Mui.Button sx={sx['editButtonSmall']} variant="contained">
          <MuiIcons.Edit />
        </Mui.Button>
      </Link>
      <Pad amt={5} />
      <Mui.Button
        color="error"
        endIcon={<MuiIcons.Delete />}
        onClick={deleteCustomer}
        sx={sx['deleteButton']}
        variant="contained"
      >
        Delete
      </Mui.Button>

      <Mui.Button
        color="error"
        onClick={deleteCustomer}
        sx={sx['deleteButtonSmall']}
        variant="contained"
      >
        <MuiIcons.Delete />
      </Mui.Button>
    </Mui.TableCell>
  )
})

const sx = {
  editButton: {
    alignItems: 'center',
    display: {
      lg: 'flex',
      md: 'none',
    },
    justifyContent: 'center',
    width: 113,
  },

  editButtonSmall: {
    alignItems: 'center',
    display: {
      lg: 'none',
      md: 'flex',
    },
    justifyContent: 'center',
  },

  deleteButton: {
    alignItems: 'center',
    display: {
      lg: 'flex',
      md: 'none',
    },
    justifyContent: 'center',
    width: 113,
  },

  deleteButtonSmall: {
    alignItems: 'center',
    display: {
      lg: 'none',
      md: 'flex',
    },
    justifyContent: 'center',
  },
}

const link: React.CSSProperties = {
  textDecoration: 'none',
}
