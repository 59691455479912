import RoofClaimsFrontHouse from '../RoofClaims/Roof-Claims-Front-House.jpg'
import RoofClaimsAddressVerification from '../RoofClaims/Roof-Claims-Address-Verification.jpg'
import RoofClaims3DView01 from '../RoofClaims/Roof-Claims-3D-View-01.jpg'
import RoofClaims3DView02 from '../RoofClaims/Roof-Claims-3D-View-02.jpg'
import RoofClaims3DView03 from '../RoofClaims/Roof-Claims-3D-View-03.jpg'
import RoofClaims3DView04 from '../RoofClaims/Roof-Claims-3D-View-04.jpg'
import RoofClaims3DView05 from '../RoofClaims/Roof-Claims-3D-View-05.jpg'
import RoofClaimsWiresHome from '../RoofClaims/Roof-Claims-Wires-Home.jpg'
import RoofClaimsSiding from '../RoofClaims/Roof-Claims-Siding.jpg'
import RoofClaimsOtherProperty from '../RoofClaims/Roof-Claims-Other-Property.jpg'
import RoofClaimsLayers from '../RoofClaims/Roof-Claims-Layers.jpg'
import RoofClaimsInclinationAngle from '../RoofClaims/Roof-Claims-Inclination-Angle.jpg'
import RoofClaimsOverview from '../RoofClaims/Roof-Claims-Overview.jpg'
import RoofClaimsGutters from '../RoofClaims/Roof-Claims-Gutters.jpg'
import RoofClaimsFlashings from '../RoofClaims/Roof-Claims-Chimney-Flashings.jpg'
import RoofClaimsPenetrations from '../RoofClaims/Roof-Claims-Penetrations.jpg'
import RoofClaimsHail from '../RoofClaims/Roof-Claims-Hail.jpg'
import RoofClaimsLengthShingle from '../RoofClaims/Roof-Claims-Length-Shingle.jpg'
import RoofClaimsDripEdge from '../RoofClaims/Roof-Claims-Drip-Edge.jpg'
import RoofClaimsShinglesColor from '../RoofClaims/Roof-Claims-Shingles-Color.jpg'
import RoofClaimsGarage from '../RoofClaims/Roof-Claims-Garage.jpg'
import RoofClaimsIceShield from '../RoofClaims/Roof-Claims-Ice-Shield.jpg'

import { ExampleImage } from '..'

export const ROOF_CLAIMS_FRONT_HOUSE: ExampleImage[] = [
  {
    label: 'Front of the house',
    source: RoofClaimsFrontHouse,
  },
]

export const ROOF_CLAIMS_ADDRESS_VERIFICATION: ExampleImage[] = [
  {
    label: 'Address Verification',
    source: RoofClaimsAddressVerification,
  },
]

export const ROOF_CLAIMS_3D_VIEW: ExampleImage[] = [
  {
    label: '3D View (1)',
    source: RoofClaims3DView01,
  },
  {
    label: '3D View (2)',
    source: RoofClaims3DView02,
  },
  {
    label: '3D View (3)',
    source: RoofClaims3DView03,
  },
  {
    label: '3D View (4)',
    source: RoofClaims3DView04,
  },
  {
    label: '3D View (5)',
    source: RoofClaims3DView05,
  },
]

export const ROOF_CLAIMS_WIRES_HOME: ExampleImage[] = [
  {
    label: 'Wires in to home',
    source: RoofClaimsWiresHome,
  },
]

export const ROOF_CLAIMS_SIDING: ExampleImage[] = [
  {
    label: 'Siding (Damage, Asbestos)',
    source: RoofClaimsSiding,
  },
]

export const ROOF_CLAIMS_OTHER_PROPERTY: ExampleImage[] = [
  {
    label: 'Other Property wind damage (Gazebo, faceline, tramp)',
    source: RoofClaimsOtherProperty,
  },
]

export const ROOF_CLAIMS_LAYERS: ExampleImage[] = [
  {
    label: 'Layers (lift up)',
    source: RoofClaimsLayers,
  },
]

export const ROOF_CLAIMS_ROOF_INCLINATION: ExampleImage[] = [
  {
    label: 'Roof inclination angle (Pitch gauge tool)',
    source: RoofClaimsInclinationAngle,
  },
]

export const ROOF_CLAIMS_ROOF_OVERVIEW: ExampleImage[] = [
  {
    label:
      'Roof Overview (Line through damage. wiring, masts, chimmeney, --vents, pipes)',
    source: RoofClaimsOverview,
  },
]

export const ROOF_CLAIMS_GUTTER: ExampleImage[] = [
  {
    label: 'Gutters (show how were fastened to the roof)',
    source: RoofClaimsGutters,
  },
]

export const ROOF_CLAIMS_FLASHINGS: ExampleImage[] = [
  {
    label: 'Flashings',
    source: RoofClaimsFlashings,
  },
]

export const ROOF_CLAIMS_PENETRATIONS: ExampleImage[] = [
  {
    label: 'Penetrations (pipejacks, turtlevents, satellite, solar)',
    source: RoofClaimsPenetrations,
  },
]

export const ROOF_CLAIMS_HAIL: ExampleImage[] = [
  {
    label: 'Hail (if applicable)',
    source: RoofClaimsHail,
  },
]

export const ROOF_CLAIMS_LENGTH_SHINGLE: ExampleImage[] = [
  {
    label: 'Shingle length (measuring tape)',
    source: RoofClaimsLengthShingle,
  },
]

export const ROOF_CLAIMS_DRIP: ExampleImage[] = [
  {
    label: 'Drip edge',
    source: RoofClaimsDripEdge,
  },
]

export const ROOF_CLAIMS_SHINGLES_COLOR: ExampleImage[] = [
  {
    label: 'Shingles color',
    source: RoofClaimsShinglesColor,
  },
]

export const ROOF_CLAIMS_GARAGE: ExampleImage[] = [
  {
    label: 'Garage',
    source: RoofClaimsGarage,
  },
]

export const ROOF_CLAIMS_ICE_SHIELD: ExampleImage[] = [
  {
    label: 'Ice Shield',
    source: RoofClaimsIceShield,
  },
]
