import React from 'react'

import * as c from '../../../common'
import {
  Customer,
  selectThumbnail,
  SimpleCustomer,
  useSelector,
} from '../../../common'

import * as Mui from '@mui/material'
import moment from 'moment'
import useMediaQueryMUI from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import Pad from '../Pad'
import gStyles from '../../global.module.css'
import { useMediaQuery } from '../../hooks/useMediaQuery'

export interface CustomerCardProps {
  customer: SimpleCustomer | Customer
  extraAttr?: string
}

export default React.memo<CustomerCardProps>(function ({
  extraAttr,
  customer,
}) {
  const isDark = useMediaQueryMUI('(prefers-color-scheme: dark)')

  const mediaQuery = useMediaQuery()

  const thumbnail = useSelector(
    selectThumbnail(customer.objectID, customer.objectID),
  )

  const getInitials = (name: string) => {
    let _name = name.split(' ', 2)
    if (!_name.length) {
      return 'U'
    }

    if (_name.length > 1) {
      const first = _name[0]
      const last = _name[1]
      //@ts-ignore
      return `${first[0]?.toUpperCase() as string}${last[0]?.toUpperCase()}`
    } else {
      const first = _name[0]
      //@ts-ignore
      return `${first[0]?.toUpperCase() as string}`
    }
  }

  return (
    <Mui.Card
      component={Mui.Paper}
      elevation={5}
      sx={isDark ? sx['cardDark'] : sx['card']}
    >
      <Link className={gStyles['text-undecorated']} to={customer.objectID}>
        <Mui.CardContent sx={sx['cardContent']}>
          <Mui.Box className={gStyles['flex']}>
            <Mui.Box sx={sx['thumbnailBox']}>
              {thumbnail ? (
                <Mui.Avatar
                  alt={getInitials(customer.customerName)}
                  src={thumbnail}
                  sx={sx['avatar']}
                />
              ) : (
                <Mui.Avatar {...stringAvatar(customer.customerName)}>
                  {getInitials(customer.customerName)}
                </Mui.Avatar>
              )}
            </Mui.Box>
            <Pad amt={20} row />
            <Mui.Box sx={sx['nameDateBox']}>
              <Mui.Box sx={sx['flexMainDataBox']}>
                <Mui.Typography
                  color={isDark ? c.white : c.black}
                  sx={
                    mediaQuery.width < 450 ? sx['mainSmText'] : sx['mainMdText']
                  }
                >
                  {customer.customerName}
                </Mui.Typography>
                <Mui.Typography
                  color={isDark ? c.white : c.black}
                  sx={mediaQuery.width < 450 ? sx['smText'] : sx['mdText']}
                >
                  {moment(customer.sort_key * -1).format(c.readableDateFormat)}
                </Mui.Typography>
              </Mui.Box>
              <Pad amt={10} />
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
              >
                {customer.customerAddress}
              </Mui.Typography>
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
              >
                {customer.solarRep}
              </Mui.Typography>
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
              >
                {customer.homeRep}
              </Mui.Typography>
              {extraAttr && (
                <Mui.Typography
                  color="text.secondary"
                  sx={mediaQuery.width < 450 ? sx['mdText'] : sx['mainSmText']}
                >
                  {extraAttr}
                </Mui.Typography>
              )}
            </Mui.Box>
          </Mui.Box>
        </Mui.CardContent>
      </Link>
    </Mui.Card>
  )
})

const cardBase = {
  display: {
    md: 'none',
    xs: 'block',
  },
  marginBottom: '16px',
  position: 'relative',
  width: '90%',
}

const sx = {
  arrowIconDark: {
    color: c.white,
    cursor: 'pointer',
    fontSize: '32px',
    position: 'absolute',
    right: '2px',
    top: 'calc( 50% - 16px )',
  },
  arrowIconLight: {
    color: c.black,
    cursor: 'pointer',
    fontSize: '32px',
    position: 'absolute',
    right: '2px',
    top: 'calc( 50% - 16px )',
  },
  avatar: {
    height: '60px',
    width: '60px',
  },
  card: {
    ...cardBase,
    backgroundColor: c.light.canvas.backgroundColor as string,
  },
  cardDark: {
    ...cardBase,
    backgroundColor: c.dark.canvas.backgroundColor as string,
  },
  cardContent: {
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
  },
  customerData: {
    display: 'flex',
  },
  flexMainDataBox: {
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '2px',
    paddingTop: '2px',
    width: '100%',
  },
  nameDateBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainMdText: { fontSize: '18px' },
  mainSmText: { fontSize: '14px' },
  smText: {
    fontSize: '12px',
  },
  mdText: {
    fontSize: '14px',
  },
  thumbnailBox: {
    alignItems: 'center',
    display: 'flex',
  },
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: '60px',
      width: '60px',
    },
  }
}
