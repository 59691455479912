/* eslint-disable require-yield */
import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { Task as SagaTask, eventChannel } from 'redux-saga'
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'

import * as schema from '../schema'
import {
  FireEvent,
  FireEventType,
  FireSnapshot,
  getDatabase,
  subToCustomer,
} from '../api'
import {
  Customer,
  InitialCustomer,
  createEmptyCustomer,
  validateCustomer,
} from '../schema'
import {
  EMPTY_ARRAY,
  DeepReadonly,
  ValueOf,
  createEventBatcher,
  log,
} from '../utils'

import { YieldReturn } from './common'

export const MAX_CUSTOMERS_AT_ONCE = 80

const PREFIX = 'customers'

type CustomersState = DeepReadonly<{
  byID: string[]
  currentLimit: number
  data: Record<string, Customer>
}>

const initialState: CustomersState = {
  byID: [],
  currentLimit: 20,
  data: {},
}

const customersSlice = createSlice({
  extraReducers(builder) {
    return builder.addCase('persist/REHYDRATE', (state) => {
      state.byID = []
      state.currentLimit = 20
    })
  },
  name: PREFIX,
  initialState,
  reducers: {
    createdCustomer(
      { data, byID },
      {
        payload: { customer },
      }: PayloadAction<{
        customer: InitialCustomer
      }>,
    ) {
      byID.splice(0, 0, customer.firebaseKey)
      data[customer.firebaseKey] = createEmptyCustomer(customer)
    },
    receivedCustomersFirebaseEvents(
      state,
      { payload: { events } }: PayloadAction<{ events: FireEvent<Customer>[] }>,
    ) {
      const { byID, data } = state

      events.forEach((event) => {
        const { key, type } = event

        if (type === 'child_added' || type === 'child_changed') {
          const { prevKey } = event

          data[key] = event.item

          const currIdx = byID.indexOf(key)

          // CAST: If the value is null or undefined indexOf() will return -1
          const newIdx = byID.indexOf(prevKey!) + 1

          if (currIdx !== newIdx) {
            currIdx > -1 && byID.splice(currIdx, 1)
            byID.splice(newIdx, 0, key)
          }
        }
        if (type === 'child_moved') {
          const { prevKey } = event

          const currIdx = byID.indexOf(key)

          // CAST: If the value is null or undefined indexOf() will return -1
          const newIdx = byID.indexOf(prevKey!) + 1

          if (currIdx !== newIdx) {
            currIdx > -1 && byID.splice(currIdx, 1)
            byID.splice(newIdx, 0, key)
          }
        }
        if (type === 'child_removed') {
          const index = byID.indexOf(key)
          index > -1 && byID.splice(index, 1)

          delete data[event.key]
        }
      })
    },
    receivedSimpleCustomers(
      { data },
      {
        payload: { customers },
      }: PayloadAction<{
        customers: readonly schema.SimpleCustomer[]
      }>,
    ) {
      for (const customer of customers) {
        const { objectID } = customer

        if (!data[objectID]) {
          data[objectID] = createEmptyCustomer({
            customerAddress: '',
            customerName: '',
            customerPhone: '',
            customerPhoneAlt: '',
            ecohomeRep: '',
            firebaseKey: objectID,
            solarCompany: '',
            solarRep: '',
          })
        }

        // CAST: Just created above
        Object.assign(data[objectID]!, customer)
      }
    },
    receivedSingleCustomerFirebaseEvents(
      state,
      {
        payload: { customerID, updates },
      }: PayloadAction<{
        customerID: string
        updates: CustomerChannelPayload
      }>,
    ) {
      const { data } = state

      if (!data[customerID]) {
        data[customerID] = createEmptyCustomer({
          customerAddress: '',
          customerName: '',
          customerPhone: '',
          customerPhoneAlt: '',
          ecohomeRep: '',
          firebaseKey: customerID,
          solarCompany: '',
          solarRep: '',
        })
      }

      //   let allDataUpdate = {}

      for (const { field, value } of updates) {
        //allDataUpdate = { ...allDataUpdate, [field]: value } as Customer
        if (field === 'owner_house') {
          continue
        }

        // @ts-expect-error Typescript edge case
        data[customerID]![field] = value
      }

      // let objToUpdateNote = {}

      // if (customer) {
      //   const { note: windowsNote, totalWindows } = generatedNewWindowsNotes({
      //     ...customer,
      //     ...(allDataUpdate as Customer),
      //   })
      //   const roofNotes = generatedRoofNotes({
      //     ...customer,
      //     ...(allDataUpdate as Customer),
      //   })
      //   const acNotes = generatedACNotes({
      //     ...customer,
      //     ...(allDataUpdate as Customer),
      //   })
      //   const { note: panelNotes, totalPanelCost } = generatedPanelNotes({
      //     ...customer,
      //     ...(allDataUpdate as Customer),
      //   })

      //   if (totalPanelCost !== customer!.panel_removal_cost) {
      //     objToUpdateNote = {
      //       panel_removal_cost: totalPanelCost,
      //       panel_removal_customer_cost: totalPanelCost,
      //     }
      //     data[customerID]!.panel_removal_cost = totalPanelCost
      //     data[customerID]!.panel_removal_customer_cost = totalPanelCost
      //   }

      //   if (totalWindows !== customer!.new_windows_quantity) {
      //     objToUpdateNote = {
      //       new_windows_quantity: totalWindows,
      //     }
      //     data[customerID]!.new_windows_quantity = totalWindows
      //   }

      //   if (windowsNote !== customer!.new_windows_notes) {
      //     objToUpdateNote = {
      //       new_windows_notes: windowsNote,
      //     }
      //     data[customerID]!.new_windows_notes = windowsNote
      //   }
      //   if (roofNotes !== customer!.roof_notes) {
      //     objToUpdateNote = {
      //       ...objToUpdateNote,
      //       roof_notes: roofNotes,
      //     }
      //     data[customerID]!.roof_notes = roofNotes
      //   }
      //   if (acNotes !== customer!.air_conditioner_notes) {
      //     objToUpdateNote = {
      //       ...objToUpdateNote,
      //       air_conditioner_notes: acNotes,
      //     }
      //     data[customerID]!.air_conditioner_notes = acNotes
      //   }
      //   if (panelNotes !== customer!.panel_removal_notes) {
      //     objToUpdateNote = {
      //       ...objToUpdateNote,
      //       panel_removal_notes: panelNotes,
      //     }
      //     data[customerID]!.panel_removal_notes = panelNotes
      //   }

      //   if (Object.keys(objToUpdateNote).length) {
      //     updateCustomer(customerID, objToUpdateNote)
      //   }
      // }
    },
    requestedLimitIncrease(customers) {
      if (customers.currentLimit === MAX_CUSTOMERS_AT_ONCE) {
        return
      }
      customers.currentLimit += 20
    },
    requestedLimitReset(customers) {
      customers.currentLimit = 20
    },
  },
})

const {
  actions: {
    createdCustomer,
    receivedCustomersFirebaseEvents,
    receivedSimpleCustomers,
    receivedSingleCustomerFirebaseEvents,
    requestedLimitIncrease,
    requestedLimitReset,
  },
  reducer: customers,
} = customersSlice

export {
  createdCustomer,
  customers,
  receivedCustomersFirebaseEvents,
  receivedSimpleCustomers,
  receivedSingleCustomerFirebaseEvents,
}

export const subToCustomers = createAction(`${PREFIX}/subToCustomers`)

export const requestedMoreCustomers = createAction(
  `${PREFIX}/requestedMoreCustomers`,
)

export const unSubFromCustomers = createAction(`${PREFIX}/unSubFromCustomers`)

export const subToSingleCustomer = createAction<{ customerID: string }>(
  `${PREFIX}/subToSingleCustomer`,
)

export const unSubFromSingleCustomer = createAction<{ customerID: string }>(
  `${PREFIX}/unSubFromSingleCustomer`,
)

//#region selectors

interface GlobalState {
  [PREFIX]: CustomersState
}

const selectCurrentLimit = ({
  customers: { currentLimit },
}: GlobalState): number => currentLimit

const selectAllCustomersIDs = ({ customers: { byID } }: GlobalState) => {
  if (byID.length === 0) {
    return EMPTY_ARRAY as string[]
  }
  return byID
}

export const selectCustomer = (
  { customers: { data } }: GlobalState,
  customerID: string,
): Customer => {
  const customer = data[customerID]
  if (!customer) {
    return schema.createEmptyCustomer({
      firebaseKey: customerID,
    })
  }
  return customer
}

const selectAllCustomerData = ({ customers: { data } }: GlobalState) => data

export const selectAllCustomers: (state: GlobalState) => Customer[] =
  createSelector(
    selectAllCustomersIDs,
    selectAllCustomerData,
    (byID, data): Customer[] => {
      if (byID.length === 0) {
        return EMPTY_ARRAY as Customer[]
      }

      // A typical ID is 20 characters long
      return byID
        .filter((id) => id.length >= 18)
        .map((id) => {
          const customerOrUndef = data[id]

          if (customerOrUndef) {
            return customerOrUndef
          }

          log(
            `selectAllCustomers() -> Could not fetch customer data for id ${id}`,
          )

          return createEmptyCustomer({
            customerAddress: '',
            customerName: '',
            customerPhone: '',
            customerPhoneAlt: '',
            ecohomeRep: '',
            firebaseKey: id,
            solarCompany: '',
            solarRep: '',
          })
        })
        .filter((c) => !c.deleted)
    },
  )

export interface SelectCustomerParams {
  customerID: string
}

export const makeSelectCustomer = () =>
  createSelector(
    selectAllCustomerData,
    (_: GlobalState, args: SelectCustomerParams) => args,
    (customerData, { customerID }): Customer => {
      return (
        customerData[customerID] ||
        createEmptyCustomer({
          customerAddress: '',
          customerName: '',
          customerPhone: '',
          customerPhoneAlt: '',
          ecohomeRep: '',
          firebaseKey: customerID,
          solarCompany: '',
          solarRep: '',
        })
      )
    },
  )

export interface SelectCustomerFieldParams<
  K extends schema.CustomerField = schema.CustomerField,
> {
  customerID: string
  field: K
}

export const makeSelectCustomerField = <K extends schema.CustomerField>() =>
  createSelector(
    selectAllCustomerData,
    (_: GlobalState, args: SelectCustomerFieldParams<K>) => args,
    (customerData, { customerID, field }): Customer[K] => {
      const customer =
        customerData[customerID] ||
        createEmptyCustomer({
          customerAddress: '',
          customerName: '',
          customerPhone: '',
          customerPhoneAlt: '',
          ecohomeRep: '',
          firebaseKey: customerID,
          solarCompany: '',
          solarRep: '',
        })

      return customer[field]
    },
  )
//#endregion selectors

//#region customersChannel

type CustomersChannelPayload = FireEvent<Customer>[]

const createCustomersSubscription = (
  eventType: FireEventType,
  listener: (e: FireEvent<Customer> | Error) => void,
) =>
  function customersSubscription(data: FireSnapshot, prevKey?: string | null) {
    try {
      const { key } = data

      if (!key) {
        throw new ReferenceError(`${eventType} without key`)
      }

      // A typical ID is 20 characters long
      // if (key.length < 18) {
      //   throw new ReferenceError(`Customer key too short: ${key}`)
      // }

      if (eventType === 'child_added' || eventType === 'child_changed') {
        const val = data.val()

        const validation = validateCustomer(val as Record<string, unknown>)

        if (validation.ok) {
          listener({
            item: validation.sanitized,
            key,
            prevKey,
            type: eventType,
          })
        } else {
          throw new Error(
            validation.err || JSON.stringify(validation.errMap, null, 4),
          )
        }
      }
      if (eventType === 'child_moved' || eventType === 'child_removed') {
        const val = data.val()

        const validation = validateCustomer(val as Record<string, unknown>)

        if (validation.ok) {
          listener({
            item: validation.sanitized,
            key,
            prevKey,
            type: eventType,
          })
        }
      }
      if (eventType === 'child_removed') {
        listener({
          item: schema.createEmptyCustomer({
            customerAddress: '',
            customerName: '',
            customerPhone: '',
            customerPhoneAlt: '',
            ecohomeRep: '',
            firebaseKey: '',
            solarCompany: '',
            solarRep: '',
          }),
          key,
          prevKey,
          type: eventType,
        })
      }
    } catch (e) {
      log(`Error inside customers subscription (${eventType}):`)
      log(e)
    }
  }

const createCustomersChannel = (limit: number) =>
  eventChannel<CustomersChannelPayload | Error>((emit) => {
    const dbRef = getDatabase()
      .ref(`Customers`)
      .orderByChild('sort_key')
      .limitToFirst(limit)

    const eventBatcher = createEventBatcher<FireEvent<Customer>>({
      debounceTime: 1000,
    })

    eventBatcher.onEvents(emit)

    dbRef.on(
      'child_added',
      createCustomersSubscription('child_added', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    dbRef.on(
      'child_changed',
      createCustomersSubscription('child_changed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    dbRef.on(
      'child_moved',
      createCustomersSubscription('child_moved', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    dbRef.on(
      'child_removed',
      createCustomersSubscription('child_removed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    return () => {
      eventBatcher.flush()
      eventBatcher.off()
      dbRef.off()
    }
  })

function* subToCustomersSaga() {
  const currentLimit = selectCurrentLimit(yield select())

  const customersChannel: YieldReturn<typeof createCustomersChannel> =
    yield call(createCustomersChannel, currentLimit)

  try {
    while (true) {
      const payload: CustomersChannelPayload = yield take(customersChannel)

      yield put(
        receivedCustomersFirebaseEvents({
          events: payload,
        }),
      )
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      customersChannel.close()
    }
  }
}

function* customersSubWatcher() {
  while (true) {
    yield take(subToCustomers)

    const customersSubTask: SagaTask = yield fork(subToCustomersSaga)

    yield take(unSubFromCustomers)

    yield cancel(customersSubTask)
    yield put(requestedLimitReset())
  }
}

function* moreCustomersWatcher() {
  // A check is also performed inside the reducer, but let's avoid unnecessary
  // updates
  // const currentLimit = selectCurrentLimit(yield select())
  // if (currentLimit !== MAX_CUSTOMERS_AT_ONCE) {
  // }
  yield put(unSubFromCustomers())
  yield put(requestedLimitIncrease())
  yield put(subToCustomers())
}

//#endregion customersChannel

//#region singleCustomer

interface CustomerUpdate {
  field: keyof Customer
  value: ValueOf<Customer>
}

type CustomerChannelPayload = CustomerUpdate[]

const createCustomerChannel = (customerID: string) =>
  eventChannel<CustomerChannelPayload>((emit) => {
    const eventBatcher = createEventBatcher<CustomerUpdate>({
      debounceTime: 500,
    })

    eventBatcher.onEvents(emit)

    const unSub = subToCustomer(customerID, (field, value) => {
      eventBatcher.eventReceived({
        field,
        value,
      })
    })

    return () => {
      eventBatcher.flush()
      eventBatcher.off()
      unSub()
    }
  })

function* subToCustomerSaga(customerID: string) {
  const customerChan: YieldReturn<typeof createCustomerChannel> = yield call(
    createCustomerChannel,
    customerID,
  )

  try {
    while (true) {
      const updates: CustomerChannelPayload = yield take(customerChan)

      yield put(
        receivedSingleCustomerFirebaseEvents({
          customerID,
          updates,
        }),
      )
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      customerChan.close()
    }
  }
}

function* customerSubWatcher() {
  while (true) {
    const action: ReturnType<typeof subToSingleCustomer> = yield take(
      subToSingleCustomer,
    )

    const {
      payload: { customerID },
    } = action

    const customerSubTask: SagaTask = yield fork(subToCustomerSaga, customerID)

    yield take(unSubFromSingleCustomer)

    yield cancel(customerSubTask)
  }
}
//#endregion singleCustomer

// function* updateTouchedCostSaga(
//   payload: PayloadAction<{ customerID: string; data: Partial<Customer> }>,
// ) {
//   const db = getDatabase()

//   const customerID = payload.payload.customerID
//   const data = payload.payload.data

//   const customer: Customer =
//     (yield appSelect((state: c.State) => state.customers.data[customerID])) ||
//     schema.createEmptyCustomer({
//       firebaseKey: customerID,
//     })

//   const _hasTouchedCustomerCost = customer.has_touched_customer_cost
//   const _hasTouchedCost = customer.has_touched_cost

//   const hasTouchedCustomerCost = {} as Writable<Partial<schema.TouchedCost>>
//   const hasTouchedCost = {} as Writable<Partial<schema.TouchedCost>>

//   for (const [field] of entries(data)) {
//     const isCustomerCost = field.endsWith('_customer_cost')
//     const isCost = field.endsWith('_cost') && !isCustomerCost
//     if (isCustomerCost) {
//       const parentField = field.replace(
//         '_customer_cost',
//         '',
//       ) as schema.CustomerField

//       // When this write occurs: { parentField: 'no', xxxx_customer_cost: '' }
//       // it is because the efficiency is being reset, therefore the write to
//       // customer cost was not user-initiated thus it should not be set to the
//       // "touched" state
//       hasTouchedCustomerCost[parentField] = data?.[parentField] !== 'no'
//     }
//     if (isCost) {
//       const parentField = field.replace('_cost', '') as schema.CustomerField
//       hasTouchedCost[parentField] = data?.[parentField] !== 'no'
//     }
//   }
//   if (Object.keys(hasTouchedCustomerCost).length) {
//     db.ref('Customers')
//       .child(customerID)
//       .child('has_touched_customer_cost')
//       .set(
//         JSON.stringify({
//           ..._hasTouchedCustomerCost,
//           ...hasTouchedCustomerCost,
//         }),
//       )
//   }
//   if (Object.keys(hasTouchedCost).length) {
//     db.ref('Customers')
//       .child(customerID)
//       .child('has_touched_cost')
//       .set(
//         JSON.stringify({
//           ..._hasTouchedCost,
//           ...hasTouchedCost,
//         }),
//       )
//   }
// }

export function* customersSaga() {
  yield all([
    takeEvery(requestedMoreCustomers, moreCustomersWatcher),
    // takeEvery(updatedCustomerActionCreator, updateTouchedCostSaga),
    call(customersSubWatcher),
    call(customerSubWatcher),
  ])
}
