import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gs from '../gStyles'

import Cashback from './Cashback'
// import Checkbox from './Checkbox'
import ConnectedInput from './ConnectedInput'
import ConnectedYesNo from './ConnectedYesOrNo'
import ConnRowInput from './ConnRowInput'
import MultipleChoiceInput from './ConnectedPicker'
// import Label from './Label'
// import Labeled from './Labeled'

export type FieldProps = c.r<{
  customerID: string
  field: c.CustomerField
  on: c.Surface
}>

/**
 * From 628px and above, the app renders inputs in 2 columns.
 */
const twoColumnsScreen = (() => {
  if (r.isWeb) {
    // @ts-ignore Ignore in RN side.
    return window.matchMedia('(min-width: 628px)')
  }

  return {
    addEventListener: c.emptyFn,
    removeEventListener: c.emptyFn,
    matches() {
      return false
    },
  }
})()

const Field = React.memo<FieldProps>(({ customerID, field, on }) => {
  //#region styling
  const rowGap = gs.useRowGap(on)
  const gStyles = gs.useGlobalStyles(on)
  const [isTwoColumnsScreen, setIsTwoColumnsScreen] = React.useState(
    twoColumnsScreen.matches,
  )
  const handleScreenSizeChange = React.useCallback((): void => {
    setIsTwoColumnsScreen(twoColumnsScreen.matches)
  }, [])
  React.useEffect(() => {
    if (r.isMobile) {
      return
    }
    twoColumnsScreen.addEventListener('change', handleScreenSizeChange)

    return () => {
      twoColumnsScreen.removeEventListener('change', handleScreenSizeChange)
    }
  }, [handleScreenSizeChange])

  //#endregion styling
  //#region global
  // const [customer] = r.useCustomer(customerID)
  const [fieldVal] = r.useCustomerField(customerID, field)
  //#endregion global
  //#region local
  // const handleNo = React.useCallback((): void => {
  //   c.updateCustomer(customerID, { [field]: 'yes' })
  // }, [customerID, field])
  // const handleYes = React.useCallback((): void => {
  //   c.updateCustomer(customerID, { [field]: 'no' })
  // }, [customerID, field])
  //#endregion local
  if (field === 'cash_amount') {
    return <Cashback customerID={customerID} />
  }
  if (c.rowableFields.includes(field as any)) {
    return (
      <ConnRowInput
        customerID={customerID}
        on={on}
        field={field as c.FieldRowable}
      />
    )
  }
  if (c.multipleChoiceFields.includes(field as any)) {
    return (
      <MultipleChoiceInput
        customerID={customerID}
        field={field as c.MultipleChoiceField}
        on={on}
      />
    )
  }
  // const shouldDisable =
  //   c.shouldDisableInput(field) || c.shouldHideInput(field, customer)
  // const labelElRight = (
  //   <Checkbox
  //     checked={fieldVal === 'yes'}
  //     disabled={shouldDisable}
  //     on={on}
  //     onPress={fieldVal === 'yes' ? handleYes : handleNo}
  //   />
  // )

  if (
    c.CustomerSchema[field] === c.YesNo ||
    c.CustomerSchema[field] === c.NoYes ||
    c.CustomerSchema[field] === c.YesNoEmpty
  ) {
    const [dependent] = c.yesNoToDependent[field] || []

    if (!dependent) {
      return <ConnectedYesNo customerID={customerID} centerText field={field} />
    }

    const shouldRenderDependent =
      fieldVal === 'yes' ||
      (dependent === 'new_windows_replace_custom' && fieldVal === 'no') ||
      isTwoColumnsScreen

    return (
      <>
        <rn.View style={gStyles.separatorH} />
        {rowGap}

        <rn.View style={gStyles.contentNoMargin}>
          <ConnectedYesNo customerID={customerID} centerText field={field} />
          {rowGap}
          {shouldRenderDependent && (
            <Field customerID={customerID} field={dependent} on={on} />
          )}
        </rn.View>

        {rowGap}

        <rn.View style={gStyles.separatorH} />
      </>
    )
  }

  return <ConnectedInput customerID={customerID} field={field} on={on} />
})

export default Field
