import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as ReAnimated from 'react-native-reanimated'
import Animated from 'react-native-reanimated'

import * as gs from '../gStyles'

import Text from './Text'

export type FullscreenModalProps = c.r<{
  children: React.ReactNode
  onRequestClose: c.VoidFn
  title: string
  visible: boolean
}>

export default React.memo<FullscreenModalProps>(function FullscreenModal({
  children,
  onRequestClose,
  title,
  visible,
}) {
  const isMounted = r.useIsMounted()
  const styles = gs.useThemedStyleSheet(themedStyles)

  const { height: screenH, width: screenW } = rn.useWindowDimensions()

  const modalOffset = ReAnimated.useSharedValue(screenH * 1.5)

  const _animatedStyleModal = ReAnimated.useAnimatedStyle(
    (): Animated.AnimatedStyleProp<rn.ViewStyle> => ({
      transform: [{ translateY: modalOffset.value }],
    }),
    [modalOffset],
  )
  const animatedStyleModal = React.useMemo(
    () => [styles.modal, _animatedStyleModal],
    [_animatedStyleModal, styles.modal],
  )

  const androidBackdropStyle = ReAnimated.useAnimatedStyle(
    (): Animated.AnimatedStyleProp<rn.ViewStyle> => ({
      backgroundColor: '#000',
      flex: 1,
      left: 0,
      opacity: ReAnimated.interpolate(
        modalOffset.value,
        [0, screenH * 1.5],
        [0.32, 0],
        ReAnimated.Extrapolate.CLAMP,
      ),
      position: 'absolute',
      top: 0,
      height: screenH,
      width: screenW,
    }),
    [modalOffset, screenH, screenW],
  )

  React.useEffect((): void => {
    if (!isMounted()) return
    if (!visible) {
      return
    }
    modalOffset.value = ReAnimated.withTiming(0, {
      easing: ReAnimated.Easing.bezier(0.25, 0.1, 0.25, 1),
      duration: animationDuration,
    })
  }, [isMounted, modalOffset, visible])

  const handleRequestClose = React.useCallback((): void => {
    modalOffset.value = ReAnimated.withTiming(
      screenH * 1.5,
      {
        easing: ReAnimated.Easing.bezier(0.25, 0.1, 0.25, 1),
        duration: animationDuration,
      },
      (isFinished) => {
        if (isFinished) {
          ReAnimated.runOnJS(onRequestClose)()
        }
      },
    )
  }, [modalOffset, onRequestClose, screenH])

  const AndroidBackdrop =
    rn.Platform.OS === 'android' ? Animated.View : React.Fragment

  return (
    <rn.Modal
      animationType="none"
      onRequestClose={handleRequestClose}
      transparent
      visible={visible}
    >
      <AndroidBackdrop
        style={rn.Platform.OS === 'android' ? androidBackdropStyle : undefined}
      />

      <Animated.View style={animatedStyleModal}>
        <rn.ScrollView contentContainerStyle={styles.modalContent}>
          {children}
        </rn.ScrollView>

        <rn.View style={styles.header}>
          <Text style={styles.title}>{title}</Text>

          <rn.Pressable
            onPress={handleRequestClose}
            style={styles.donePressable}
          >
            <Text style={styles.doneTxt}>Done</Text>
          </rn.Pressable>
        </rn.View>
      </Animated.View>
    </rn.Modal>
  )
})

const animationDuration = 400
const headerHeight = 64

const themedStyles = gs.ThemedStyleSheet.create((t) => ({
  donePressable: {
    ...gs.deadCenter,
    height: '100%',
    paddingRight: t.canvas.gap,
    paddingLeft: 48,
    position: 'absolute',
    right: 0,
  },
  doneTxt: {
    color: t.canvas.highlight,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  header: {
    ...gs.deadCenter,
    ...gs.shadowFull,
    backgroundColor: t.backdrop.backgroundColor,
    height: headerHeight,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  modal: {
    ...gs.shadowFull,
    backgroundColor: t.canvas.backgroundColor,
    flexGrow: 1,
    paddingTop: headerHeight,
    shadowOffset: {
      ...gs.shadowFull.shadowOffset,
      height: -20,
    },
    shadowOpacity: 1,
    shadowRadius: 240,
  },
  modalContent: { padding: t.canvas.gap },
  title: {
    color: t.canvas.color,
    fontFamily: t.fontFamily,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
}))
